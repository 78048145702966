import {
  Action,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_RESET,
  CHANGE_PASSWORD_SUCCEEDED,
} from "./actions";
import { ChangePasswordPayload } from "./interfaces";

export interface ChangePasswordState {
  readonly data: ChangePasswordPayload | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialChangePasswordState: ChangePasswordState = {
  data: null,
  isFetching: false,
  isFetched: false,
};

const changePasswordReducer = (
  state = initialChangePasswordState,
  action: Action
): ChangePasswordState => {
  switch (action.type) {
    case CHANGE_PASSWORD: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case CHANGE_PASSWORD_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case CHANGE_PASSWORD_FAILED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: false,
      };
    }
    case CHANGE_PASSWORD_RESET: {
      return initialChangePasswordState;
    }
    default:
      return state;
  }
};

export default changePasswordReducer;
