import { call, put, takeLatest } from "redux-saga/effects";

import { deleteClienteApi } from "./api";
import {
  DELETE_CLIENTE,
  DeleteCliente,
  deleteClienteFailed,
  deleteClienteSucceeded,
} from "./actions";
import { DeleteClienteResponse } from "./interfaces";
import { SagaIterator } from "@redux-saga/types";

function* deleteCliente(action: DeleteCliente): SagaIterator {
  try {
    const { clienteId } = action.payload;

    const response: DeleteClienteResponse = yield call(
      deleteClienteApi,
      clienteId
    );
    const responsePayload = {
      message: response,
      isDeleted: true,
    };
    yield put(deleteClienteSucceeded(responsePayload));
  } catch (e) {
    console.log("deleteClienteSaga error: ", e);
    const responsePayload = {
      message: e.toString(),
      isDeleted: false,
    };
    yield put(deleteClienteFailed(responsePayload));
  }
}

export default function* deleteClienteSaga(): SagaIterator {
  yield takeLatest(DELETE_CLIENTE, deleteCliente);
}
