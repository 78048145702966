import { FindAllTipoRisorsaResponse } from "./interfaces";

export const GET_ALL_TIPO_RISORSA = "GET_ALL_TIPO_RISORSA";
export const GET_ALL_TIPO_RISORSA_SUCCEEDED = "GET_ALL_TIPO_RISORSA_SUCCEEDED";
export const GET_ALL_TIPO_RISORSA_FAILED = "GET_ALL_TIPO_RISORSA_FAILED";

// =============== //
// Action types //

export type GetAllTipoRisorsa = {
  type: typeof GET_ALL_TIPO_RISORSA;
  payload: { offset: number; limit: number };
};

type GetAllTipoRisorsaSucceeded = {
  type: typeof GET_ALL_TIPO_RISORSA_SUCCEEDED;
  payload: FindAllTipoRisorsaResponse;
};

type GetAllTipoRisorsaFailed = {
  type: typeof GET_ALL_TIPO_RISORSA_FAILED;
  payload: null;
};

// =============== //
// Creators //

export const getAllTipoRisorsa = (): GetAllTipoRisorsa => ({
  type: GET_ALL_TIPO_RISORSA,
  payload: { offset: 0, limit: 0 },
});

export const getAllTipoRisorsaSucceeded = (
  response: FindAllTipoRisorsaResponse
): GetAllTipoRisorsaSucceeded => ({
  type: GET_ALL_TIPO_RISORSA_SUCCEEDED,
  payload: response,
});

export const getAllTipoRisorsaFailed = (): GetAllTipoRisorsaFailed => ({
  type: GET_ALL_TIPO_RISORSA_FAILED,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | GetAllTipoRisorsa
  | GetAllTipoRisorsaSucceeded
  | GetAllTipoRisorsaFailed;
