export const GET_ALL_STATISTICHE = "GET_ALL_STATISTICHE";

// Action types //
export type GetAllStatistiche = {
  type: typeof GET_ALL_STATISTICHE;
  payload: null;
};

// Creators //
export const getAllStatistiche = (): GetAllStatistiche => ({
  type: GET_ALL_STATISTICHE,
  payload: null,
});

// Exports //
export type Action = GetAllStatistiche;
