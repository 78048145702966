import {
  Action,
  UPDATE_MARKER,
  UPDATE_MARKER_FAILED,
  UPDATE_MARKER_RESET,
  UPDATE_MARKER_SUCCEEDED,
} from "./actions";

export interface UpdateMarkerState {
  readonly data: { message: string; isUpdated: boolean } | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialUpdateMarkerState: UpdateMarkerState = {
  data: null,
  isFetching: false,
  isFetched: false,
};

const updateMarkerReducer = (
  state = initialUpdateMarkerState,
  action: Action
): UpdateMarkerState => {
  switch (action.type) {
    case UPDATE_MARKER: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case UPDATE_MARKER_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case UPDATE_MARKER_FAILED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: false,
      };
    }
    case UPDATE_MARKER_RESET: {
      return initialUpdateMarkerState;
    }
    default:
      return state;
  }
};

export default updateMarkerReducer;
