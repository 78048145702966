// =============== //
// Actions //
import { responsePayload } from "./interfaces";

export const UPDATE_CLIENTE_SUCCEEDED = "UPDATE_CLIENTE_SUCCEEDED";
export const UPDATE_CLIENTE_FAILED = "UPDATE_CLIENTE_FAILED";
export const UPDATE_CLIENTE_RESET = "UPDATE_CLIENTE_RESET";
export const UPDATE_CLIENTE = "UPDATE_CLIENTE";

// =============== //
// Action types //
export type UpdateCliente = {
  type: typeof UPDATE_CLIENTE;
  payload: {
    id: string;
    nome: string;
    email: string;
    sede: string;
    descrizione: string;
    isActive: boolean;
    isLogoColored: boolean;
  };
};

type UpdateClienteSucceeded = {
  type: typeof UPDATE_CLIENTE_SUCCEEDED;
  payload: responsePayload;
};

type UpdateClienteFailed = {
  type: typeof UPDATE_CLIENTE_FAILED;
  payload: responsePayload;
};

type UpdateClienteReset = {
  type: typeof UPDATE_CLIENTE_RESET;
  payload: null;
};

// =============== //
// Creators //
export const updateCliente = (
  id: string,
  nome: string,
  email: string,
  sede: string,
  descrizione: string,
  isActive: boolean,
  isLogoColored: boolean
): UpdateCliente => ({
  type: UPDATE_CLIENTE,
  payload: { id, nome, email, sede, descrizione, isActive, isLogoColored },
});

export const updateClienteSucceeded = (
  response: responsePayload
): UpdateClienteSucceeded => ({
  type: UPDATE_CLIENTE_SUCCEEDED,
  payload: response,
});

export const updateClienteFailed = (
  response: responsePayload
): UpdateClienteFailed => ({
  type: UPDATE_CLIENTE_FAILED,
  payload: response,
});

export const updateClienteReset = (): UpdateClienteReset => ({
  type: UPDATE_CLIENTE_RESET,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | UpdateCliente
  | UpdateClienteSucceeded
  | UpdateClienteFailed
  | UpdateClienteReset;
