import { call, put, takeLatest } from "redux-saga/effects";

import { fetchAllUsers, fetchUsersWithFiltersApi } from "./api";
import {
  FIND_USER_WITH_FILTERS,
  FINDALL_USER,
  FindAllUser,
  findAllUserFailed,
  findAllUserSucceeded,
  FindUserWithFilters,
} from "./actions";
import { FindAllUserResponse } from "./interfaces";
import { SagaIterator } from "@redux-saga/types";

function* findAllUser(action: FindAllUser): SagaIterator {
  try {
    const { offset, limit } = action.payload;
    const response: FindAllUserResponse = yield call(
      fetchAllUsers,
      offset,
      limit
    );
    yield put(findAllUserSucceeded(response));
  } catch (e) {
    yield put(findAllUserFailed());
  }
}

function* findUserWithFilters(action: FindUserWithFilters): SagaIterator {
  try {
    const {
      email,
      ruoloId,
      ambito,
      idCliente,
      codiceMarker,
      dateFrom,
      dateTo,
      offset,
      limit,
    } = action.payload;
    const response: FindAllUserResponse = yield call(
      fetchUsersWithFiltersApi,
      email,
      ruoloId,
      ambito,
      idCliente,
      codiceMarker,
      dateFrom,
      dateTo,
      offset,
      limit
    );
    yield put(findAllUserSucceeded(response));
  } catch (e) {
    yield put(findAllUserFailed());
  }
}

export default function* userSaga(): SagaIterator {
  yield takeLatest(FINDALL_USER, findAllUser);
  yield takeLatest(FIND_USER_WITH_FILTERS, findUserWithFilters);
}
