import { DEFAULT_HEADERS, ENDPOINT, ENDPOINTS_BASE } from "../../constants";
import { ChangePasswordResponse } from "./interfaces";

export async function updateUserPassword(
  email: string,
  password: string,
  tinyId: string
): Promise<ChangePasswordResponse> {
  return fetch(`${ENDPOINTS_BASE}${ENDPOINT.CHANGE_PASSWORD}`, {
    method: "POST",
    headers: DEFAULT_HEADERS,
    body: JSON.stringify({
      email,
      tinyId,
      password: btoa(password),
    }),
  }).then(async (response: any) => {
    if (response.ok) {
      const resp: ChangePasswordResponse = {
        message: response.text(),
      };
      return resp;
    }
    throw new Error(await response.text());
  });
}
