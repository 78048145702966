import { call, put, takeLatest } from "redux-saga/effects";
import {
  NEXT_MARKER_CODE,
  nextMarkerCodeFailed,
  nextMarkerCodeSucceeded,
} from "./actions";
import { nextMarkerCodeApi } from "./api";
import { SagaIterator } from "@redux-saga/types";

function* nextMarkerCode(): SagaIterator {
  try {
    const response: string = yield call(nextMarkerCodeApi);
    yield put(nextMarkerCodeSucceeded(response));
  } catch (e) {
    yield put(nextMarkerCodeFailed());
  }
}

export default function* nextMarkerCodeSaga(): SagaIterator {
  yield takeLatest(NEXT_MARKER_CODE, nextMarkerCode);
}
