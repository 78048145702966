import {
    AUTHORIZED_HEADERS,
    ENDPOINT,
    ENDPOINTS_BASE,
} from "../../../constants";
import {MarkerBody} from "../../../components/markerDetails/MarkerDetails";

export async function createMarkerApi(
    markerToCreate: MarkerBody
): Promise<string> {
    return fetch(`${ENDPOINTS_BASE}${ENDPOINT.CREATE_MARKER}`, {
        method: "POST",
        headers: AUTHORIZED_HEADERS,
        body: JSON.stringify(markerToCreate),
    }).then(async (response) => {
        if (response.ok) {
            return response.text();
        }
        throw new Error(await response.text());
    });
}

export async function createMarkerBulkApi(
    markerToCreate: MarkerBody,
    numeroMarkerTotali: number,
): Promise<string> {
    return fetch(`${ENDPOINTS_BASE}${ENDPOINT.CREATE_MARKER_BULK}?numeroMarkerTotali=${numeroMarkerTotali}`, {
        method: "POST",
        headers: AUTHORIZED_HEADERS,
        body: JSON.stringify(markerToCreate),
    }).then(async (response) => {
        console.log(response)
        if (response.ok) {
            return `${numeroMarkerTotali} Marker inseriti con successo`;
        }
        throw new Error(await response.text());
    });
}
