import React, { FC } from "react";
import Loader from "react-loader-spinner";
import { Box } from "@material-ui/core";

interface Props {
  visible: boolean;
}

const CustomLoader: FC<Props> = ({ visible }): JSX.Element => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Loader
        type="Bars"
        color="#5664d2"
        height={100}
        width={100}
        visible={visible}
      />
    </Box>
  );
};

export default CustomLoader;
