import {
  AUTHORIZED_HEADERS,
  ENDPOINT,
  ENDPOINTS_BASE,
} from "../../../constants";

export async function createUserApi(
  email: string,
  password: string,
  ruoloUtente: number,
  isActive: boolean
): Promise<string> {
  return fetch(`${ENDPOINTS_BASE}${ENDPOINT.CREATE_USER}`, {
    method: "POST",
    headers: AUTHORIZED_HEADERS,
    body: JSON.stringify({
      email,
      password,
      ruoloUtente: { id: ruoloUtente },
      isActive,
    }),
  }).then(async (response) => {
    if (response.ok) {
      return response.text();
    }
    throw new Error(await response.text());
  });
}
