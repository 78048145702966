import {
  Action,
  GET_MARKER_REQUEST_REPORT,
  GET_MARKER_REQUEST_REPORT_FAILED,
  GET_MARKER_REQUEST_REPORT_RESET,
  GET_MARKER_REQUEST_REPORT_SUCCEEDED,
} from "./actions";
import { MarkerRequestReportResponse } from "./interfaces";

export interface MarkerRequestReportState {
  readonly data: MarkerRequestReportResponse | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialMarkerRequestsState: MarkerRequestReportState = {
  data: null,
  isFetching: false,
  isFetched: false,
};

const MarkerRequestReportReducer = (
  state = initialMarkerRequestsState,
  action: Action
): MarkerRequestReportState => {
  switch (action.type) {
    case GET_MARKER_REQUEST_REPORT: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case GET_MARKER_REQUEST_REPORT_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case GET_MARKER_REQUEST_REPORT_FAILED: {
      return { ...state, isFetching: false, isFetched: false };
    }
    case GET_MARKER_REQUEST_REPORT_RESET: {
      return initialMarkerRequestsState;
    }
    default:
      return state;
  }
};

export default MarkerRequestReportReducer;
