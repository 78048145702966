// =============== //
// Actions //
import { ResponsePayload } from "./interfaces";

export const UPDATE_USER_SUCCEEDED = "UPDATE_USER_SUCCEEDED";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
export const UPDATE_USER_RESET = "UPDATE_USER_RESET";
export const UPDATE_USER = "UPDATE_USER";

// =============== //
// Action types //
export type UpdateUser = {
  type: typeof UPDATE_USER;
  payload: {
    id: number;
    email: string;
    password: string;
    ruoloUtente: number;
    isActive: boolean;
  };
};

type UpdateUserSucceeded = {
  type: typeof UPDATE_USER_SUCCEEDED;
  payload: ResponsePayload;
};

type UpdateUserFailed = {
  type: typeof UPDATE_USER_FAILED;
  payload: ResponsePayload;
};

type UpdateUserReset = {
  type: typeof UPDATE_USER_RESET;
  payload: null;
};

// =============== //
// Creators //
export const updateUser = (
  id: number,
  email: string,
  password: string,
  ruoloUtente: number,
  isActive: boolean
): UpdateUser => ({
  type: UPDATE_USER,
  payload: { id, email, password, ruoloUtente, isActive },
});

export const updateUserSucceeded = (
  response: ResponsePayload
): UpdateUserSucceeded => ({
  type: UPDATE_USER_SUCCEEDED,
  payload: response,
});

export const updateUserFailed = (
  response: ResponsePayload
): UpdateUserFailed => ({
  type: UPDATE_USER_FAILED,
  payload: response,
});

export const updateUserReset = (): UpdateUserReset => ({
  type: UPDATE_USER_RESET,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | UpdateUser
  | UpdateUserSucceeded
  | UpdateUserFailed
  | UpdateUserReset;
