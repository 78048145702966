import { call, put, takeLatest } from "redux-saga/effects";
import {
  getAllUserRegisteredFailed,
  getAllUserRegisteredSucceeded,
} from "./actions/utentiRegistratiActions";
import {
  getAllQrcodeRequestsFailed,
  getAllQrcodeRequestsSucceeded,
} from "./actions/qrcodeRequestActions";
import { GET_ALL_STATISTICHE } from "./actions/actions";
import {
  getAllMarkersFailed,
  getAllMarkersSucceeded,
} from "./actions/markerActions";
import {
  getAllMarkerRequestsFailed,
  getAllMarkerRequestsSucceeded,
} from "./actions/markerRequestsActions";
import { SagaIterator } from "@redux-saga/types";
import { fetchAllUsers } from "../../UserList/api";
import { FindAllUserResponse } from "../../UserList/interfaces";
import { GetAllQrCodeRequestResponse } from "../../QrCodeRequestList/interfaces";
import { getAllQrCodeRequestApi } from "../../QrCodeRequestList/api";
import { FindAllMarkerResponse } from "../../MarkerList/interfaces";
import { fetchAllMarker } from "../../MarkerList/api";
import { GetAllMarkerRequestResponse } from "../../MarkerRequestList/interfaces";
import { getAllMarkerRequestApi } from "../../MarkerRequestList/api";
import { GetAllClienteResponse } from "../../ClienteList/interfaces";
import { getAllClienteApi } from "../../ClienteList/api";
import {
  getAllClientiFailed,
  getAllClientiSucceeded,
} from "./actions/clientiActions";
import { isUserAuthorized } from "../../../utils";
import { AUTHORIZATION } from "../../../constants";
import { GetWidgetsDataResponse } from "./interfaces";
import {
  GET_WIDGETS_DATA,
  GetWidgetsData,
  getWidgetsDataFailed,
  getWidgetsDataSucceeded,
} from "./actions/widgetsActions";
import { getWidgetsDataApi } from "./api";

function* bigSaga(): SagaIterator {
  const offset = 0;
  const limit = 0;

  if (isUserAuthorized(AUTHORIZATION.UT_V)) {
    try {
      const response: FindAllUserResponse = yield call(
        fetchAllUsers,
        offset,
        limit
      );
      const payload = {
        totalUsers: response.totalRecord,
      };

      yield put(getAllUserRegisteredSucceeded(payload));
    } catch (e) {
      yield put(getAllUserRegisteredFailed());
    }
  }

  if (isUserAuthorized(AUTHORIZATION.QR_V)) {
    try {
      const response: GetAllQrCodeRequestResponse = yield call(
        getAllQrCodeRequestApi,
        offset,
        limit
      );
      const payload = {
        totalQrcodeRequests: response.totalRecord,
      };
      yield put(getAllQrcodeRequestsSucceeded(payload));
    } catch (e) {
      yield put(getAllQrcodeRequestsFailed());
    }
  }

  if (isUserAuthorized(AUTHORIZATION.MK_V)) {
    try {
      const response: FindAllMarkerResponse = yield call(
        fetchAllMarker,
        offset,
        limit
      );
      const payload = {
        totalMarkers: response.totalRecord,
        markers: [],
      };
      yield put(getAllMarkersSucceeded(payload));
    } catch (e) {
      yield put(getAllMarkersFailed());
    }
  }

  if (isUserAuthorized(AUTHORIZATION.RM_V)) {
    try {
      const response: GetAllMarkerRequestResponse = yield call(
        getAllMarkerRequestApi,
        offset,
        limit
      );
      const payload = {
        totalMarkerRequests: response.totalRecord,
        markerRequests: [],
      };
      yield put(getAllMarkerRequestsSucceeded(payload));
    } catch (e) {
      yield put(getAllMarkerRequestsFailed());
    }
  }

  if (isUserAuthorized(AUTHORIZATION.CL_V)) {
    try {
      const response: GetAllClienteResponse = yield call(
        getAllClienteApi,
        offset,
        limit
      );
      const payload = {
        totalClients: response.totalRecord,
      };
      yield put(getAllClientiSucceeded(payload));
    } catch (e) {
      yield put(getAllClientiFailed());
    }
  }
}

function* getWidgetsData(action: GetWidgetsData): SagaIterator {
  if (isUserAuthorized(AUTHORIZATION.ST_V)) {
    try {
      const { intervallo } = action.payload;
      const response: GetWidgetsDataResponse = yield call(
        getWidgetsDataApi,
        intervallo
      );
      yield put(getWidgetsDataSucceeded(response));
    } catch (e) {
      yield put(getWidgetsDataFailed());
    }
  }
}

export default function* statisticheAllSaga(): SagaIterator {
  yield takeLatest(GET_ALL_STATISTICHE, bigSaga);
  yield takeLatest(GET_WIDGETS_DATA, getWidgetsData);
}
