import { call, put, takeLatest } from "redux-saga/effects";
import {
  FIND_MARKER_WITH_FILTERS,
  FINDALL_MARKER,
  FindAllMarker,
  findAllMarkerFailed,
  findAllMarkerSucceeded,
  FindMarkerWithFilters,
  GetQrImage,
  getQrImageSucceeded,
  getQrImageFailed,
  GET_QR_IMAGE,
} from "./actions";
import { FindAllMarkerResponse, GetQrImageResponse } from "./interfaces";
import {
  fetchAllMarker,
  fetchMarkerWithFiltersApi,
  getQrImageApi,
} from "./api";
import { SagaIterator } from "@redux-saga/types";

function* findAllMarker(action: FindAllMarker): SagaIterator {
  try {
    const { offset, limit } = action.payload;
    const response: FindAllMarkerResponse = yield call(
      fetchAllMarker,
      offset,
      limit
    );
    yield put(findAllMarkerSucceeded(response));
  } catch (e) {
    yield put(findAllMarkerFailed());
  }
}

function* findMarkerWithFilters(action: FindMarkerWithFilters): SagaIterator {
  try {
    const {
      idCliente,
      codiceMarker,
      idTipologia,
      ambito,
      isActive,
      offset,
      limit,
    } = action.payload;
    const response: FindAllMarkerResponse = yield call(
      fetchMarkerWithFiltersApi,
      idCliente,
      codiceMarker,
      idTipologia,
      ambito,
      isActive,
      offset,
      limit
    );
    yield put(findAllMarkerSucceeded(response));
  } catch (e) {
    yield put(findAllMarkerFailed());
  }
}

function* getQrImage(action: GetQrImage) {
  try {
    const { marker } = action.payload;
    const response: GetQrImageResponse = yield call(getQrImageApi, marker);
    yield put(getQrImageSucceeded(response));
  } catch (e) {
    yield put(getQrImageFailed());
  }
}

export default function* markerSaga(): SagaIterator {
  yield takeLatest(FINDALL_MARKER, findAllMarker);
  yield takeLatest(FIND_MARKER_WITH_FILTERS, findMarkerWithFilters);
  yield takeLatest(GET_QR_IMAGE, getQrImage);
}
