import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_ALL_RUOLO_UTENTE,
  GET_ALL_RUOLO_UTENTE_DASHBOARD,
  getAllRuoloUtenteFailed,
  getAllRuoloUtenteSucceeded,
} from "./actions";
import { GetAllRuoloUtenteResponse } from "./interfaces";
import { getAllRuoloUtenteApi, getAllRuoloUtenteDashboardApi } from "./api";
import { SagaIterator } from "@redux-saga/types";

function* getAllRuoloUtente(): SagaIterator {
  try {
    const response: GetAllRuoloUtenteResponse = yield call(
      getAllRuoloUtenteApi
    );

    yield put(getAllRuoloUtenteSucceeded(response));
  } catch (e) {
    yield put(getAllRuoloUtenteFailed());
  }
}

function* getAllRuoloUtenteDashboard(): SagaIterator {
  try {
    const response: GetAllRuoloUtenteResponse = yield call(
      getAllRuoloUtenteDashboardApi
    );

    yield put(getAllRuoloUtenteSucceeded(response));
  } catch (e) {
    yield put(getAllRuoloUtenteFailed());
  }
}
export default function* getAllRuoloUtenteSaga(): SagaIterator {
  yield takeLatest(GET_ALL_RUOLO_UTENTE, getAllRuoloUtente);
  yield takeLatest(GET_ALL_RUOLO_UTENTE_DASHBOARD, getAllRuoloUtenteDashboard);
}
