import { AUTHORIZED_HEADERS, ENDPOINT, ENDPOINTS_BASE } from "../../constants";
import { GetAllMarkerRequestResponse } from "./interfaces";

export async function getAllMarkerRequestApi(
  offset: number,
  limit: number
): Promise<GetAllMarkerRequestResponse> {
  return fetch(
    `${ENDPOINTS_BASE}${ENDPOINT.GET_ALL_MARKER_REQUEST}?offset=${offset}&limit=${limit}`,
    {
      method: "GET",
      headers: AUTHORIZED_HEADERS,
    }
  ).then((response) => response.json());
}

export async function getAllMarkerRequestWithFiltersApi(
  idCliente: string,
  idTipologia: string,
  ambito: string,
  marker: string,
  dateFrom: string,
  dateTo: string,
  offset: number,
  limit: number
): Promise<GetAllMarkerRequestResponse> {
  return fetch(
    `${ENDPOINTS_BASE}${ENDPOINT.GET_MARKER_REQUEST_WITH_FILTERS}?idCliente=${idCliente}&idTipologia=${idTipologia}&nomeAmbito=${ambito}&codiceMarker=${marker}&dateFrom=${dateFrom}&dateTo=${dateTo}&offset=${offset}&limit=${limit}`,
    {
      method: "GET",
      headers: AUTHORIZED_HEADERS,
    }
  ).then((response) => response.json());
}
