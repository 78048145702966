import React, { FC, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { Trash2 as DelIcon, Edit as EditIcon } from "react-feather";
import AlertDialog from "../AlertDialog/AlertDialog";
import { renderIcon, renderIsActive } from "../../utils/returntype";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { deleteMarker } from "../../pages/MarkerCRUD/DeleteMarker/actions";
import { useNavigate } from "react-router-dom";
import { Marker } from "../../pages/MarkerList/interfaces";
import PortalClickAway from "../portalClickAway/PortalClickAway";
import { isUserAuthorized } from "../../utils";
import { AUTHORIZATION, ROUTE_PATH } from "../../constants";
import { getQrImage, removeQrImage } from "../../pages/MarkerList/actions";

interface Props {
  markerList: Marker[];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
}

const MarkerListResults: FC<Props> = ({
  markerList,
  page,
  setPage,
  limit,
  setLimit,
  ...rest
}) => {
  const [markerToDelete, setMarkerToDelete] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [portalParameters, setPortalParameters] = useState<{
    byte: null | string;
    open: boolean;
  }>({
    byte: null,
    open: false,
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  console.log(markerList);

  useEffect(() => {
    dispatch(removeQrImage());
  }, []);

  const totalRecord =
    useAppSelector((state) => state.marker.data?.totalRecord) ?? 0;
  const markerResponse = useAppSelector(
    (state) => state.marker.qrImageResponse
  );

  useEffect(() => {
    if (markerResponse !== null) {
      setPortalParameters({
        byte: markerResponse.bytes,
        open: true,
      });
    } else {
      setPortalParameters({ byte: null, open: false });
    }
  }, [markerResponse]);

  const handleLimitChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLimit(Number(event.target.value));
    setPage(0);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleClickMarker = (markerId: number) => {
    navigate(`/${ROUTE_PATH.APP}/${ROUTE_PATH.MARKER}`, {
      state: { markerId },
    });
  };

  const handleDeleteMarker = (): void => {
    dispatch(deleteMarker(markerToDelete));
    setIsModalOpen(false);
  };

  const handleOpenModal = (id: number): void => {
    setMarkerToDelete(id);
    setIsModalOpen(true);
  };

  const handleClickCodMarker = (markerId: number) => {
    dispatch(getQrImage(markerId));
  };

  const handleClickAway = () => {
    dispatch(removeQrImage());
  };

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Ambito</TableCell>
                  <TableCell>Codice Marker</TableCell>
                  <TableCell align="center">Tipo Risorsa</TableCell>
                  <TableCell>Call to Action</TableCell>
                  <TableCell>Logo</TableCell>
                  <TableCell align="center">Attivo</TableCell>
                  <TableCell align="center">Modifica</TableCell>
                  <TableCell align="center">Elimina</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {markerList.map((marker: Marker) => (
                  <TableRow hover key={marker.id} id={marker.id.toString()}>
                    <TableCell>{marker.cliente.nome}</TableCell>
                    <TableCell>{marker.ambito}</TableCell>
                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClickCodMarker(marker.codice)}
                    >
                      {marker.codice}
                    </TableCell>
                    <TableCell align="center">
                      {renderIcon(marker.tipoRisorsa.codice)}
                    </TableCell>
                    <TableCell
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                      onClick={() => window.open(marker.azione, "_blank")}
                    >
                      {marker.azione}
                    </TableCell>
                    <TableCell>
                      {marker.isMarkerLogoColored ? (
                        <img
                          src="/static/images/logo/Icona_Ciclope_Color_100x100.jpg"
                          alt="logo colorato"
                          style={{ height: "24px", width: "24px" }}
                        />
                      ) : (
                        <img
                          src="/static/images/logo/Icona_Ciclope_BW_100x100.jpg"
                          alt="logo black white"
                          style={{ height: "24px", width: "24px" }}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {renderIsActive(marker.isActive)}
                    </TableCell>
                    <TableCell align="center">
                      {isUserAuthorized(AUTHORIZATION.MK_E) && (
                        <EditIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClickMarker(marker.id)}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {isUserAuthorized(AUTHORIZATION.MK_E) && (
                        <DelIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOpenModal(marker.id)}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={totalRecord}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage={"Righe per pagina"}
        />
      </Card>
      <AlertDialog
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        dialogTitle={"Elimina Marker"}
        dialogContent={"Sei sicuro di eliminare il marker selezionato?"}
        buttonSuccessTitle={"Si"}
        buttonFailureTitle={"No"}
        buttonSuccessAction={handleDeleteMarker}
      />
      {portalParameters.byte && portalParameters.open && (
        <PortalClickAway
          byte={portalParameters.byte}
          open={portalParameters.open}
          handleClickAway={handleClickAway}
        />
      )}
    </>
  );
};

export default MarkerListResults;
