import {
  Action,
  UPDATE_USER,
  UPDATE_USER_FAILED,
  UPDATE_USER_RESET,
  UPDATE_USER_SUCCEEDED,
} from "./actions";

export interface UpdateUsersState {
  readonly data: { message: string; isUpdated: boolean } | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialUpdateUsersState: UpdateUsersState = {
  data: null,
  isFetching: false,
  isFetched: false,
};

const updateUsersReducer = (
  state = initialUpdateUsersState,
  action: Action
): UpdateUsersState => {
  switch (action.type) {
    case UPDATE_USER: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case UPDATE_USER_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case UPDATE_USER_FAILED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: false,
      };
    }
    case UPDATE_USER_RESET: {
      return initialUpdateUsersState;
    }
    default:
      return state;
  }
};

export default updateUsersReducer;
