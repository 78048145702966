import {
  AUTHORIZED_HEADERS,
  ENDPOINT,
  ENDPOINTS_BASE,
} from "../../../constants";
import { UserReportResponse } from "./interfaces";

export async function getUserReportApi(
  email: string,
  ruoloId: string,
  ambito: string,
  dateFrom: string,
  dateTo: string
): Promise<UserReportResponse> {
  return fetch(
    `${ENDPOINTS_BASE}${ENDPOINT.GET_USER_REPORT}?email=${email}&ruoloId=${ruoloId}&ambito=${ambito}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
    {
      method: "GET",
      headers: AUTHORIZED_HEADERS,
    }
  ).then((response) => response.json());
}
