import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import { Autocomplete } from "@material-ui/core";
import { Cliente } from "../../pages/ClienteList/interfaces";
import { TipoRisorsa } from "../../common/tipoRisorsa/interfaces";
import { useAppDispatch, useAppSelector } from "../../hooks";
import React, { ChangeEvent, FC, SyntheticEvent, useEffect } from "react";
import CustomResponsiveDateRangePicker from "../customResponsiveDateRangePicker/CustomResponsiveDateRangePicker";
import { DateRange } from "@material-ui/lab/DateRangePicker/RangeTypes";
import { exportExcel } from "../../utils";
import { EXCEL_REPORT_NAME } from "../../constants";
import { QrCodeRequestFilters } from "../../pages/QrCodeRequestList/QrCodeRequestList";
import { getQrCodeRequestReportReset } from "../../pages/QrCodeRequestList/Report/actions";

interface Props {
  qrCodeRequestFilters: QrCodeRequestFilters;
  handleChangeAutocompleteClienteFilter: (
    event: SyntheticEvent,
    selectedCliente: Cliente | null
  ) => void;
  handleChangeTextClienteFilter: (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => void;
  handleChangeFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeAutocompleteTipoRisorsaFilter: (
    event: SyntheticEvent,
    selectedTipoRisorsa: TipoRisorsa | null
  ) => void;
  handleChangeTextTipoRisorsaFilter: (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => void;
  dateFilterValue: DateRange<Date>;
  setDateFilterValue: React.Dispatch<React.SetStateAction<DateRange<Date>>>;
  handleReportClick: () => void;
}

const QrCodeRequestListToolbar: FC<Props> = ({
  qrCodeRequestFilters,
  handleChangeAutocompleteClienteFilter,
  handleChangeTextClienteFilter,
  handleChangeFilter,
  handleChangeAutocompleteTipoRisorsaFilter,
  handleChangeTextTipoRisorsaFilter,
  dateFilterValue,
  setDateFilterValue,
  handleReportClick,
  ...props
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const completeTipoRisorsaList: TipoRisorsa[] =
    useAppSelector((state) => state.tipoRisorsa.data?.tipiRisorsa) ?? [];

  const completeClienteList: Cliente[] =
    useAppSelector((state) => state.clienteNoPag.data?.clienti) ?? [];

  const isQrCodeRequestReportFetched = useAppSelector(
    (state) => state.qrCodeRequestReport.isFetched
  );
  const isQrCodeRequestReportFetching = useAppSelector(
    (state) => state.qrCodeRequestReport.isFetching
  );
  const byteExcel = useAppSelector(
    (state) => state.qrCodeRequestReport.data?.bytes
  );

  useEffect(() => {
    if (isQrCodeRequestReportFetched && byteExcel) {
      exportExcel(byteExcel, EXCEL_REPORT_NAME.QRCODE_REQUEST_LIST);
    }
    return () => dispatch(getQrCodeRequestReportReset());
  }, [isQrCodeRequestReportFetched]);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleReportClick}
          disabled={isQrCodeRequestReportFetching}
        >
          Esporta Report
        </Button>
      </Box>

      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid sx={{ mt: 1 }} container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Autocomplete
                  options={completeClienteList}
                  getOptionLabel={(cliente: Cliente) => cliente.nome}
                  onChange={handleChangeAutocompleteClienteFilter}
                  inputValue={qrCodeRequestFilters.cliente.nome}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Cliente"
                      name="cliente"
                      variant="outlined"
                      onChange={({ target }) =>
                        handleChangeTextClienteFilter(target)
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={3}>
                <TextField
                  name="email"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Email"
                  variant="outlined"
                  onChange={handleChangeFilter}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Autocomplete
                  options={completeTipoRisorsaList}
                  getOptionLabel={(tipoRisorsa: TipoRisorsa) =>
                    tipoRisorsa.codice
                  }
                  onChange={handleChangeAutocompleteTipoRisorsaFilter}
                  inputValue={qrCodeRequestFilters.tipologia.codice}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Tipo risorsa"
                      name="tipoRisorsa"
                      variant="outlined"
                      onChange={({ target }) =>
                        handleChangeTextTipoRisorsaFilter(target)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <CustomResponsiveDateRangePicker
                  dateFilterValue={dateFilterValue}
                  setDateFilterValue={setDateFilterValue}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default QrCodeRequestListToolbar;
