import { Helmet } from "react-helmet";
import { Box, Container } from "@material-ui/core";
import UserListResults from "../../components/user/UserListResults";
import UserListToolbar from "../../components/user/UserListToolbar";
import React, {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { findAllUser, findUserWithFilters } from "./actions";
import { User } from "./interfaces";
import { getAllRuoloUtente } from "../../common/ruoloUtente/actions";
import { RuoloUtente } from "../../common/ruoloUtente/interfaces";
import { DateRange } from "@material-ui/lab/DateRangePicker/RangeTypes";
import { dateMakerForFilters } from "../../utils";
import { getUserReport } from "../UserCRUD/Report/actions";
import { Cliente } from "../ClienteList/interfaces";
import { getAllClienteNoPag } from "../../common/cliente/actions";
import { findMarkerWithFilters } from "../MarkerList/actions";
import CustomLoader from "../../components/CustomLoader/CustomLoader";

export interface UserFilters {
  email: string;
  ruoloUtente: { id: string; ruolo: string };
  ambito: string;
  cliente: { id: string; nome: string; codMarkers: Array<number> };
  codiceMarker: string;
}

const UserList: FC = () => {
  const dispatch = useAppDispatch();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const [userFilters, setUserFilters] = useState<UserFilters>({
    email: "",
    ruoloUtente: { id: "", ruolo: "" },
    ambito: "",
    cliente: { id: "", nome: "", codMarkers: [] },
    codiceMarker: "",
  });

  const [dateFilterValue, setDateFilterValue] = useState<DateRange<Date>>([
    null,
    null,
  ]);

  const areUserFiltersFilled = (): boolean => {
    const { email, ruoloUtente, ambito, cliente, codiceMarker } = userFilters;
    return (
      !!email ||
      !!ruoloUtente.id ||
      !!ambito ||
      !!cliente.id ||
      !!codiceMarker ||
      !!dateFilterValue[0] ||
      !!dateFilterValue[1]
    );
  };

  const users: User[] =
    useAppSelector((state) => state.users.data?.utenti) ?? [];

  const isUserDeleted = useAppSelector(
    (state) => state.deleteUser.data?.isDeleted
  );

  const isUserUpdated = useAppSelector(
    (state) => state.updateUser.data?.isUpdated
  );

  useEffect(() => {
    dispatch(getAllRuoloUtente());
    dispatch(getAllClienteNoPag());
  }, []);

  useEffect(() => {
    setPage(0);
    setLimit(10);
    const offset = page * limit;
    if (areUserFiltersFilled()) {
      const { email, ruoloUtente, ambito, cliente, codiceMarker } = userFilters;
      const dateFrom = dateMakerForFilters(dateFilterValue[0]);
      const dateTo = dateMakerForFilters(dateFilterValue[1]);
      dispatch(
        findUserWithFilters(
          email,
          ruoloUtente.id,
          ambito,
          cliente.id,
          codiceMarker,
          dateFrom,
          dateTo,
          offset,
          limit
        )
      );
    } else {
      dispatch(findAllUser(offset, limit));
    }
  }, [
    userFilters.email.length,
    userFilters.ruoloUtente,
    userFilters.ambito,
    userFilters.cliente.id,
    userFilters.codiceMarker,
    dateFilterValue[0],
    dateFilterValue[1],
    isUserDeleted,
    isUserUpdated,
  ]);

  useEffect(() => {
    const offset = page * limit;
    if (areUserFiltersFilled()) {
      const { email, ruoloUtente, ambito, cliente, codiceMarker } = userFilters;
      const dateFrom = dateMakerForFilters(dateFilterValue[0]);
      const dateTo = dateMakerForFilters(dateFilterValue[1]);
      dispatch(
        findUserWithFilters(
          email,
          ruoloUtente.id,
          ambito,
          cliente.id,
          codiceMarker,
          dateFrom,
          dateTo,
          offset,
          limit
        )
      );
    } else {
      dispatch(findAllUser(offset, limit));
    }
  }, [limit, page]);

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === "codiceMarker") {
      setUserFilters({
        ...userFilters,
        codiceMarker: event.target.value,
        ambito: event.target.value ? userFilters.ambito : "",
      });
    } else {
      setUserFilters({
        ...userFilters,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleChangeAutocompleteRuoloFilter = (
    event: SyntheticEvent,
    selectedRuolo: RuoloUtente | null
  ) => {
    setUserFilters((prevState) => {
      return {
        ...prevState,
        ruoloUtente: {
          id: selectedRuolo?.id?.toString() ?? "",
          ruolo: selectedRuolo?.ruolo ?? "",
        },
      };
    });
  };

  const handleChangeTextRuoloUtenteFilter = (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => {
    //check sul value del ruolo: se non c'è resetto l'id

    setUserFilters((prevState) => {
      return {
        ...prevState,
        ruoloUtente: {
          id: target.value ? userFilters.ruoloUtente.id : "",
          ruolo: target.value ?? "",
        },
      };
    });
  };

  const handleChangeAutocompleteClienteFilter = (
    event: SyntheticEvent,
    selectedCliente: Cliente | null
  ) => {
    setUserFilters((prevState) => {
      return {
        ...prevState,
        cliente: {
          id: selectedCliente?.id?.toString() ?? "",
          nome: selectedCliente?.nome ?? "",
          codMarkers: selectedCliente?.codMarkers ?? [],
        },
        codiceMarker: selectedCliente?.id ? prevState.codiceMarker : "",
        ambito: selectedCliente?.id ? prevState.ambito : "",
      };
    });
  };

  const handleChangeTextClienteFilter = (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => {
    setUserFilters((prevState) => {
      return {
        ...prevState,
        cliente: { ...prevState.cliente, nome: target.value },
      };
    });
  };

  const handleChangeAutocompleteMarkerFilter = (
    event: SyntheticEvent,
    selectedMarker: string | null
  ) => {
    if (selectedMarker) {
      dispatch(
        findMarkerWithFilters(
          userFilters.cliente.id.toString(),
          selectedMarker,
          "",
          "",
          true,
          0,
          50
        )
      );
    }
    setUserFilters((prevState) => {
      return {
        ...prevState,
        codiceMarker: selectedMarker ?? "",
        ambito: selectedMarker ? "" : prevState.ambito,
      };
    });
  };

  const handleChangeAutocompleteAmbitoFilter = (
    event: SyntheticEvent,
    selectedAmbito: string | null
  ) => {
    setUserFilters((prevState) => {
      return {
        ...prevState,
        ambito: selectedAmbito ?? "",
      };
    });
  };

  const handleReportClick = (): void => {
    const { email, ruoloUtente, ambito } = userFilters;
    const dateFrom = dateMakerForFilters(dateFilterValue[0]);
    const dateTo = dateMakerForFilters(dateFilterValue[1]);
    dispatch(getUserReport(email, ruoloUtente.id, ambito, dateFrom, dateTo));
  };

  const isUserListFetching = useAppSelector((state) => state.users.isFetching);
  const isUserListFetched = useAppSelector((state) => state.users.isFetched);

  return (
    <>
      <Helmet>
        <title>Utenti | DxMarker</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <UserListToolbar
            handleChangeFilter={handleChangeFilter}
            userFilters={userFilters}
            dateFilterValue={dateFilterValue}
            setDateFilterValue={setDateFilterValue}
            handleChangeAutocompleteRuoloFilter={
              handleChangeAutocompleteRuoloFilter
            }
            handleChangeTextRuoloUtenteFilter={
              handleChangeTextRuoloUtenteFilter
            }
            handleChangeAutocompleteClienteFilter={
              handleChangeAutocompleteClienteFilter
            }
            handleChangeTextClienteFilter={handleChangeTextClienteFilter}
            handleReportClick={handleReportClick}
            handleChangeAutocompleteMarkerFilter={
              handleChangeAutocompleteMarkerFilter
            }
            handleChangeAutocompleteAmbitoFilter={
              handleChangeAutocompleteAmbitoFilter
            }
          />
          <Box sx={{ pt: 3 }}>
            <CustomLoader visible={isUserListFetching} />
            {isUserListFetched && (
              <UserListResults
                users={users}
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
              />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default UserList;
