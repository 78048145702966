// =============== //
// Actions //
import { ClientiStatisticheResponse } from "../interfaces";

export const GET_ALL_CLIENTI_SUCCEEDED = "GET_ALL_CLIENTI_SUCCEEDED";
export const GET_ALL_CLIENTI_FAILED = "GET_ALL_CLIENTI_FAILED";
export const GET_ALL_CLIENTI = "GET_ALL_CLIENTI";

// =============== //
// Action types //
export type GetAllClienti = {
  type: typeof GET_ALL_CLIENTI;
  payload: null;
};

type GetAllClientiSucceeded = {
  type: typeof GET_ALL_CLIENTI_SUCCEEDED;
  payload: ClientiStatisticheResponse;
};

type GetAllClientiFailed = {
  type: typeof GET_ALL_CLIENTI_FAILED;
  payload: null;
};

// =============== //
// Creators //
export const getAllClienti = (): GetAllClienti => ({
  type: GET_ALL_CLIENTI,
  payload: null,
});

export const getAllClientiSucceeded = (
  response: ClientiStatisticheResponse
): GetAllClientiSucceeded => ({
  type: GET_ALL_CLIENTI_SUCCEEDED,
  payload: response,
});

export const getAllClientiFailed = (): GetAllClientiFailed => ({
  type: GET_ALL_CLIENTI_FAILED,
  payload: null,
});

// =============== //
// Exports //
export type ClientiTotaliAction =
  | GetAllClienti
  | GetAllClientiSucceeded
  | GetAllClientiFailed;
