import { Helmet } from "react-helmet";
import { Box, Container, Grid } from "@material-ui/core";
import UserDetails from "../../../components/userDetails/UserDetails";
import { FC } from "react";
import { useLocation } from "react-router";

const Account: FC = (): JSX.Element => {
  const location = useLocation();
  return (
    <>
      <Helmet>
        <title>
          {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
          {/*@ts-ignore*/}
          {location.state?.userId ? "Modifica Utente" : "Crea Utente"}
        </title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={8} md={6} xs={12}>
              <UserDetails />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Account;
