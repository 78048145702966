import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_ALL_QRCODE_REQUEST,
  GetAllQrCodeRequest,
  getAllQrCodeRequestFailed,
  getAllQrCodeRequestSucceeded,
  GetQrCodeRequestWithFilters,
  GET_QRCODE_REQUEST_WITH_FILTERS,
} from "./actions";
import { GetAllQrCodeRequestResponse } from "./interfaces";
import {
  getAllQrCodeRequestApi,
  getAllQrCodeRequestWithFiltersApi,
} from "./api";
import { SagaIterator } from "@redux-saga/types";

function* getAllQrCodeRequest(action: GetAllQrCodeRequest): SagaIterator {
  try {
    const { offset, limit } = action.payload;
    const response: GetAllQrCodeRequestResponse = yield call(
      getAllQrCodeRequestApi,
      offset,
      limit
    );
    yield put(getAllQrCodeRequestSucceeded(response));
  } catch (e) {
    yield put(getAllQrCodeRequestFailed());
  }
}

function* getQrCodeRequestWithFilters(
  action: GetQrCodeRequestWithFilters
): SagaIterator {
  try {
    const { email, idTipologia, idCliente, dateFrom, dateTo, offset, limit } =
      action.payload;
    const response: GetAllQrCodeRequestResponse = yield call(
      getAllQrCodeRequestWithFiltersApi,
      email,
      idTipologia,
      idCliente,
      dateFrom,
      dateTo,
      offset,
      limit
    );
    yield put(getAllQrCodeRequestSucceeded(response));
  } catch (e) {
    yield put(getAllQrCodeRequestFailed());
  }
}

export default function* qrCodeRequestSaga(): SagaIterator {
  yield takeLatest(GET_ALL_QRCODE_REQUEST, getAllQrCodeRequest);
  yield takeLatest(
    GET_QRCODE_REQUEST_WITH_FILTERS,
    getQrCodeRequestWithFilters
  );
}
