// =============== //
// Actions //
import { GetAllQrCodeRequestResponse } from "./interfaces";

export const GET_ALL_QRCODE_REQUEST_SUCCEEDED =
  "GET_ALL_QRCODE_REQUEST_SUCCEEDED";
export const GET_ALL_QRCODE_REQUEST_FAILED = "GET_ALL_QRCODE_REQUEST_FAILED";
export const GET_ALL_QRCODE_REQUEST = "GET_ALL_QRCODE_REQUEST";
export const GET_QRCODE_REQUEST_WITH_FILTERS =
  "GET_QRCODE_REQUEST_WITH_FILTERS";

// =============== //
// Action types //
export type GetAllQrCodeRequest = {
  type: typeof GET_ALL_QRCODE_REQUEST;
  payload: {
    offset: number;
    limit: number;
  };
};

export type GetQrCodeRequestWithFilters = {
  type: typeof GET_QRCODE_REQUEST_WITH_FILTERS;
  payload: {
    email: string;
    idTipologia: string;
    idCliente: string;
    dateFrom: string;
    dateTo: string;
    offset: number;
    limit: number;
  };
};

type GetAllQrCodeRequestSucceeded = {
  type: typeof GET_ALL_QRCODE_REQUEST_SUCCEEDED;
  payload: GetAllQrCodeRequestResponse;
};

type GetAllQrCodeRequestFailed = {
  type: typeof GET_ALL_QRCODE_REQUEST_FAILED;
  payload: null;
};

// =============== //
// Creators //
export const getAllQrCodeRequest = (
  offset: number,
  limit: number
): GetAllQrCodeRequest => ({
  type: GET_ALL_QRCODE_REQUEST,
  payload: { offset, limit },
});

export const getQrCodeRequestWithFilters = (
  email: string,
  idTipologia: string,
  idCliente: string,
  dateFrom: string,
  dateTo: string,
  offset: number,
  limit: number
): GetQrCodeRequestWithFilters => ({
  type: GET_QRCODE_REQUEST_WITH_FILTERS,
  payload: { email, idTipologia, idCliente, dateFrom, dateTo, offset, limit },
});

export const getAllQrCodeRequestSucceeded = (
  response: GetAllQrCodeRequestResponse
): GetAllQrCodeRequestSucceeded => ({
  type: GET_ALL_QRCODE_REQUEST_SUCCEEDED,
  payload: response,
});

export const getAllQrCodeRequestFailed = (): GetAllQrCodeRequestFailed => ({
  type: GET_ALL_QRCODE_REQUEST_FAILED,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | GetAllQrCodeRequest
  | GetQrCodeRequestWithFilters
  | GetAllQrCodeRequestSucceeded
  | GetAllQrCodeRequestFailed;
