import React, { FC, useCallback } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface Props {
  isOpen: boolean;
  dialogTitle: string;
  dialogContent: string;
  buttonSuccessTitle: string;
  buttonFailureTitle?: string;
  buttonSuccessAction: () => void;
  handleClose: () => void;
}

const AlertDialog: FC<Props> = ({
  isOpen = false,
  dialogTitle,
  dialogContent,
  buttonSuccessTitle,
  buttonFailureTitle,
  buttonSuccessAction,
  handleClose,
}): JSX.Element => {
  const dialogContentComposer = useCallback((dialogContent: string):
    | JSX.Element
    | JSX.Element[] => {
    if (dialogContent) {
      const splittedContent = dialogContent.split("\n");
      return splittedContent.map((row, index) => (
        <DialogContentText key={index + row}>{row}</DialogContentText>
      ));
    }
    return <DialogContentText />;
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent id="alert-dialog-description">
        {dialogContentComposer(dialogContent)}
      </DialogContent>
      <DialogActions>
        <Button onClick={buttonSuccessAction} color="primary">
          {buttonSuccessTitle}
        </Button>
        {buttonFailureTitle && (
          <Button onClick={handleClose} color="primary" autoFocus>
            {buttonFailureTitle}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default AlertDialog;
