import {
  Action,
  CREATE_CLIENTE,
  CREATE_CLIENTE_FAILED,
  CREATE_CLIENTE_RESET,
  CREATE_CLIENTE_SUCCEEDED,
} from "./actions";

export interface CreateClienteState {
  readonly data: { message: string; isCreated: boolean } | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialCreateClienteState: CreateClienteState = {
  data: null,
  isFetching: false,
  isFetched: false,
};

const CreateClienteReducer = (
  state = initialCreateClienteState,
  action: Action
): CreateClienteState => {
  switch (action.type) {
    case CREATE_CLIENTE: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case CREATE_CLIENTE_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case CREATE_CLIENTE_FAILED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: false,
      };
    }
    case CREATE_CLIENTE_RESET: {
      return initialCreateClienteState;
    }
    default:
      return state;
  }
};

export default CreateClienteReducer;
