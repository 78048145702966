import {
  Action,
  CREATE_MARKER,
  CREATE_MARKER_FAILED,
  CREATE_MARKER_RESET,
  CREATE_MARKER_SUCCEEDED,
} from "./actions";

export interface CreateMarkerState {
  readonly data: { message: string; isCreated: boolean } | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialCreateMarkerState: CreateMarkerState = {
  data: null,
  isFetching: false,
  isFetched: false,
};

const CreateMarkerReducer = (
  state = initialCreateMarkerState,
  action: Action
): CreateMarkerState => {
  switch (action.type) {
    case CREATE_MARKER: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case CREATE_MARKER_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case CREATE_MARKER_FAILED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: false,
      };
    }
    case CREATE_MARKER_RESET: {
      return initialCreateMarkerState;
    }
    default:
      return state;
  }
};

export default CreateMarkerReducer;
