import { DEFAULT_HEADERS, ENDPOINT, ENDPOINTS_BASE } from "../../constants";
import { LoginResponse } from "./interfaces";

export async function fetchLoginUser(
  email: string,
  password: string
): Promise<LoginResponse> {
  return fetch(`${ENDPOINTS_BASE}${ENDPOINT.REGISTER_USER}`, {
    method: "POST",
    headers: DEFAULT_HEADERS,
    body: JSON.stringify({
      email,
      password: btoa(password),
    }),
  }).then((response) => response.json());
}
