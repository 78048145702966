import { AUTHORIZED_HEADERS, ENDPOINT, ENDPOINTS_BASE } from "../../constants";
import { GetAllRuoloUtenteResponse } from "./interfaces";

export async function getAllRuoloUtenteApi(): Promise<GetAllRuoloUtenteResponse> {
  return fetch(`${ENDPOINTS_BASE}${ENDPOINT.GET_ALL_RUOLO_UTENTE}`, {
    method: "GET",
    headers: AUTHORIZED_HEADERS,
  }).then((response) => response.json());
}

export async function getAllRuoloUtenteDashboardApi(): Promise<GetAllRuoloUtenteResponse> {
  return fetch(`${ENDPOINTS_BASE}${ENDPOINT.GET_RUOLO_UTENTE_DASHBOARD}`, {
    method: "GET",
    headers: AUTHORIZED_HEADERS,
  }).then((response) => response.json());
}
