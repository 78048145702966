// =============== //
// Actions //

import { MarkerBackgroundResponse } from "./interfaces";

export const READ_MARKER_BACKGROUND_SUCCEEDED =
  "READ_MARKER_BACKGROUND_SUCCEEDED";
export const READ_MARKER_BACKGROUND_FAILED = "READ_MARKER_BACKGROUND_FAILED";
export const READ_MARKER_BACKGROUND_RESET = "READ_MARKER_BACKGROUND_RESET";
export const READ_MARKER_BACKGROUND = "READ_MARKER_BACKGROUND";

// =============== //
// Action types //
export type ReadMarkerBackground = {
  type: typeof READ_MARKER_BACKGROUND;
  payload: {
    markerCode: string;
    isEdit?: boolean;
  };
};

type ReadMarkerBackgroundSucceeded = {
  type: typeof READ_MARKER_BACKGROUND_SUCCEEDED;
  payload: MarkerBackgroundResponse;
};

type ReadMarkerBackgroundFailed = {
  type: typeof READ_MARKER_BACKGROUND_FAILED;
  payload: null;
};

type ReadMarkerBackgroundReset = {
  type: typeof READ_MARKER_BACKGROUND_RESET;
  payload: null;
};

// =============== //
// Creators //
export const readMarkerBackground = (
  markerCode: string,
  isEdit: boolean
): ReadMarkerBackground => ({
  type: READ_MARKER_BACKGROUND,
  payload: { markerCode, isEdit },
});

export const readMarkerBackgroundSucceeded = (
  response: MarkerBackgroundResponse
): ReadMarkerBackgroundSucceeded => ({
  type: READ_MARKER_BACKGROUND_SUCCEEDED,
  payload: response,
});

export const readMarkerBackgroundFailed = (): ReadMarkerBackgroundFailed => ({
  type: READ_MARKER_BACKGROUND_FAILED,
  payload: null,
});

export const readMarkerBackgroundReset = (): ReadMarkerBackgroundReset => ({
  type: READ_MARKER_BACKGROUND_RESET,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | ReadMarkerBackground
  | ReadMarkerBackgroundSucceeded
  | ReadMarkerBackgroundFailed
  | ReadMarkerBackgroundReset;
