import { call, put, takeLatest } from "redux-saga/effects";

import { fetchClienteWithFiltersApi, getAllClienteApi } from "./api";
import {
  FIND_CLIENTE_WITH_FILTERS,
  FindClienteWithFilters,
  GET_ALL_CLIENTE,
  GetAllCliente,
  getAllClienteFailed,
  getAllClienteSucceeded,
} from "./actions";
import { GetAllClienteResponse } from "./interfaces";
import { SagaIterator } from "@redux-saga/types";

function* findAllUser(action: GetAllCliente): SagaIterator {
  try {
    const { offset, limit } = action.payload;
    const response: GetAllClienteResponse = yield call(
      getAllClienteApi,
      offset,
      limit
    );
    yield put(getAllClienteSucceeded(response));
  } catch (e) {
    yield put(getAllClienteFailed());
  }
}

function* findClienteWithFilters(action: FindClienteWithFilters): SagaIterator {
  try {
    const { nome, codiceMarker, offset, limit } = action.payload;
    const response: GetAllClienteResponse = yield call(
      fetchClienteWithFiltersApi,
      nome,
      codiceMarker,
      offset,
      limit
    );
    yield put(getAllClienteSucceeded(response));
  } catch (e) {
    yield put(getAllClienteFailed());
  }
}

export default function* clienteSaga(): SagaIterator {
  yield takeLatest(GET_ALL_CLIENTE, findAllUser);
  yield takeLatest(FIND_CLIENTE_WITH_FILTERS, findClienteWithFilters);
}
