import { call, put, takeLatest } from "redux-saga/effects";

import { getUserReportApi } from "./api";
import {
  GET_USER_REPORT,
  GetUserReport,
  getUserReportFailed,
  getUserReportSucceeded,
} from "./actions";
import { UserReportResponse } from "./interfaces";
import { SagaIterator } from "@redux-saga/types";

function* getUserReport(action: GetUserReport): SagaIterator {
  try {
    const { email, ruoloId, ambito, dateFrom, dateTo } = action.payload;
    const response: UserReportResponse = yield call(
      getUserReportApi,
      email,
      ruoloId,
      ambito,
      dateFrom,
      dateTo
    );
    yield put(getUserReportSucceeded(response));
  } catch (e) {
    yield put(getUserReportFailed());
  }
}

export default function* userReportSaga(): SagaIterator {
  yield takeLatest(GET_USER_REPORT, getUserReport);
}
