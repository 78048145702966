import { combineReducers, Reducer } from "redux";
import { MyAction } from "shared";

import appReducer, { AppState } from "./app-reducer";
import loginReducer, { LoginState } from "../pages/Login/reducer";
import userReducer, { UsersState } from "../pages/UserList/reducer";
import createUserReducer, {
  CreateUsersState,
} from "../pages/UserCRUD/CreateUser/reducer";
import updateUsersReducer, {
  UpdateUsersState,
} from "../pages/UserCRUD/UpdateUser/reducer";
import deleteUsersReducer, {
  DeleteUsersState,
} from "../pages/UserCRUD/DeleteUser/reducer";
import changePasswordReducer, {
  ChangePasswordState,
} from "../components/ResetPasswordPage/reducer";

import markerReducer, { MarkerState } from "../pages/MarkerList/reducer";
import tipoRisorsaReducer, {
  TipoRisorsaState,
} from "../common/tipoRisorsa/reducer";
import deleteMarkerReducer, {
  DeleteMarkerState,
} from "../pages/MarkerCRUD/DeleteMarker/reducer";
import createMarkerReducer, {
  CreateMarkerState,
} from "../pages/MarkerCRUD/CreateMarker/reducer";
import updateMarkerReducer, {
  UpdateMarkerState,
} from "../pages/MarkerCRUD/UpdateMarker/reducer";
import nextMarkerCodeReducer, {
  NextMarkerCodeState,
} from "../pages/MarkerCRUD/NextMarkerCode/reducer";
import readMarkerBackgroundReducer, {
  ReadMarkerBackgroundState,
} from "../pages/MarkerCRUD/ReadMarkerBackground/reducer";
import ruoloUtenteReducer, {
  RuoloUtenteState,
} from "../common/ruoloUtente/reducer";
import markerRequestReducer, {
  MarkerRequestState,
} from "../pages/MarkerRequestList/reducer";
import qrCodeRequestReducer, {
  QrCodeRequestState,
} from "../pages/QrCodeRequestList/reducer";
import statisticheAllReducer, {
  StatisticheState,
} from "../pages/Dashboard/statistics/reducer";
import userReportReducer, {
  UserReportState,
} from "../pages/UserCRUD/Report/reducer";
import clienteReducer, { ClienteState } from "../pages/ClienteList/reducer";
import createClienteReducer, {
  CreateClienteState,
} from "../pages/ClienteCRUD/CreateCliente/reducer";
import updateClienteReducer, {
  UpdateClienteState,
} from "../pages/ClienteCRUD/UpdateCliente/reducer";
import deleteClienteReducer, {
  DeleteClienteState,
} from "../pages/ClienteCRUD/DeleteCliente/reducer";
import markerRequestReportReducer, {
  MarkerRequestReportState,
} from "../pages/MarkerRequestList/Report/reducer";
import qrCodeRequestReportReducer, {
  QrCodeRequestReportState,
} from "../pages/QrCodeRequestList/Report/reducer";
import ClienteNoPagReducer, {
  ClienteNoPagState,
} from "../common/cliente/reducer";
import searchMarkerCodeReducer, {
  SearchMarkerCodeState,
} from "../pages/MarkerCRUD/SearchMarkerCode/reducer";

export interface RootState {
  app: AppState;

  login: LoginState;

  users: UsersState;
  createUser: CreateUsersState;
  updateUser: UpdateUsersState;
  deleteUser: DeleteUsersState;

  changePassword: ChangePasswordState;

  marker: MarkerState;
  createMarker: CreateMarkerState;
  updateMarker: UpdateMarkerState;
  deleteMarker: DeleteMarkerState;
  searchMarkerCode: SearchMarkerCodeState;
  markerRequest: MarkerRequestState;
  readMarkerBackground: ReadMarkerBackgroundState;

  tipoRisorsa: TipoRisorsaState;
  nextMarkerCode: NextMarkerCodeState;
  ruoloUtente: RuoloUtenteState;

  qrCodeRequest: QrCodeRequestState;
  statisticheAll: StatisticheState;

  userReport: UserReportState;
  markerRequestReport: MarkerRequestReportState;
  qrCodeRequestReport: QrCodeRequestReportState;

  cliente: ClienteState;
  createCliente: CreateClienteState;
  updateCliente: UpdateClienteState;
  deleteCliente: DeleteClienteState;
  clienteNoPag: ClienteNoPagState;
}

export default function getRootReducer(): Reducer<RootState, MyAction> {
  return combineReducers({
    app: appReducer,
    login: loginReducer,
    users: userReducer,
    createUser: createUserReducer,
    updateUser: updateUsersReducer,
    deleteUser: deleteUsersReducer,
    changePassword: changePasswordReducer,
    marker: markerReducer,
    createMarker: createMarkerReducer,
    updateMarker: updateMarkerReducer,
    deleteMarker: deleteMarkerReducer,
    searchMarkerCode: searchMarkerCodeReducer,
    readMarkerBackground: readMarkerBackgroundReducer,
    tipoRisorsa: tipoRisorsaReducer,
    nextMarkerCode: nextMarkerCodeReducer,
    ruoloUtente: ruoloUtenteReducer,
    markerRequest: markerRequestReducer,
    qrCodeRequest: qrCodeRequestReducer,
    qrCodeRequestReport: qrCodeRequestReportReducer,
    statisticheAll: statisticheAllReducer,
    userReport: userReportReducer,
    markerRequestReport: markerRequestReportReducer,
    cliente: clienteReducer,
    createCliente: createClienteReducer,
    updateCliente: updateClienteReducer,
    deleteCliente: deleteClienteReducer,
    clienteNoPag: ClienteNoPagReducer,
  });
}
