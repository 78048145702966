import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import { useNavigate } from "react-router-dom";
import { ChangeEvent, FC } from "react";
import { isUserAuthorized } from "../../utils";
import { AUTHORIZATION, ROUTE_PATH } from "../../constants";

interface Props {
  handleChangeFilter: (event: ChangeEvent<HTMLInputElement>) => void;
}

const ClienteListToolbar: FC<Props> = ({
  handleChangeFilter,
  ...props
}): JSX.Element => {
  const navigate = useNavigate();

  return (
    <Box {...props}>
      {isUserAuthorized(AUTHORIZATION.CL_E) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => navigate(`/${ROUTE_PATH.APP}/${ROUTE_PATH.CLIENTE}`)}
          >
            Inserisci Cliente
          </Button>
        </Box>
      )}

      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid sx={{ mt: 1 }} container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={5}>
                <TextField
                  name="nome"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Nome"
                  variant="outlined"
                  onChange={handleChangeFilter}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={2}>
                <TextField
                  name="codiceMarker"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Marker"
                  variant="outlined"
                  onChange={handleChangeFilter}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default ClienteListToolbar;
