import {
  AUTHORIZED_HEADERS,
  ENDPOINT,
  ENDPOINTS_BASE,
} from "../../../constants";
import { MarkerBackgroundResponse } from "./interfaces";

export async function readMarkerBackgroundApi(
  markerCode: string,
  isEdit: boolean
): Promise<MarkerBackgroundResponse> {
  return fetch(
    `${ENDPOINTS_BASE}${ENDPOINT.GET_MARKER_BACKGROUND}?codMarker=${markerCode}&isEdit=${isEdit}`,
    {
      method: "GET",
      headers: AUTHORIZED_HEADERS,
    }
  ).then((response) => response.json());
}
