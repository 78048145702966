import {
  Action,
  FIND_MARKER_WITH_FILTERS,
  FINDALL_MARKER,
  FINDALL_MARKER_FAILED,
  FINDALL_MARKER_SUCCEEDED,
  GET_QR_IMAGE,
  GET_QR_IMAGE_SUCCEEDED,
  GET_QR_IMAGE_FAILED,
  REMOVE_QR_IMAGE,
} from "./actions";
import { FindAllMarkerResponse, GetQrImageResponse } from "./interfaces";

export interface MarkerState {
  readonly data: FindAllMarkerResponse | null;
  readonly qrImageResponse: GetQrImageResponse | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialMarkerState: MarkerState = {
  data: null,
  qrImageResponse: null,
  isFetching: false,
  isFetched: false,
};

const MarkerReducer = (
  state = initialMarkerState,
  action: Action
): MarkerState => {
  switch (action.type) {
    case FINDALL_MARKER: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case FIND_MARKER_WITH_FILTERS: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case FINDALL_MARKER_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case FINDALL_MARKER_FAILED: {
      return { ...state, isFetching: false, isFetched: false };
    }

    case GET_QR_IMAGE: {
      return { ...state, qrImageResponse: null };
    }
    case REMOVE_QR_IMAGE: {
      return { ...state, qrImageResponse: null };
    }
    case GET_QR_IMAGE_SUCCEEDED: {
      return {
        ...state,
        qrImageResponse: action.payload,
      };
    }
    case GET_QR_IMAGE_FAILED: {
      return { ...state, qrImageResponse: null };
    }
    default:
      return state;
  }
};

export default MarkerReducer;
