import { FC } from "react";
import Box from "@material-ui/core/Box";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Portal from "@material-ui/core/Portal";
import { SxProps } from "@material-ui/system";

interface Props {
  byte: string;
  open: boolean;
  handleClickAway: () => void;
}
const PortalClickAway: FC<Props> = ({
  byte,
  open,
  handleClickAway,
}): JSX.Element => {
  const styles: SxProps = {
    position: "fixed",
    width: 245,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    p: 1,
    bgcolor: "background.paper",
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        {open ? (
          <Portal>
            <Box sx={styles}>
              <img
                src={`data:image/png;base64,${byte}`}
                alt={"test alt"}
                loading="lazy"
              />
            </Box>
          </Portal>
        ) : null}
      </div>
    </ClickAwayListener>
  );
};
export default PortalClickAway;
