// =============== //
// Actions //
import { GetAllClienteResponse } from "./interfaces";

export const GET_ALL_CLIENTE_SUCCEEDED = "GET_ALL_CLIENTE_SUCCEEDED";
export const GET_ALL_CLIENTE_FAILED = "GET_ALL_CLIENTE_FAILED";
export const GET_ALL_CLIENTE = "GET_ALL_CLIENTE";
export const FIND_CLIENTE_WITH_FILTERS = "FIND_CLIENTE_WITH_FILTERS";

// =============== //
// Action types //
export type GetAllCliente = {
  type: typeof GET_ALL_CLIENTE;
  payload: {
    offset: number;
    limit: number;
  };
};

export type FindClienteWithFilters = {
  type: typeof FIND_CLIENTE_WITH_FILTERS;
  payload: {
    nome: string;
    codiceMarker: string;
    offset: number;
    limit: number;
  };
};

type GetAllClienteSucceeded = {
  type: typeof GET_ALL_CLIENTE_SUCCEEDED;
  payload: GetAllClienteResponse;
};

type GetAllClienteFailed = {
  type: typeof GET_ALL_CLIENTE_FAILED;
  payload: null;
};

// =============== //
// Creators //
export const getAllCliente = (
  offset: number,
  limit: number
): GetAllCliente => ({
  type: GET_ALL_CLIENTE,
  payload: { offset, limit },
});

export const findClienteWithFilters = (
  nome: string,
  codiceMarker: string,
  offset: number,
  limit: number
): FindClienteWithFilters => ({
  type: FIND_CLIENTE_WITH_FILTERS,
  payload: { nome, codiceMarker, offset, limit },
});

export const getAllClienteSucceeded = (
  response: GetAllClienteResponse
): GetAllClienteSucceeded => ({
  type: GET_ALL_CLIENTE_SUCCEEDED,
  payload: response,
});

export const getAllClienteFailed = (): GetAllClienteFailed => ({
  type: GET_ALL_CLIENTE_FAILED,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | GetAllCliente
  | FindClienteWithFilters
  | GetAllClienteSucceeded
  | GetAllClienteFailed;
