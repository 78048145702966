import { AUTHORIZED_HEADERS, ENDPOINT, ENDPOINTS_BASE } from "../../constants";
import { GetAllQrCodeRequestResponse } from "./interfaces";

export async function getAllQrCodeRequestApi(
  offset: number,
  limit: number
): Promise<GetAllQrCodeRequestResponse> {
  return fetch(
    `${ENDPOINTS_BASE}${ENDPOINT.GET_ALL_QRCODE_REQUEST}?offset=${offset}&limit=${limit}`,
    {
      method: "GET",
      headers: AUTHORIZED_HEADERS,
    }
  ).then((response) => response.json());
}

export async function getAllQrCodeRequestWithFiltersApi(
  email: string,
  idTipologia: string,
  idCliente: string,
  dateFrom: string,
  dateTo: string,
  offset: number,
  limit: number
): Promise<GetAllQrCodeRequestResponse> {
  return fetch(
    `${ENDPOINTS_BASE}${ENDPOINT.GET_QRCODE_REQUEST_WITH_FILTERS}?emailUtente=${email}&idTipologia=${idTipologia}&idCliente=${idCliente}&dateFrom=${dateFrom}&dateTo=${dateTo}&offset=${offset}&limit=${limit}`,
    {
      method: "GET",
      headers: AUTHORIZED_HEADERS,
    }
  ).then((response) => response.json());
}
