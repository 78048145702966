import { Helmet } from "react-helmet";
import { Box, Container } from "@material-ui/core";
import React, {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { findAllMarker, findMarkerWithFilters } from "./actions";
import { Marker } from "./interfaces";
import MarkerListResults from "../../components/marker/MarkerListResults";
import MarkerListToolbar from "../../components/marker/MarkerListToolbar";
import { getAllTipoRisorsa } from "../../common/tipoRisorsa/actions";
import { TipoRisorsa } from "../../common/tipoRisorsa/interfaces";
import { getAllClienteNoPag } from "../../common/cliente/actions";
import { Cliente } from "../ClienteList/interfaces";
import CustomLoader from "../../components/CustomLoader/CustomLoader";

export interface MarkerFilters {
  cliente: { id: string; nome: string };
  codiceMarker: string;
  tipologia: { id: string; codice: string };
  ambito: string;
  isActive: boolean | null;
}

const MarkerList: FC = () => {
  const dispatch = useAppDispatch();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const [markerFilters, setMarkerFilters] = useState<MarkerFilters>({
    cliente: { id: "", nome: "" },
    codiceMarker: "",
    tipologia: { id: "", codice: "" },
    ambito: "",
    isActive: null,
  });

  const areMarkerFiltersFilled = (): boolean => {
    const { cliente, codiceMarker, tipologia, ambito, isActive } =
      markerFilters;
    return (!!cliente.id ||
      !!codiceMarker ||
      !!tipologia.id ||
      !!ambito ||
      isActive) as boolean;
  };

  const markerList: Marker[] =
    useAppSelector((state) => state.marker.data?.markers) ?? [];

  const isMarkerDeleted = useAppSelector(
    (state) => state.deleteMarker.data?.isDeleted
  );

  const isMarkerUpdated = useAppSelector(
    (state) => state.updateMarker.data?.isUpdated
  );

  const isMarkerFetching = useAppSelector((state) => state.marker.isFetching);
  const isMarkerFetched = useAppSelector((state) => state.marker.isFetched);

  useEffect(() => {
    dispatch(getAllTipoRisorsa());
    dispatch(getAllClienteNoPag());
  }, []);

  useEffect(() => {
    setPage(0);
    setLimit(10);
    const offset = page * limit;
    if (areMarkerFiltersFilled()) {
      const { cliente, codiceMarker, tipologia, ambito, isActive } =
        markerFilters;
      dispatch(
        findMarkerWithFilters(
          cliente.id,
          codiceMarker,
          tipologia.id,
          ambito,
          isActive,
          offset,
          limit
        )
      );
    } else {
      dispatch(findAllMarker(offset, limit));
    }
  }, [
    markerFilters.ambito.length,
    markerFilters.codiceMarker.length,
    markerFilters.tipologia.id,
    markerFilters.cliente.id,
    markerFilters.isActive,
    isMarkerDeleted,
    isMarkerUpdated,
  ]);

  useEffect(() => {
    const offset = page * limit;
    if (areMarkerFiltersFilled()) {
      const { cliente, codiceMarker, tipologia, ambito, isActive } =
        markerFilters;
      dispatch(
        findMarkerWithFilters(
          cliente.id,
          codiceMarker,
          tipologia.id,
          ambito,
          isActive,
          offset,
          limit
        )
      );
    } else {
      dispatch(findAllMarker(offset, limit));
    }
  }, [limit, page]);

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setMarkerFilters({
      ...markerFilters,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeAutocompleteTipoRisorsaFilter = (
    event: SyntheticEvent,
    selectedTipoRisorsa: TipoRisorsa | null
  ) => {
    if (selectedTipoRisorsa) {
      setMarkerFilters((prevState) => {
        return {
          ...prevState,
          tipologia: {
            id: selectedTipoRisorsa.id.toString(),
            codice: selectedTipoRisorsa.codice,
          },
        };
      });
    } else {
      setMarkerFilters((prevState) => {
        return {
          ...prevState,
          tipologia: {
            id: "",
            codice: "",
          },
        };
      });
    }
  };

  const handleChangeTextTipoRisorsaFilter = (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => {
    setMarkerFilters({
      ...markerFilters,
      tipologia: { ...markerFilters.tipologia, codice: target.value },
    });
  };

  const handleChangeAutocompleteClienteFilter = (
    event: SyntheticEvent,
    selectedCliente: Cliente | null
  ) => {
    if (selectedCliente) {
      setMarkerFilters((prevState) => {
        return {
          ...prevState,
          cliente: {
            id: selectedCliente.id.toString(),
            nome: selectedCliente.nome,
          },
        };
      });
    } else {
      setMarkerFilters((prevState) => {
        return {
          ...prevState,
          cliente: {
            id: "",
            nome: "",
          },
        };
      });
    }
  };

  const handleChangeTextClienteFilter = (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => {
    setMarkerFilters((prevState) => {
      return {
        ...prevState,
        cliente: { ...prevState.cliente, nome: target.value },
      };
    });
  };

  const handleChangeCheckbox = (): void => {
    setMarkerFilters((prevState) => {
      const getNewValue = (): boolean | null => {
        if (prevState.isActive === null) {
          return true;
        }
        return null;
      };
      return { ...prevState, isActive: getNewValue() };
    });
  };

  return (
    <>
      <Helmet>
        <title>Marker | DxMarker</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <MarkerListToolbar
            handleChangeFilter={handleChangeFilter}
            handleChangeAutocompleteTipoRisorsaFilter={
              handleChangeAutocompleteTipoRisorsaFilter
            }
            handleChangeTextTipoRisorsaFilter={
              handleChangeTextTipoRisorsaFilter
            }
            markerFilters={markerFilters}
            handleChangeAutocompleteClienteFilter={
              handleChangeAutocompleteClienteFilter
            }
            handleChangeTextClienteFilter={handleChangeTextClienteFilter}
            handleChangeCheckbox={handleChangeCheckbox}
          />
          <Box sx={{ pt: 3 }}>
            <CustomLoader visible={isMarkerFetching} />
            {isMarkerFetched && (
              <MarkerListResults
                markerList={markerList}
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
              />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MarkerList;
