import React, { FC, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { Edit as EditIcon, Trash2 as DelIcon } from "react-feather";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import AlertDialog from "../AlertDialog/AlertDialog";
import { Cliente } from "../../pages/ClienteList/interfaces";
import { renderIsActive } from "../../utils/returntype";
import { deleteCliente } from "../../pages/ClienteCRUD/DeleteCliente/actions";
import { AUTHORIZATION, ROUTE_PATH } from "../../constants";
import { isUserAuthorized } from "../../utils";

interface Props {
  clienteList: Cliente[];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
}

const ClienteListResults: FC<Props> = ({
  clienteList,
  page,
  setPage,
  limit,
  setLimit,
  ...rest
}) => {
  const [clienteToDelete, setClienteToDelete] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const totalRecord =
    useAppSelector((state) => state.cliente.data?.totalRecord) ?? 0;

  const handleLimitChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLimit(Number(event.target.value));
    setPage(0);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleClickCliente = (clienteId: number) => {
    navigate(`/${ROUTE_PATH.APP}/${ROUTE_PATH.CLIENTE}`, {
      state: { clienteId },
    });
  };

  const handleDeleteCliente = (): void => {
    if (clienteToDelete) {
      dispatch(deleteCliente(clienteToDelete));
      setIsModalOpen(false);
    }
  };

  const handleOpenModal = (clienteId: number): void => {
    setClienteToDelete(clienteId);
    setIsModalOpen(true);
  };

  const uniqueMarkers = (markers: Array<number>): string => {
    const uniqueMarkers: number[] = Array.from(new Set(markers));
    const sortedUniqueMarkers = uniqueMarkers.sort((a, b) => (a > b ? 1 : -1));
    return sortedUniqueMarkers.map((markerCode) => `${markerCode}`).join(", ");
  };

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome</TableCell>
                  <TableCell>Descrizione Attività</TableCell>
                  <TableCell>Sede</TableCell>
                  <TableCell>Indirizzo email</TableCell>
                  <TableCell>Marker</TableCell>
                  <TableCell align="center">Attivo</TableCell>
                  <TableCell align="center">Modifica</TableCell>
                  <TableCell align="center">Elimina</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clienteList.map((cliente: Cliente) => (
                  <TableRow hover key={cliente.id} id={cliente.id.toString()}>
                    <TableCell>{cliente.nome}</TableCell>
                    <TableCell>{cliente.descrizione}</TableCell>
                    <TableCell>{cliente.sede}</TableCell>
                    <TableCell>{cliente.email}</TableCell>
                    <TableCell>{uniqueMarkers(cliente.codMarkers)}</TableCell>

                    <TableCell align="center">
                      {renderIsActive(cliente.isActive)}
                    </TableCell>
                    <TableCell align="center">
                      {isUserAuthorized(AUTHORIZATION.CL_E) && (
                        <EditIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClickCliente(cliente.id)}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {isUserAuthorized(AUTHORIZATION.CL_E) && (
                        <DelIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => handleOpenModal(cliente.id)}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={totalRecord}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage={"Righe per pagina"}
        />
      </Card>
      <AlertDialog
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        dialogTitle={"Elimina Cliente"}
        dialogContent={"Sei sicuro di eliminare il cliente?"}
        buttonSuccessTitle={"Si"}
        buttonFailureTitle={"No"}
        buttonSuccessAction={handleDeleteCliente}
      />
    </>
  );
};

export default ClienteListResults;
