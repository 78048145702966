import {
  Action,
  SEARCH_MARKER_CODE,
  SEARCH_MARKER_CODE_FAILED,
  SEARCH_MARKER_CODE_RESET,
  SEARCH_MARKER_CODE_SUCCEEDED,
} from "./actions";
import { QrImageResponse } from "./interfaces";

export interface SearchMarkerCodeState {
  readonly data: QrImageResponse | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialSearchMarkerCode: SearchMarkerCodeState = {
  data: null,
  isFetching: false,
  isFetched: false,
};

const searchMarkerCodeReducer = (
  state = initialSearchMarkerCode,
  action: Action
): SearchMarkerCodeState => {
  switch (action.type) {
    case SEARCH_MARKER_CODE: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case SEARCH_MARKER_CODE_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case SEARCH_MARKER_CODE_FAILED: {
      return { ...state, isFetching: false, isFetched: false };
    }
    case SEARCH_MARKER_CODE_RESET: {
      return initialSearchMarkerCode;
    }
    default:
      return state;
  }
};

export default searchMarkerCodeReducer;
