import { call, put, takeLatest } from "redux-saga/effects";

import { createUserApi } from "./api";
import {
  CREATE_USER,
  CreateUser,
  createUserFailed,
  createUserSucceeded,
} from "./actions";
import { CreateUserResponse } from "./interfaces";
import { SagaIterator } from "@redux-saga/types";

function* createUser(action: CreateUser): SagaIterator {
  try {
    const { email, password, ruoloUtente, isActive } = action.payload;

    const response: CreateUserResponse = yield call(
      createUserApi,
      email,
      btoa(password),
      ruoloUtente,
      isActive
    );
    const responsePayload = {
      message: response,
      isCreated: true,
    };
    yield put(createUserSucceeded(responsePayload));
  } catch (e) {
    console.log("createUserSaga error: ", e);
    const responsePayload = {
      message: e.toString(),
      isCreated: false,
    };
    yield put(createUserFailed(responsePayload));
  }
}

export default function* createUserSaga(): SagaIterator {
  yield takeLatest(CREATE_USER, createUser);
}
