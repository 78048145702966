import {
  AUTHORIZED_HEADERS,
  ENDPOINT,
  ENDPOINTS_BASE,
} from "../../../constants";

export async function deleteMarkerApi(markerId: number): Promise<string> {
  return fetch(
    `${ENDPOINTS_BASE}${ENDPOINT.DELETE_MARKER}?markerId=${markerId}`,
    {
      method: "DELETE",
      headers: AUTHORIZED_HEADERS,
    }
  ).then(async (response) => {
    if (response.ok) {
      return response.text();
    }
    throw new Error(await response.text());
  });
}
