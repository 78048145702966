import {
  Action,
  READ_MARKER_BACKGROUND,
  READ_MARKER_BACKGROUND_FAILED,
  READ_MARKER_BACKGROUND_RESET,
  READ_MARKER_BACKGROUND_SUCCEEDED,
} from "./actions";
import { MarkerBackgroundResponse } from "./interfaces";

export interface ReadMarkerBackgroundState {
  readonly data: MarkerBackgroundResponse | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialReadMarkerBackground: ReadMarkerBackgroundState = {
  data: null,
  isFetching: false,
  isFetched: false,
};

const ReadMarkerBackgroundReducer = (
  state = initialReadMarkerBackground,
  action: Action
): ReadMarkerBackgroundState => {
  switch (action.type) {
    case READ_MARKER_BACKGROUND: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case READ_MARKER_BACKGROUND_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case READ_MARKER_BACKGROUND_FAILED: {
      return { ...state, isFetching: false, isFetched: false };
    }
    case READ_MARKER_BACKGROUND_RESET: {
      return initialReadMarkerBackground;
    }
    default:
      return state;
  }
};

export default ReadMarkerBackgroundReducer;
