import { call, put, takeLatest } from "redux-saga/effects";

import { createClienteApi } from "./api";
import {
  CREATE_CLIENTE,
  CreateCliente,
  createClienteFailed,
  createClienteSucceeded,
} from "./actions";
import { CreateClienteResponse } from "./interfaces";
import { SagaIterator } from "@redux-saga/types";

function* createCliente(action: CreateCliente): SagaIterator {
  try {
    const { nome, email, sede, descrizione, isActive, isLogoColored } =
      action.payload;

    const response: CreateClienteResponse = yield call(
      createClienteApi,
      nome,
      email,
      sede,
      descrizione,
      isActive,
      isLogoColored
    );
    const responsePayload = {
      message: response,
      isCreated: true,
    };
    yield put(createClienteSucceeded(responsePayload));
  } catch (e) {
    console.log("createClienteSaga error: ", e);
    const responsePayload = {
      message: e.toString(),
      isCreated: false,
    };
    yield put(createClienteFailed(responsePayload));
  }
}

export default function* createClienteSaga(): SagaIterator {
  yield takeLatest(CREATE_CLIENTE, createCliente);
}
