import { AUTHORIZED_HEADERS, ENDPOINT, ENDPOINTS_BASE } from "../../constants";
import { GetAllClienteResponse } from "./interfaces";

export async function getAllClienteApi(
  offset: number,
  limit: number
): Promise<GetAllClienteResponse> {
  return fetch(
    `${ENDPOINTS_BASE}${ENDPOINT.GET_ALL_CLIENTE}?offset=${offset}&limit=${limit}`,
    {
      method: "GET",
      headers: AUTHORIZED_HEADERS,
    }
  ).then((response) => response.json());
}

export async function fetchClienteWithFiltersApi(
  nome: string,
  codiceMarker: string,
  offset: number,
  limit: number
): Promise<GetAllClienteResponse> {
  return fetch(
    `${ENDPOINTS_BASE}${ENDPOINT.FIND_CLIENTE_WITH_FILTERS}?nomeCliente=${nome}&codiceMarker=${codiceMarker}&offset=${offset}&limit=${limit}`,
    {
      method: "GET",
      headers: AUTHORIZED_HEADERS,
    }
  ).then((response) => response.json());
}
