import {
  Action,
  FINDALL_USER,
  FINDALL_USER_SUCCEEDED,
  FINDALL_USER_FAILED,
  FIND_USER_WITH_FILTERS,
} from "./actions";
import { FindAllUserResponse } from "./interfaces";

export interface UsersState {
  readonly data: FindAllUserResponse | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialUsersState: UsersState = {
  data: null,
  isFetching: false,
  isFetched: false,
};

const UsersReducer = (
  state = initialUsersState,
  action: Action
): UsersState => {
  switch (action.type) {
    case FINDALL_USER: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case FIND_USER_WITH_FILTERS: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case FINDALL_USER_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case FINDALL_USER_FAILED: {
      return { ...state, isFetching: false, isFetched: false };
    }
    default:
      return state;
  }
};

export default UsersReducer;
