// =============== //
// Actions //
import { QrcodeRequestStatisticheResponse } from "../interfaces";

export const GET_ALL_QRCODE_REQUESTS_SUCCEEDED =
  "GET_ALL_QRCODE_REQUESTS_SUCCEEDED";
export const GET_ALL_QRCODE_REQUESTS_FAILED = "GET_ALL_QRCODE_REQUESTS_FAILED";
export const GET_ALL_QRCODE_REQUESTS = "GET_ALL_QRCODE_REQUESTS";

// =============== //
// Action types //
export type GetAllQrcodeRequests = {
  type: typeof GET_ALL_QRCODE_REQUESTS;
  payload: null;
};

type GetAllQrcodeRequestsSucceeded = {
  type: typeof GET_ALL_QRCODE_REQUESTS_SUCCEEDED;
  payload: QrcodeRequestStatisticheResponse;
};

type GetAllQrcodeRequestsFailed = {
  type: typeof GET_ALL_QRCODE_REQUESTS_FAILED;
  payload: null;
};

// =============== //
// Creators //
export const getAllQrcodeRequests = (): GetAllQrcodeRequests => ({
  type: GET_ALL_QRCODE_REQUESTS,
  payload: null,
});

export const getAllQrcodeRequestsSucceeded = (
  response: QrcodeRequestStatisticheResponse
): GetAllQrcodeRequestsSucceeded => ({
  type: GET_ALL_QRCODE_REQUESTS_SUCCEEDED,
  payload: response,
});

export const getAllQrcodeRequestsFailed = (): GetAllQrcodeRequestsFailed => ({
  type: GET_ALL_QRCODE_REQUESTS_FAILED,
  payload: null,
});

// =============== //
// Exports //
export type QrcodeRequestAction =
  | GetAllQrcodeRequests
  | GetAllQrcodeRequestsSucceeded
  | GetAllQrcodeRequestsFailed;
