import {
  Action,
  DELETE_CLIENTE,
  DELETE_CLIENTE_FAILED,
  DELETE_CLIENTE_RESET,
  DELETE_CLIENTE_SUCCEEDED,
} from "./actions";

export interface DeleteClienteState {
  readonly data: { message: string; isDeleted: boolean } | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialDeleteClienteState: DeleteClienteState = {
  data: null,
  isFetching: false,
  isFetched: false,
};

const deleteClienteReducer = (
  state = initialDeleteClienteState,
  action: Action
): DeleteClienteState => {
  switch (action.type) {
    case DELETE_CLIENTE: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case DELETE_CLIENTE_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case DELETE_CLIENTE_FAILED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: false,
      };
    }
    case DELETE_CLIENTE_RESET: {
      return initialDeleteClienteState;
    }
    default:
      return state;
  }
};

export default deleteClienteReducer;
