// =============== //
// Actions //
//import { responsePayload } from "./interfaces";

import { ChangePasswordPayload } from "./interfaces";

export const CHANGE_PASSWORD_SUCCEEDED = "CHANGE_PASSWORD_SUCCEEDED";
export const CHANGE_PASSWORD_FAILED = "CHANGE_PASSWORD_FAILED";
export const CHANGE_PASSWORD_RESET = "CHANGE_PASSWORD_RESET";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

// =============== //
// Action types //
export type ChangePassword = {
  type: typeof CHANGE_PASSWORD;
  payload: {
    email: string;
    tinyId: string;
    password: string;
  };
};

type ChangePasswordSucceeded = {
  type: typeof CHANGE_PASSWORD_SUCCEEDED;
  payload: ChangePasswordPayload;
};

type ChangePasswordFailed = {
  type: typeof CHANGE_PASSWORD_FAILED;
  payload: ChangePasswordPayload;
};

type ChangePasswordReset = {
  type: typeof CHANGE_PASSWORD_RESET;
  payload: null;
};

// =============== //
// Creators //
export const changePassword = (
  email: string,
  password: string,
  tinyId: string
): ChangePassword => ({
  type: CHANGE_PASSWORD,
  payload: { email, tinyId, password },
});

export const changePasswordSucceeded = (
  response: ChangePasswordPayload
): ChangePasswordSucceeded => ({
  type: CHANGE_PASSWORD_SUCCEEDED,
  payload: response,
});

export const changePasswordFailed = (
  response: ChangePasswordPayload
): ChangePasswordFailed => ({
  type: CHANGE_PASSWORD_FAILED,
  payload: response,
});

export const changePasswordReset = (): ChangePasswordReset => ({
  type: CHANGE_PASSWORD_RESET,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | ChangePassword
  | ChangePasswordSucceeded
  | ChangePasswordFailed
  | ChangePasswordReset;
