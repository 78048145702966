import React, { ChangeEvent, FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  experimentalStyled,
  Grid,
  TextField,
} from "@material-ui/core";
import { useLocation, useNavigate } from "react-router";
import { ResetPassword } from "./interfaces";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { changePassword } from "./actions";
import { ROUTE_PATH } from "../../constants";

const LayoutRoot = experimentalStyled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
  overflow: "hidden",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
}));

const ResetPasswordPage: FC = ({ ...props }): JSX.Element => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [values, setValues] = useState<
    Pick<ResetPassword, "email" | "password" | "confirmPassword" | "tinyId">
  >({
    email: searchParams.get("user") ?? "",
    tinyId: searchParams.get("id") ?? "",
    password: "",
    confirmPassword: "",
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isPasswordChanged = useAppSelector(
    (state) => state.changePassword.data?.isChanged
  );

  const errorCode = useAppSelector((state) => state.changePassword.data?.code);

  const errorDescription = useAppSelector(
    (state) => state.changePassword.data?.description
  );

  useEffect(() => {
    if (values.email === "" || values.tinyId === "") {
      navigate(`/${ROUTE_PATH.ERROR_RESET_PASSWORD}`, {
        state: {
          description: "Parametri non valorizzati correttamente.",
        },
      });
    }

    if (isPasswordChanged) {
      navigate(`/${ROUTE_PATH.CONFIRMED_RESET_PASSWORD}`, {
        state: {
          user: values.email,
        },
      });
    } else if (isPasswordChanged === false) {
      navigate(`/${ROUTE_PATH.ERROR_RESET_PASSWORD}?code=${errorCode}`, {
        state: {
          description: errorDescription,
        },
      });
    }
  }, [isPasswordChanged]);

  //AGGIUNGERE CONTROLLO EMAIL E ID NON VUOTI

  const handleResetPasswordAction = () => {
    const { email, password, tinyId } = values;

    //CHECK THAT THE TWO PASSWORD MATCH
    dispatch(changePassword(email, password, tinyId));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <LayoutRoot>
      <h1>{`Modifica password di ${values.email}`}</h1>
      <br />
      <>
        <form
          autoComplete="off"
          noValidate
          {...props}
          key={"formResetPassword"}
        >
          <Card>
            <CardHeader
              name="title"
              //subheader="Modifica la password"
              title="Modifica la password"
            />
            <Divider />
            <CardContent id="container">
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    autoFocus={true}
                    label="Indirizzo email"
                    name="email"
                    InputProps={{
                      readOnly: true,
                      disabled: true,
                    }}
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Nuova Password"
                    name="password"
                    id="password"
                    onChange={handleChange}
                    key={"password"}
                    type="password"
                    required
                    value={values.password}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Conferma Password"
                    name="confirmPassword"
                    id="confirmPassword"
                    key={"confirmPassword"}
                    type="password"
                    onChange={handleChange}
                    required
                    value={values.confirmPassword}
                    variant="outlined"
                    error={values.password !== values.confirmPassword}
                    helperText={
                      values.password !== values.confirmPassword &&
                      "Le password devono essere identiche"
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2,
              }}
            >
              <Button
                color="primary"
                variant="contained"
                onClick={handleResetPasswordAction}
                disabled={
                  !values.password ||
                  !values.email ||
                  values.password !== values.confirmPassword
                }
              >
                Aggiorna
              </Button>
            </Box>
          </Card>
        </form>
      </>
      <img
        alt="Logo"
        src="/static/logo2_inverted.png"
        style={{ width: "16rem", margin: "3%" }}
      />
    </LayoutRoot>
  );
};

export default ResetPasswordPage;
