import { call, put, takeLatest } from "redux-saga/effects";

import { getQrCodeRequestReportApi } from "./api";
import {
  GET_QRCODE_REQUEST_REPORT,
  GetQrCodeRequestReport,
  getQrCodeRequestReportFailed,
  getQrCodeRequestReportSucceeded,
} from "./actions";
import { QrCodeRequestReportResponse } from "./interfaces";
import { SagaIterator } from "@redux-saga/types";

function* getQrCodeRequestReport(action: GetQrCodeRequestReport): SagaIterator {
  try {
    const { email, idTipologia, idCliente, dateFrom, dateTo } = action.payload;
    const response: QrCodeRequestReportResponse = yield call(
      getQrCodeRequestReportApi,
      email,
      idTipologia,
      idCliente,
      dateFrom,
      dateTo
    );
    yield put(getQrCodeRequestReportSucceeded(response));
  } catch (e) {
    yield put(getQrCodeRequestReportFailed());
  }
}

export default function* qrCodeRequestReportSaga(): SagaIterator {
  yield takeLatest(GET_QRCODE_REQUEST_REPORT, getQrCodeRequestReport);
}
