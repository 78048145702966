export const errorCode: { [index: string]: string } = {
  0: "Ops qualcosa è andato storto",
  5001: "Utente non trovato",
  5006: "Codice richiesta non valido",
  5007: "Utente già confermato",
  5008: "Richiesta Scaduta",
  5009: "Attivazione utente già effettuata",
} as const;

export const errorMessage = (code: string | null): string => {
  if (!code) {
    return errorCode[0];
  }
  return errorCode[code] ?? errorCode[0];
};
