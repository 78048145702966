import { Bar } from "react-chartjs-2";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  colors,
  Divider,
  useTheme,
} from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { GetWidgetsDataResponse } from "../../pages/Dashboard/statistics/interfaces";
import { getWidgetsData } from "../../pages/Dashboard/statistics/actions/widgetsActions";
import { WIDGETS_INTERVAL } from "../../pages/Dashboard/Dashboard";
import { useAppDispatch } from "../../hooks";

interface Props {
  widgetsData: GetWidgetsDataResponse;
}

const TemporalGraph: FC<Props> = ({ widgetsData }, ...props): JSX.Element => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [dataPeriod, setDataPeriod] = useState<WIDGETS_INTERVAL>(
    WIDGETS_INTERVAL.WEEKLY
  );

  useEffect(() => {
    dispatch(getWidgetsData(dataPeriod));
  }, [dataPeriod]);

  const getDatasetLabel = (interval: WIDGETS_INTERVAL) => {
    switch (interval) {
      case WIDGETS_INTERVAL.WEEKLY:
        return {
          firstInterval: "Settimana Corrente",
          secondInterval: "Settimana Scorsa",
          generalLabel: "Ultima Settimana",
        };

      case WIDGETS_INTERVAL.MONTHLY:
        return {
          firstInterval: "Mese Corrente",
          secondInterval: "Mese Scorso",
          generalLabel: "Ultimo Mese",
        };

      case WIDGETS_INTERVAL.YEARLY:
        return {
          firstInterval: "Anno Corrente",
          secondInterval: "Anno Scorso",
          generalLabel: "Ultimo Anno",
        };

      default:
        return {
          firstInterval: "Settimana Corrente",
          secondInterval: "Settimana Scorsa",
          generalLabel: "Ultima Settimana",
        };
    }
  };

  const data = {
    datasets: [
      {
        backgroundColor: colors.indigo[500],
        data: [widgetsData.totalMarkerRequestIntervallo1],
        label: getDatasetLabel(dataPeriod).firstInterval,
        barThickness: 72,
        maxBarThickness: 70,
        categoryPercentage: 0.5,
        barPercentage: 0.5,
      },
      {
        backgroundColor: colors.grey[200],
        data: [widgetsData.totalMarkerRequestIntervallo2],
        label: getDatasetLabel(dataPeriod).secondInterval,
        barThickness: 72,
        maxBarThickness: 70,
        categoryPercentage: 0.5,
        barPercentage: 0.5,
      },
    ],
    labels: [getDatasetLabel(dataPeriod).generalLabel],
  };

  const options = {
    animation: false,
    cornerRadius: 20,
    layout: { padding: 0 },
    legend: { display: false },
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
          },
          gridLines: {
            display: false,
            drawBorder: false,
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            fontColor: theme.palette.text.secondary,
            beginAtZero: true,
            min: 0,
          },
          gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: theme.palette.divider,
            drawBorder: false,
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
            zeroLineColor: theme.palette.divider,
          },
        },
      ],
    },
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  return (
    <Card {...props}>
      <CardHeader
        action={
          <ButtonGroup
            variant="text"
            aria-label="outlined primary button group"
            size="small"
          >
            <Button onClick={() => setDataPeriod(WIDGETS_INTERVAL.WEEKLY)}>
              Settimana
            </Button>
            <Button onClick={() => setDataPeriod(WIDGETS_INTERVAL.MONTHLY)}>
              Mese
            </Button>
            <Button onClick={() => setDataPeriod(WIDGETS_INTERVAL.YEARLY)}>
              Anno
            </Button>
          </ButtonGroup>
        }
        title="Marker Scansionati"
      />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 400,
            position: "relative",
          }}
        >
          <Bar data={data} options={options} />
        </Box>
      </CardContent>
      <Divider />
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          p: 2,
        }}
      />
    </Card>
  );
};

export default TemporalGraph;
