import {
  Image as ImgIcon,
  Link2 as UrlIcon,
  Youtube as YoutubeIcon,
  Video as VimeoIcon,
  Slash as InactiveIcon,
  CheckCircle as ActiveIcon,
} from "react-feather";

export const renderIcon = (codice: string): JSX.Element => {
  if (codice === "YOUTUBE") return <YoutubeIcon />;
  if (codice === "VIMEO") return <VimeoIcon />;
  if (codice === "URL") return <UrlIcon />;
  if (codice === "IMG") return <ImgIcon />;
  return <></>;
};

export const renderIsActive = (isActive: boolean): JSX.Element => {
  if (isActive) return <ActiveIcon />;
  return <InactiveIcon />;
};
