import {
  AUTHORIZED_HEADERS,
  ENDPOINT,
  ENDPOINTS_BASE,
} from "../../../constants";

export async function updateClienteApi(
  id: string,
  nome: string,
  email: string,
  sede: string,
  descrizione: string,
  isActive: boolean,
  isLogoColored: boolean
): Promise<string> {
  return fetch(`${ENDPOINTS_BASE}${ENDPOINT.UPDATE_CLIENTE}`, {
    method: "PUT",
    headers: AUTHORIZED_HEADERS,
    body: JSON.stringify({
      id,
      nome,
      email,
      sede,
      descrizione,
      isActive,
      isLogoColored,
    }),
  }).then(async (response) => {
    if (response.ok) {
      return response.text();
    }
    throw new Error(await response.text());
  });
}
