import { call, put, takeLatest } from "redux-saga/effects";

import { updateUserApi } from "./api";
import {
  UPDATE_USER,
  UpdateUser,
  updateUserFailed,
  updateUserSucceeded,
} from "./actions";
import { ResponsePayload, UpdateUserResponse } from "./interfaces";
import { SagaIterator } from "@redux-saga/types";

function* updateUser(action: UpdateUser): SagaIterator {
  try {
    const { id, email, password, ruoloUtente, isActive } = action.payload;

    const response: UpdateUserResponse = yield call(
      updateUserApi,
      id,
      email,
      password ? btoa(password) : null,
      ruoloUtente,
      isActive
    );
    const responsePayload: ResponsePayload = {
      message: response,
      isUpdated: true,
    };
    yield put(updateUserSucceeded(responsePayload));
  } catch (e: any) {
    console.log("updateUserSaga error: ", e);
    const responsePayload = {
      message: e.toString(),
      isUpdated: false,
    };
    yield put(updateUserFailed(responsePayload));
  }
}

export default function* updateUserSaga(): SagaIterator {
  yield takeLatest(UPDATE_USER, updateUser);
}
