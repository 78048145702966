import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import { useNavigate } from "react-router-dom";
import React, { ChangeEvent, FC, SyntheticEvent } from "react";
import { TipoRisorsa } from "../../common/tipoRisorsa/interfaces";
import { Autocomplete } from "@material-ui/core";
import { useAppSelector } from "../../hooks";
import { MarkerFilters } from "../../pages/MarkerList/MarkerList";
import { Cliente } from "../../pages/ClienteList/interfaces";
import { isUserAuthorized } from "../../utils";
import { AUTHORIZATION, ROUTE_PATH } from "../../constants";

interface Props {
  handleChangeFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeAutocompleteTipoRisorsaFilter: (
    event: SyntheticEvent,
    selectedTipoRisorsa: TipoRisorsa | null
  ) => void;
  handleChangeTextTipoRisorsaFilter: (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => void;
  handleChangeAutocompleteClienteFilter: (
    event: SyntheticEvent,
    selectedCliente: Cliente | null
  ) => void;
  handleChangeTextClienteFilter: (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => void;
  handleChangeCheckbox: () => void;
  markerFilters: MarkerFilters;
}

const MarkerListToolbar: FC<Props> = ({
  handleChangeFilter,
  handleChangeAutocompleteTipoRisorsaFilter,
  handleChangeTextTipoRisorsaFilter,
  markerFilters,
  handleChangeAutocompleteClienteFilter,
  handleChangeTextClienteFilter,
  handleChangeCheckbox,
  ...props
}): JSX.Element => {
  const navigate = useNavigate();

  const completeTipoRisorsaList: TipoRisorsa[] =
    useAppSelector((state) => state.tipoRisorsa.data?.tipiRisorsa) ?? [];

  const completeClienteList: Cliente[] =
    useAppSelector((state) => state.clienteNoPag.data?.clienti) ?? [];

  return (
    <Box {...props}>
      {isUserAuthorized(AUTHORIZATION.MK_E) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            gap: '8px'
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => navigate(`/${ROUTE_PATH.APP}/${ROUTE_PATH.MARKER_MASSIVO}`)}
          >
            Inserisci Marker Massivo
          </Button>
          
          <Button
            color="primary"
            variant="contained"
            onClick={() => navigate(`/${ROUTE_PATH.APP}/${ROUTE_PATH.MARKER}`)}
          >
            Inserisci Marker
          </Button>
        </Box>
      )}

      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid sx={{ mt: 1 }} container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                <Autocomplete
                  id="cliente-marker-search"
                  options={completeClienteList}
                  getOptionLabel={(cliente: Cliente) => cliente.nome}
                  onChange={handleChangeAutocompleteClienteFilter}
                  inputValue={markerFilters.cliente.nome}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Cliente"
                      name="cliente"
                      variant="outlined"
                      onChange={({ target }) =>
                        handleChangeTextClienteFilter(target)
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={3}>
                <TextField
                  name="ambito"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Ambito"
                  variant="outlined"
                  onChange={handleChangeFilter}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3} lg={2}>
                <TextField
                  name="codiceMarker"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Codice"
                  variant="outlined"
                  onChange={handleChangeFilter}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={2}>
                <Autocomplete
                  id="tipo-risorsa-marker-search"
                  options={completeTipoRisorsaList}
                  getOptionLabel={(tipoRisorsa: TipoRisorsa) =>
                    tipoRisorsa.codice
                  }
                  onChange={handleChangeAutocompleteTipoRisorsaFilter}
                  inputValue={markerFilters.tipologia.codice}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Tipo risorsa"
                      name="tipoRisorsa"
                      variant="outlined"
                      onChange={({ target }) =>
                        handleChangeTextTipoRisorsaFilter(target)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      onClick={handleChangeCheckbox}
                      checked={markerFilters.isActive ?? false}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="Solo attivi"
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default MarkerListToolbar;
