import {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import isEqual from "lodash/isEqual";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { createUser } from "../../pages/UserCRUD/CreateUser/actions";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { User } from "../../pages/UserList/interfaces";
import { updateUser } from "../../pages/UserCRUD/UpdateUser/actions";
import {
  GetAllRuoloUtenteResponse,
  RuoloUtente,
} from "../../common/ruoloUtente/interfaces";
import { getAllRuoloUtenteDashboard } from "../../common/ruoloUtente/actions";
import { ROUTE_PATH, USER_ROLE } from "../../constants";

interface LocationState {
  userId: number;
}

const FACEBOOK = "FACEBOOK";
const APPLE = "APPLE";
const GOOGLE = "GOOGLE";

const CICLOPE_LOGO_PATH = "/static/images/logo/Icona_Ciclope_Color_100x100.jpg";
const FACEBOOK_LOGO_PATH = "/static/images/logo/Facebook_logo_100x100.png";
const GOOGLE_LOGO_PATH = "/static/images/logo/Google_logo_100x100.png";
const APPLE_LOGO_PATH = "/static/images/logo/Apple_logo_100x100.png";

const UserDetails: FC = ({ ...props }): JSX.Element => {
  const [values, setValues] = useState({
    email: "",
    password: "",
    repeatPassword: "",
    ruoloUtente: { id: 0, ruolo: "" },
    isActive: true,
  });

  const [valuesOriginal, setValuesOriginal] = useState({
    email: "",
    password: "",
    repeatPassword: "",
    ruoloUtente: { id: 0, ruolo: "" },
    isActive: true,
  });

  const [imagePath, setImagePath] = useState(CICLOPE_LOGO_PATH);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const location: Location<LocationState> = useLocation();

  const isUserCreated = useAppSelector(
    (state) => state.createUser.data?.isCreated
  );
  const isUserUpdated = useAppSelector(
    (state) => state.updateUser.data?.isUpdated
  );
  const userList = useAppSelector((state) => state.users.data?.utenti) ?? [];
  const ruoloUtenteList: GetAllRuoloUtenteResponse =
    useAppSelector((state) => state.ruoloUtente.data) ?? [];

  useEffect(() => {
    dispatch(getAllRuoloUtenteDashboard());
  }, []);

  useEffect(() => {
    if (location.state?.userId) {
      const userToUpdateFromState: User | undefined = userList.find(
        (user) => user.id === Number(location.state?.userId)
      );

      if (userToUpdateFromState) {
        const { email, ruoloUtente, isActive } = userToUpdateFromState;
        setValues({
          email,
          ruoloUtente,
          password: "",
          repeatPassword: "",
          isActive,
        });
        setValuesOriginal({
          email,
          ruoloUtente,
          password: "",
          repeatPassword: "",
          isActive,
        });
        if (userToUpdateFromState.login) {
          if (userToUpdateFromState.login === FACEBOOK)
            setImagePath(FACEBOOK_LOGO_PATH);
          else if (userToUpdateFromState.login === GOOGLE)
            setImagePath(GOOGLE_LOGO_PATH);
          else if (userToUpdateFromState.login === APPLE)
            setImagePath(APPLE_LOGO_PATH);
        }
      }
    } else {
      const admin = ruoloUtenteList.find((x) => x.ruolo === USER_ROLE.ADMIN);
      if (admin !== undefined) {
        setValues({
          ...values,
          ruoloUtente: admin,
        });
      }
    }
  }, [location.state?.userId]);

  useEffect(() => {
    if (isUserCreated || isUserUpdated) {
      navigate(`/${ROUTE_PATH.APP}/${ROUTE_PATH.USERS}`);
    }
  }, [isUserCreated, isUserUpdated]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeAutocompleteRuoloUtente = (
    event: SyntheticEvent,
    value: RuoloUtente
  ) => {
    setValues({ ...values, ruoloUtente: value });
  };

  const handleChangeTextRuoloUtente = (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => {
    const newRuoloUtente = { ...values.ruoloUtente };
    newRuoloUtente.ruolo = target.value;
    setValues({ ...values, ruoloUtente: newRuoloUtente });
  };

  const handleUserAction = () => {
    const { email, password, ruoloUtente, isActive } = values;
    const { id: ruoloUtenteId } = ruoloUtente;
    if (location.state?.userId) {
      dispatch(
        updateUser(
          Number(location.state.userId),
          email,
          password,
          ruoloUtenteId,
          isActive
        )
      );
    } else {
      dispatch(createUser(email, password, ruoloUtenteId, isActive));
    }
  };

  const toggleIsActive = (): void => {
    setValues((prevState) => {
      return { ...prevState, isActive: !prevState.isActive };
    });
  };

  const isBtnDisabled = useMemo((): boolean => {
    if (location.state?.userId) {
      return (
        isEqual(values, valuesOriginal) ||
        ((!values.password || !values.repeatPassword) &&
          values.password !== values.repeatPassword)
      );
    }
    return (
      !values.email ||
      !values.ruoloUtente.ruolo ||
      !values.password ||
      !values.repeatPassword ||
      values.password !== values.repeatPassword
    );
  }, [
    values.email,
    values.password,
    values.repeatPassword,
    values.ruoloUtente.ruolo,
    values.isActive,
  ]);

  return (
    <>
      <form autoComplete="off" noValidate {...props}>
        <Card>
          <CardHeader
            name="title"
            subheader={
              location.state?.userId
                ? "Aggiorna i campi necessari"
                : "Completa i campi necessari"
            }
            title={location.state?.userId ? "Modifica Utente" : "Crea Utente"}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Indirizzo Email"
                  name="email"
                  required
                  onChange={location.state?.userId ? () => null : handleChange}
                  value={values.email}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  id="ruoloUtente"
                  options={ruoloUtenteList}
                  getOptionLabel={(ruoloUtente: RuoloUtente) =>
                    ruoloUtente.ruolo
                  }
                  onChange={handleChangeAutocompleteRuoloUtente}
                  inputValue={values.ruoloUtente?.ruolo}
                  disableClearable
                  disabled={
                    values.ruoloUtente?.ruolo === USER_ROLE.MOBILE ||
                    ruoloUtenteList.length === 1
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Ruolo Utente"
                      name="ruoloUtente"
                      onChange={({ target }) =>
                        handleChangeTextRuoloUtente(target)
                      }
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Password"
                  name="password"
                  onChange={handleChange}
                  required
                  value={values.password}
                  variant="outlined"
                  type="password"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Ripeti Password"
                  name="repeatPassword"
                  onChange={handleChange}
                  required
                  value={values.repeatPassword}
                  variant="outlined"
                  error={values.password !== values.repeatPassword}
                  helperText={
                    values.password !== values.repeatPassword &&
                    "Le password devono essere identiche"
                  }
                  type="password"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <fieldset style={customFieldset}>
                  <legend style={customLegend}>
                    <Typography color="textSecondary" fontSize={12.3}>
                      Tipo Utente
                    </Typography>
                  </legend>
                  <img
                    src={imagePath}
                    alt="Tipo Utente"
                    loading="lazy"
                    style={{
                      width: 36,
                      height: 36,
                      position: "relative",
                    }}
                  />
                </fieldset>
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      onClick={toggleIsActive}
                      checked={values.isActive}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="attivo"
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              p: 2,
            }}
          >
            <Button
              color="secondary"
              variant="contained"
              onClick={() => navigate(`/${ROUTE_PATH.APP}/${ROUTE_PATH.USERS}`)}
              sx={{ marginRight: "1%" }}
            >
              Annulla
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleUserAction}
              disabled={isBtnDisabled}
            >
              {location.state?.userId ? "Aggiorna" : "Crea"}
            </Button>
          </Box>
        </Card>
      </form>
    </>
  );
};

/* START styles */
const customFieldset: React.CSSProperties = {
  border: "1px solid lightgrey",
  borderRadius: 5,
  height: "65px",
  textAlign: "center",
};
const customLegend: React.CSSProperties = {
  marginLeft: "10px",
  paddingLeft: "5px",
  paddingRight: "5px",
  textAlign: "left",
};
/* END styles */

export default UserDetails;
