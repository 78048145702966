import { Doughnut } from "react-chartjs-2";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  colors,
  Divider,
  Typography,
  useTheme,
} from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { GetWidgetsDataResponse } from "../../pages/Dashboard/statistics/interfaces";
import { SxProps } from "@material-ui/system";
import { Theme } from "@material-ui/core/styles/createMuiTheme";
import { renderIcon } from "../../utils/returntype";

interface Props {
  sx: SxProps<Theme>;
  widgetsData: GetWidgetsDataResponse;
}

interface TipoRisorsaForGraph {
  title: string;
  value: number;
  icon: JSX.Element;
  color: string;
}
interface DataForGraph {
  datasets: [
    {
      data: number[];
      backgroundColor: string[];
      borderWidth: number;
      borderColor: string;
      hoverBorderColor: string;
    }
  ];
  labels: string[];
}

const TipiRisorseCakeGraph: FC<Props> = ({
  widgetsData,
  ...props
}): JSX.Element => {
  const theme = useTheme();
  const [tipoRisorsaArrayForGraph, setTipoRisorsaArrayForGraph] =
    useState<TipoRisorsaForGraph[]>();
  const [dataForGraph, setDataForGraph] = useState<DataForGraph>();

  /* const data = {
    datasets: [
      {
        data: [570, 899, 211],
        backgroundColor: [
          colors.indigo[500],
          colors.red[600],
          colors.green[300],
        ],
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white,
      },
    ],
    labels: ["URL", "YOUTUBE", "VIMEO"],
  };*/

  const dataCreatorForGraph = () => {
    const availableColors = [
      colors.yellow[300],
      colors.green[300],
      colors.indigo[500],
      colors.red[600],
    ];

    const dataObj: DataForGraph = {
      datasets: [
        {
          data: [],
          backgroundColor: [],
          borderWidth: 8,
          borderColor: colors.common.white,
          hoverBorderColor: colors.common.white,
        },
      ],
      labels: [],
    };

    const tipoRisorsaArray: TipoRisorsaForGraph[] =
      widgetsData.tipoRisorsaIntervallo1.map((tipoRisorsaObj, index) => {
        return {
          title: tipoRisorsaObj.descrizione,
          value: tipoRisorsaObj.totaleScansioni,
          icon: renderIcon(tipoRisorsaObj.codice),
          color: availableColors[index] ?? colors.indigo[500],
        };
      });

    for (const tipoRisorsa of tipoRisorsaArray) {
      dataObj.datasets[0].data.push(tipoRisorsa.value);
      dataObj.datasets[0].backgroundColor.push(tipoRisorsa.color);
      dataObj.labels.push(tipoRisorsa.title);
    }

    setTipoRisorsaArrayForGraph(tipoRisorsaArray);
    setDataForGraph(dataObj);
  };

  useEffect(() => {
    dataCreatorForGraph();
  }, [widgetsData.tipoRisorsaIntervallo1]);

  const options = {
    animation: false,
    cutoutPercentage: 80,
    layout: { padding: 0 },
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    responsive: true,
    tooltips: {
      backgroundColor: theme.palette.background.paper,
      bodyFontColor: theme.palette.text.secondary,
      borderColor: theme.palette.divider,
      borderWidth: 1,
      enabled: true,
      footerFontColor: theme.palette.text.secondary,
      intersect: false,
      mode: "index",
      titleFontColor: theme.palette.text.primary,
    },
  };

  /* const tipoRisorsa = [
    {
      title: "URL",
      value: 570,
      icon: renderIcon("URL"),
      color: colors.indigo[500],
    },
    {
      title: "YOU TUBE",
      value: 899,
      icon: renderIcon("YOUTUBE"),
      color: colors.red[600],
    },
    {
      title: "VIMEO",
      value: 211,
      icon: renderIcon("VIMEO"),
      color: colors.green[300],
    },
  ];*/

  return (
    <Card {...props}>
      <CardHeader title="Tipo Risorsa" />
      <Divider />
      <CardContent>
        <Box
          sx={{
            height: 300,
            position: "relative",
          }}
        >
          <Doughnut data={dataForGraph} options={options} />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 2,
          }}
        >
          {tipoRisorsaArrayForGraph?.map(
            ({ color, icon: Icon, title, value }) => (
              <Box
                key={title}
                sx={{
                  p: 1,
                  textAlign: "center",
                }}
              >
                {Icon}
                <Typography color="textPrimary" variant="body1">
                  {title}
                </Typography>
                <Typography style={{ color }} variant="h2">
                  {value}
                </Typography>
              </Box>
            )
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default TipiRisorseCakeGraph;
