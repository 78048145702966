// =============== //
// Actions //
import { LoginResponse } from "./interfaces";

export const LOGIN_USER_SUCCEEDED = "LOGIN_USER_SUCCEEDED";
export const LOGIN_USER_FAILED = "LOGIN_USER_FAILED";
export const LOGIN_USER = "LOGIN_USER";

// =============== //
// Action types //
export type LoginUser = {
  type: typeof LOGIN_USER;
  payload: {
    email: string;
    password: string;
  };
};

type LoginUserSucceeded = {
  type: typeof LOGIN_USER_SUCCEEDED;
  payload: LoginResponse;
};

type LoginUserFailed = {
  type: typeof LOGIN_USER_FAILED;
  payload: null;
};

// =============== //
// Creators //
export const loginUser = (email: string, password: string): LoginUser => ({
  type: LOGIN_USER,
  payload: { email, password },
});

export const loginUserSucceeded = (
  response: LoginResponse
): LoginUserSucceeded => ({
  type: LOGIN_USER_SUCCEEDED,
  payload: response,
});

export const loginUserFailed = (): LoginUserFailed => ({
  type: LOGIN_USER_FAILED,
  payload: null,
});

// =============== //
// Exports //
export type Action = LoginUser | LoginUserSucceeded | LoginUserFailed;
