import jwt_decode from "jwt-decode";
import moment from "moment";
import { TipoRisorsa } from "../common/tipoRisorsa/interfaces";
import { MarkerBody } from "../components/markerDetails/MarkerDetails";
import { AUTHORIZATION } from "../constants";

export function isDevelopmentMode(): boolean {
  return process.env.NODE_ENV === "development";
}

export const storeToken = (token: string): void => {
  localStorage.setItem("token", token);
  const decodedToken: DecodedToken = jwt_decode(token);
  localStorage.setItem("decodedToken", JSON.stringify(decodedToken));
};

interface DecodedToken {
  abilitazioni: string[];
  exp: number;
  iat: number;
  sub: string;
}

export const getAuthorizationList = (): string[] => {
  const decodedTokenString = localStorage.getItem("decodedToken");
  if (decodedTokenString) {
    const decodedToken: DecodedToken = JSON.parse(decodedTokenString);
    return decodedToken.abilitazioni;
  }
  return [];
};

export const getCurrentUserEmail = (): string => {
  const decodedTokenString = localStorage.getItem("decodedToken");
  if (decodedTokenString) {
    const decodedToken: DecodedToken = JSON.parse(decodedTokenString);
    return decodedToken.sub;
  }
  return "";
};

export const getToken = (): string | null => localStorage.getItem("token");

export const cleanToken = (): void => {
  localStorage.removeItem("token");
  localStorage.removeItem("decodedToken");
};

export function isAuthenticated(): boolean {
  const token = localStorage.getItem("token");
  if (!token) {
    return false;
  }

  const decodedToken: string | null = localStorage.getItem("decodedToken");
  if (decodedToken) {
    const decodedTokenObj: DecodedToken = JSON.parse(decodedToken);
    const expirationDate = decodedTokenObj.exp;
    return expirationDate
      ? new Date() < new Date(expirationDate * 1000)
      : false;
  }
  return false;
}

export function dateMakerForFilters(date: Date | null): string {
  if (date !== null) {
    return moment(date).format("YYYY-MM-DD");
  }
  return "";
}

export function base64ToArrayBuffer(base64: string): Uint8Array {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

export function extractByteString(contentTypeSrcString: string): string {
  return contentTypeSrcString.split(",")[1] ?? contentTypeSrcString;
}

export function saveByteArray(reportName: string, byte: Uint8Array): void {
  const blob = new Blob([byte], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
  });
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = reportName;
  link.click();
}
export function exportExcel(data: string, documentTitle: string): void {
  const byteToArrayData: Uint8Array = base64ToArrayBuffer(data);
  saveByteArray(documentTitle, byteToArrayData);
}

export const handleFindTypeFromAction = (
  azione: string,
  values: MarkerBody,
  setValues: (
    value: ((prevState: MarkerBody) => MarkerBody) | MarkerBody
  ) => void
): void => {
  const newTipoRisorsaUrl: TipoRisorsa = { id: 1, codice: "URL" };
  const newTipoRisorsaYoutube: TipoRisorsa = { id: 2, codice: "YOUTUBE" };
  const newTipoRisorsaVimeo: TipoRisorsa = { id: 3, codice: "VIMEO" };

  const youtube = new RegExp(
    /(?:https?:\/\/|www\.|m\.|^)youtu(?:be\.com\/watch\?(?:.*?&(?:amp;)?)?v=|\.be\/)([\w‌-]+)(?:&(?:amp;)?[\w?=]*)?/
  );
  const vimeo = new RegExp(
    /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/
  );
  const url1 = new RegExp(
    /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?/gi
  );
  /*
  const url2 = new RegExp(
    "[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}b([-a-zA-Z0-9@:%_+.~#?&//=]*)"
  );
*/
  if (youtube.test(azione)) {
    setValues({ ...values, tipoRisorsa: newTipoRisorsaYoutube, azione });
  } else if (vimeo.test(azione)) {
    setValues({ ...values, tipoRisorsa: newTipoRisorsaVimeo, azione });
  } else if (url1.test(azione)) {
    setValues({ ...values, tipoRisorsa: newTipoRisorsaUrl, azione });
  } else {
    setValues({ ...values, tipoRisorsa: { id: 0, codice: "" }, azione });
  }
};

export const isUserAuthorized = (
  authorizationCode: AUTHORIZATION | null | undefined
): boolean => {
  if (authorizationCode) {
    const authorizationList = getAuthorizationList();
    return authorizationList.includes(authorizationCode);
  }
  return false;
};

export const uniqueAndSortedArrayOfNumber = (
  listOfNumber: Array<number>
): Array<string> => {
  try {
    const uniqueNumberList: number[] = Array.from(new Set(listOfNumber));
    uniqueNumberList.sort((a, b) => (a > b ? 1 : -1));
    return uniqueNumberList.map((number) => number.toString());
  } catch (e) {
    throw new Error(e);
  }
};
