// =============== //
// Actions //
import { ResponsePayload } from "./interfaces";

export const DELETE_USER_SUCCEEDED = "DELETE_USER_SUCCEEDED";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";
export const DELETE_USER_RESET = "DELETE_USER_RESET";
export const DELETE_USER = "DELETE_USER";

// =============== //
// Action types //
export type DeleteUser = {
  type: typeof DELETE_USER;
  payload: {
    utenteId: string;
  };
};

type DeleteUserSucceeded = {
  type: typeof DELETE_USER_SUCCEEDED;
  payload: ResponsePayload;
};

type DeleteUserFailed = {
  type: typeof DELETE_USER_FAILED;
  payload: ResponsePayload;
};

type DeleteUserReset = {
  type: typeof DELETE_USER_RESET;
  payload: null;
};

// =============== //
// Creators //
export const deleteUser = (utenteId: string): DeleteUser => ({
  type: DELETE_USER,
  payload: { utenteId },
});

export const deleteUserSucceeded = (
  response: ResponsePayload
): DeleteUserSucceeded => ({
  type: DELETE_USER_SUCCEEDED,
  payload: response,
});

export const deleteUserFailed = (
  response: ResponsePayload
): DeleteUserFailed => ({
  type: DELETE_USER_FAILED,
  payload: response,
});

export const deleteUserReset = (): DeleteUserReset => ({
  type: DELETE_USER_RESET,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | DeleteUser
  | DeleteUserSucceeded
  | DeleteUserFailed
  | DeleteUserReset;
