import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import configureStore from "./store/configure-store";
import App from "./App";

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const MOUNT_NODE = document.getElementById("root") as HTMLElement;
const store = configureStore();

const renderApp = () =>
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
    MOUNT_NODE
  );

// Render the application
renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Hot reloading
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MODULE = module as any;

if (process.env.NODE_ENV !== "production" && MODULE.hot) {
  MODULE.hot.accept("./App", renderApp);
}

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
