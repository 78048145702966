import React, { useEffect, FC, useState } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MuiAlert from "@material-ui/core/Alert";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { deleteClienteReset } from "../../pages/ClienteCRUD/DeleteCliente/actions";
import { createClienteReset } from "../../pages/ClienteCRUD/CreateCliente/actions";
import { deleteMarkerReset } from "../../pages/MarkerCRUD/DeleteMarker/actions";
import { createMarkerReset } from "../../pages/MarkerCRUD/CreateMarker/actions";
import { deleteUserReset } from "../../pages/UserCRUD/DeleteUser/actions";
import { createUserReset } from "../../pages/UserCRUD/CreateUser/actions";
import { updateUserReset } from "../../pages/UserCRUD/UpdateUser/actions";
import { updateClienteReset } from "../../pages/ClienteCRUD/UpdateCliente/actions";
import { updateMarkerReset } from "../../pages/MarkerCRUD/UpdateMarker/actions";

const CustomizedSnackbars: FC = (): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const dispatch = useAppDispatch();

  //========UTENTE========//
  const isDeletedUser =
    useAppSelector((state) => state.deleteUser.data?.isDeleted) ?? null;

  const isCreatedUser =
    useAppSelector((state) => state.createUser.data?.isCreated) ?? null;

  const isUpdatedUser =
    useAppSelector((state) => state.updateUser.data?.isUpdated) ?? null;

  const getDeleteUserMessage =
    useAppSelector((state) => state.deleteUser.data?.message) ?? "";

  const getCreateUserMessage =
    useAppSelector((state) => state.createUser.data?.message) ?? "";

  const getUpdateUserMessage =
    useAppSelector((state) => state.updateUser.data?.message) ?? "";

  useEffect(() => {
    if (isDeletedUser !== null && isDeletedUser) {
      setMessage(getDeleteUserMessage);
      setOpen(true);
    }
    return () => {
      dispatch(deleteUserReset());
    };
  }, [isDeletedUser]);

  useEffect(() => {
    if (isCreatedUser !== null && isCreatedUser) {
      setMessage(getCreateUserMessage);
      setOpen(true);
    }
    return () => {
      dispatch(createUserReset());
    };
  }, [isCreatedUser]);

  useEffect(() => {
    if (isUpdatedUser !== null && isUpdatedUser) {
      setMessage(getUpdateUserMessage);
      setOpen(true);
    }
    return () => {
      dispatch(updateUserReset());
    };
  }, [isUpdatedUser]);

  //========CLIENTE========//
  const isDeletedCliente =
    useAppSelector((state) => state.deleteCliente.data?.isDeleted) ?? null;

  const isCreatedCliente =
    useAppSelector((state) => state.createCliente.data?.isCreated) ?? null;

  const isUpdatedCliente =
    useAppSelector((state) => state.updateCliente.data?.isUpdated) ?? null;

  const getDeleteClienteMessage =
    useAppSelector((state) => state.deleteCliente.data?.message) ?? "";

  const getCreateClienteMessage =
    useAppSelector((state) => state.createCliente.data?.message) ?? "";

  const getUpdateClienteMessage =
    useAppSelector((state) => state.updateCliente.data?.message) ?? "";

  useEffect(() => {
    if (isDeletedCliente !== null && isDeletedCliente) {
      setMessage(getDeleteClienteMessage);
      setOpen(true);
    }
    return () => {
      dispatch(deleteClienteReset());
    };
  }, [isDeletedCliente]);

  useEffect(() => {
    if (isCreatedCliente !== null && isCreatedCliente) {
      setMessage(getCreateClienteMessage);
      setOpen(true);
    }
    return () => {
      dispatch(createClienteReset());
    };
  }, [isCreatedCliente]);

  useEffect(() => {
    if (isUpdatedCliente !== null && isUpdatedCliente) {
      setMessage(getUpdateClienteMessage);
      setOpen(true);
    }
    return () => {
      dispatch(updateClienteReset());
    };
  }, [isUpdatedCliente]);

  //========MARKER========//
  const isDeletedMarker =
    useAppSelector((state) => state.deleteMarker.data?.isDeleted) ?? null;

  const isCreatedMarker =
    useAppSelector((state) => state.createMarker.data?.isCreated) ?? null;

  const isUpdatedMarker =
    useAppSelector((state) => state.updateMarker.data?.isUpdated) ?? null;

  const getDeleteMarkerMessage =
    useAppSelector((state) => state.deleteMarker.data?.message) ?? "";

  const getCreateMarkerMessage =
    useAppSelector((state) => state.createMarker.data?.message) ?? "";

  const getUpdateMarkerMessage =
    useAppSelector((state) => state.updateMarker.data?.message) ?? "";

  useEffect(() => {
    if (isDeletedMarker !== null && isDeletedMarker) {
      setMessage(getDeleteMarkerMessage);
      setOpen(true);
    }
    return () => {
      dispatch(deleteMarkerReset());
    };
  }, [isDeletedMarker]);

  useEffect(() => {
    if (isCreatedMarker !== null && isCreatedMarker) {
      setMessage(getCreateMarkerMessage);
      setOpen(true);
    }
    return () => {
      dispatch(createMarkerReset());
    };
  }, [isCreatedMarker]);

  useEffect(() => {
    if (isUpdatedMarker !== null && isUpdatedMarker) {
      setMessage(getUpdateMarkerMessage);
      setOpen(true);
    }
    return () => {
      dispatch(updateMarkerReset());
    };
  }, [isUpdatedMarker]);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      autoHideDuration={4000}
      onClose={handleClose}
      message="Note archived"
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity="success"
        style={{ backgroundColor: "teal", color: "white" }}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};
export default CustomizedSnackbars;
