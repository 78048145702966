import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_ALL_TIPO_RISORSA,
  GetAllTipoRisorsa,
  getAllTipoRisorsaFailed,
  getAllTipoRisorsaSucceeded,
} from "./actions";
import { FindAllTipoRisorsaResponse } from "./interfaces";
import { fetchAllTipoRisorsa } from "./api";
import { SagaIterator } from "@redux-saga/types";

function* getAllTipoRisorsa(action: GetAllTipoRisorsa): SagaIterator {
  try {
    const { offset, limit } = action.payload;
    const firstResponse: FindAllTipoRisorsaResponse = yield call(
      fetchAllTipoRisorsa,
      offset,
      limit
    );
    const { totalRecord } = firstResponse;
    const lastResponse: FindAllTipoRisorsaResponse = yield call(
      fetchAllTipoRisorsa,
      0,
      totalRecord
    );
    yield put(getAllTipoRisorsaSucceeded(lastResponse));
  } catch (e) {
    yield put(getAllTipoRisorsaFailed());
  }
}
export default function* getAllTipoRisorsaSaga(): SagaIterator {
  yield takeLatest(GET_ALL_TIPO_RISORSA, getAllTipoRisorsa);
}
