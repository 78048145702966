import {
  Action,
  GET_ALL_CLIENTE_NO_PAG_FAILED,
  GET_ALL_CLIENTE_NO_PAG_SUCCEEDED,
} from "./actions";
import { GetAllClienteResponse } from "../../pages/ClienteList/interfaces";

export interface ClienteNoPagState {
  readonly data: GetAllClienteResponse | null;
}

const initialClienteState: ClienteNoPagState = {
  data: null,
};

const ClienteNoPagReducer = (
  state = initialClienteState,
  action: Action
): ClienteNoPagState => {
  switch (action.type) {
    case GET_ALL_CLIENTE_NO_PAG_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
      };
    }
    case GET_ALL_CLIENTE_NO_PAG_FAILED: {
      return { ...state, data: null };
    }
    default:
      return state;
  }
};

export default ClienteNoPagReducer;
