import { call, put, takeLatest } from "redux-saga/effects";

import { getMarkerRequestReportApi } from "./api";
import {
  GET_MARKER_REQUEST_REPORT,
  GetMarkerRequestReport,
  getMarkerRequestReportFailed,
  getMarkerRequestReportSucceeded,
} from "./actions";
import { MarkerRequestReportResponse } from "./interfaces";
import { SagaIterator } from "@redux-saga/types";

function* getMarkerRequestReport(action: GetMarkerRequestReport): SagaIterator {
  try {
    const { marker, cliente, ambito, tipologia, dateFrom, dateTo } =
      action.payload;
    const response: MarkerRequestReportResponse = yield call(
      getMarkerRequestReportApi,
      marker,
      cliente,
      ambito,
      tipologia,
      dateFrom,
      dateTo
    );
    yield put(getMarkerRequestReportSucceeded(response));
  } catch (e) {
    yield put(getMarkerRequestReportFailed());
  }
}

export default function* markerRequestReportSaga(): SagaIterator {
  yield takeLatest(GET_MARKER_REQUEST_REPORT, getMarkerRequestReport);
}
