import { call, put, takeLatest } from "redux-saga/effects";

import { updateUserPassword } from "./api";
import {
  CHANGE_PASSWORD,
  changePasswordFailed,
  changePasswordSucceeded,
  ChangePassword,
} from "./actions";
import { SagaIterator } from "@redux-saga/types";
import { ChangePasswordPayload, ChangePasswordResponse } from "./interfaces";

function* changePsw(action: ChangePassword): SagaIterator {
  try {
    const { email, tinyId, password } = action.payload;

    const response: ChangePasswordResponse = yield call(
      updateUserPassword,
      email,
      password,
      tinyId
    );
    const responsePayload: ChangePasswordPayload = {
      message: response.message,
      isChanged: true,
      description: null,
      code: null,
    };
    yield put(changePasswordSucceeded(responsePayload));
  } catch (e: any) {
    const err = JSON.parse(e.message.toString());
    const responsePayload: ChangePasswordPayload = {
      code: err.code,
      description: err.description,
      isChanged: false,
      message: null,
    };
    console.log("changePasswordSaga error: ", e);
    yield put(changePasswordFailed(responsePayload));
  }
}

export default function* changePasswordSaga(): SagaIterator {
  yield takeLatest(CHANGE_PASSWORD, changePsw);
}
