import React, { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  Typography,
} from "@material-ui/core";
import {
  BarChart as BarChartIcon,
  BarChart2 as requests,
  Briefcase as CustomersIcon,
  Code as MarkerIcon,
  Users as UsersIcon,
} from "react-feather";
import NavItem from "./NavItem";
import { cleanToken, getCurrentUserEmail, isUserAuthorized } from "../utils";
import { AUTHORIZATION, ROUTE_PATH } from "../constants";
import InputIcon from "@material-ui/icons/Input";

const items = [
  {
    href: `/${ROUTE_PATH.APP}/${ROUTE_PATH.DASHBOARD}`,
    icon: BarChartIcon,
    title: "Dashboard",
    authorizationCode: AUTHORIZATION.ST_V,
  },
  {
    href: `/${ROUTE_PATH.APP}/${ROUTE_PATH.CLIENTI}`,
    icon: CustomersIcon,
    title: "Clienti",
    authorizationCode: AUTHORIZATION.CL_V,
  },
  {
    href: `/${ROUTE_PATH.APP}/${ROUTE_PATH.MARKER_LIST}`,
    icon: MarkerIcon,
    title: "Marker",
    authorizationCode: AUTHORIZATION.MK_V,
  },
  {
    href: `/${ROUTE_PATH.APP}/${ROUTE_PATH.USERS}`,
    icon: UsersIcon,
    title: "Utenti",
    authorizationCode: AUTHORIZATION.UT_V,
  },
  {
    href: `/${ROUTE_PATH.APP}/${ROUTE_PATH.MARKER_REQUEST_LIST}`,
    icon: requests,
    title: "Scansioni Marker",
    authorizationCode: AUTHORIZATION.RM_V,
  },
  {
    href: `/${ROUTE_PATH.APP}/${ROUTE_PATH.QRCODE_REQUEST_LIST}`,
    icon: requests,
    title: "Scansioni QrCode",
    authorizationCode: AUTHORIZATION.QR_V,
  },
];

interface Props {
  onMobileClose: () => void;
  openMobile: boolean;
}

const DashboardSidebar: FC<Props> = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const handleLogout = (): void => {
    cleanToken();
    navigate(`/${ROUTE_PATH.LOGIN}`, { replace: true });
  };

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          display: "flex",
          p: 2,
        }}
      >
        <Typography color="textPrimary" variant="h5">
          {getCurrentUserEmail()}
        </Typography>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={handleLogout} title="Logout">
            <InputIcon />
          </IconButton>
        </Hidden>
      </Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => {
            if (isUserAuthorized(item.authorizationCode)) {
              return (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              );
            }
          })}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256,
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: "calc(100% - 64px)",
            },
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default DashboardSidebar;
