import { FC, useState, useEffect } from "react";
import Dropzone, { FileRejection } from "react-dropzone";
import { Typography } from "@material-ui/core";
import { Trash2 as DelIcon } from "react-feather";
import AlertDialog from "../AlertDialog/AlertDialog";
import { extractByteString } from "../../utils";

const mainContainer: React.CSSProperties = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: "5px",
  height: "100%",
  width: "100%",
  //backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  position: "relative",
  cursor: "pointer",
};

const dragDropContainer: React.CSSProperties = {
  width: "100%",
  height: "100%",
};

const textContainer: React.CSSProperties = {
  background: "rgba(0, 0, 0, 0.25)",
  position: "absolute",
  bottom: "0",
  left: "0",
  width: "100%",
  padding: "10px",
  borderBottomLeftRadius: "5px",
  borderBottomRightRadius: "5px",
  textAlign: "center",
};

const text: React.CSSProperties = {
  textAlign: "center",
  color: "white",
  zIndex: 1,
  textShadow: "0 1px 0 black",
};

const image: React.CSSProperties = {
  height: "100%",
  width: "100%",
  objectFit: "contain",
  opacity: 0.9,
};

const deleteBtnContainer: React.CSSProperties = {
  background: "rgba(255, 0, 0, 0.6)",
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  padding: "5px",
  borderTopLeftRadius: "5px",
  borderTopRightRadius: "5px",
  textAlign: "center",
  opacity: 1,
};

const deleteIcon: React.CSSProperties = {
  cursor: "pointer",
  width: "100%",
  color: "white",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

interface Props {
  backgroundImg?: string | null;
  maxFileSize?: number;
  maxSizeErrorMessage?: string;
  handleChangeBackground: (backgroundImgData: string) => void;
  handleDeleteBackground: () => void;
}

const ImageDragAndDrop: FC<Props> = ({
  backgroundImg,
  maxFileSize,
  maxSizeErrorMessage,
  handleChangeBackground,
  handleDeleteBackground,
}): JSX.Element => {
  const [imgSrc, setImgSrc] = useState<string>("");
  const [showDeleteBtn, setShowDeleteBtn] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState<boolean>(false);
  const acceptedFileTypes = "image/png, image/jpeg, image/jpg";
  const acceptedFileTypesArray = acceptedFileTypes.split(",").map((item) => {
    return item.trim();
  });

  useEffect(() => {
    setImgSrc(backgroundImg ?? "");
    setShowDeleteBtn(!!backgroundImg);
  }, [backgroundImg]);

  const myFileItemReader = new FileReader();
  const verifyFile = (files: any): boolean => {
    const currentFile = files[0];
    const currentFileType = currentFile.type;
    if (!acceptedFileTypesArray.includes(currentFileType)) {
      alert("this file is not allowed");
      return false;
    }
    return true;
  };

  //   const isMarkerCreated = useAppSelector((state) =>
  //     console.log(state.marker.data)
  //   );

  const handleOnDrop = (acceptedFiles: any): void => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const isVerified: boolean = verifyFile(acceptedFiles);
      if (isVerified) {
        const currentFile = acceptedFiles[0];
        myFileItemReader.addEventListener("load", () => {
          if (myFileItemReader.result) {
            setImgSrc(extractByteString(myFileItemReader.result.toString()));
            setShowDeleteBtn(true);
            handleChangeBackground(
              extractByteString(myFileItemReader.result.toString())
            );
          }
        });
        myFileItemReader.readAsDataURL(currentFile);
      }
    }
  };

  const handleDelete = (): void => {
    setImgSrc("");
    setIsModalOpen(false);
    setShowDeleteBtn(false);
    handleDeleteBackground();
  };

  const handleOnReject = (rejectionArr: FileRejection[]) => {
    if (maxFileSize && rejectionArr[0].file.size > maxFileSize) {
      setIsRejectModalOpen(true);
    }
  };

  return (
    <>
      <Dropzone
        onDrop={handleOnDrop}
        multiple={false}
        accept={acceptedFileTypes}
        maxSize={maxFileSize}
        onDropRejected={(rejectionArr) => handleOnReject(rejectionArr)}
      >
        {(dropzoneProps) => {
          return (
            <div style={mainContainer}>
              <div {...dropzoneProps.getRootProps()} style={dragDropContainer}>
                {imgSrc && (
                  <img
                    src={`data:image/png;base64,${imgSrc}`}
                    style={image}
                    loading="lazy"
                  />
                )}
                <input {...dropzoneProps.getInputProps()} placeholder="drop" />
                <div style={textContainer}>
                  <Typography color="textPrimary" variant="h5" style={text}>
                    Trascina o fai click per aggiungere un'immagine
                  </Typography>
                </div>
              </div>
              {showDeleteBtn && (
                <div style={deleteBtnContainer}>
                  <DelIcon
                    style={deleteIcon}
                    onClick={() => setIsModalOpen(true)}
                  />
                </div>
              )}
            </div>
          );
        }}
      </Dropzone>
      <AlertDialog
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        dialogTitle={"Elimina Immagine"}
        dialogContent={"Sei sicuro di eliminare questa immagine?"}
        buttonSuccessTitle={"Si"}
        buttonFailureTitle={"No"}
        buttonSuccessAction={handleDelete}
      />
      <AlertDialog
        isOpen={isRejectModalOpen}
        handleClose={() => setIsRejectModalOpen(false)}
        dialogTitle={"Immagine troppo grande"}
        dialogContent={
          maxSizeErrorMessage
            ? maxSizeErrorMessage
            : "L'immagine inserita è di dimensioni troppo grandi."
        }
        buttonSuccessTitle={"OK"}
        buttonSuccessAction={() => setIsRejectModalOpen(false)}
      />
    </>
  );
};
export default ImageDragAndDrop;
