import {
  AUTHORIZED_HEADERS,
  ENDPOINT,
  ENDPOINTS_BASE,
} from "../../../constants";

export async function nextMarkerCodeApi(): Promise<string> {
  return fetch(`${ENDPOINTS_BASE}${ENDPOINT.NEXT_MARKER_CODE}`, {
    method: "GET",
    headers: AUTHORIZED_HEADERS,
  }).then((response) => response.json());
}
