import {
  Action,
  GET_USER_REPORT,
  GET_USER_REPORT_FAILED,
  GET_USER_REPORT_RESET,
  GET_USER_REPORT_SUCCEEDED,
} from "./actions";
import { UserReportResponse } from "./interfaces";

export interface UserReportState {
  readonly data: UserReportResponse | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialUsersState: UserReportState = {
  data: null,
  isFetching: false,
  isFetched: false,
};

const UserReportReducer = (
  state = initialUsersState,
  action: Action
): UserReportState => {
  switch (action.type) {
    case GET_USER_REPORT: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case GET_USER_REPORT_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case GET_USER_REPORT_FAILED: {
      return { ...state, isFetching: false, isFetched: false };
    }
    case GET_USER_REPORT_RESET: {
      return initialUsersState;
    }
    default:
      return state;
  }
};

export default UserReportReducer;
