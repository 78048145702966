import {
  AUTHORIZED_HEADERS,
  ENDPOINT,
  ENDPOINTS_BASE,
} from "../../../constants";

export async function deleteUserApi(utenteId: string): Promise<string> {
  return fetch(
    `${ENDPOINTS_BASE}${ENDPOINT.DELETE_USER}?utenteId=${utenteId}`,
    {
      method: "DELETE",
      headers: AUTHORIZED_HEADERS,
    }
  ).then(async (response) => {
    if (response.ok) {
      return response.text();
    }
    throw new Error(await response.text());
  });
}
