import { ChangeEvent, FC, SyntheticEvent, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { Marker } from "../../pages/MarkerList/interfaces";
import { TipoRisorsa } from "../../common/tipoRisorsa/interfaces";
import { createMarker } from "../../pages/MarkerCRUD/CreateMarker/actions";
import { updateMarker } from "../../pages/MarkerCRUD/UpdateMarker/actions";
import {
  nextMarkerCode,
  nextMarkerCodeReset,
} from "../../pages/MarkerCRUD/NextMarkerCode/actions";
import { ClienteBase } from "../../pages/ClienteList/interfaces";
import { extractByteString, handleFindTypeFromAction } from "../../utils";
import { ROUTE_PATH } from "../../constants";
import ImageDragAndDrop from "./../ImageDragAndDrop/ImageDragAndDrop";
import { readMarkerBackground } from "../../pages/MarkerCRUD/ReadMarkerBackground/actions";
import { readMarkerBackgroundReset } from "./../../pages/MarkerCRUD/ReadMarkerBackground/actions";
import {
  searchMarkerCode,
  searchMarkerCodeReset,
} from "../../pages/MarkerCRUD/SearchMarkerCode/actions";
import { ConstructionOutlined } from "@material-ui/icons";

export interface MarkerBody {
  id?: number;
  codice: string;
  azione: string;
  ambito: string;
  cliente: ClienteBase;
  tipoRisorsa: {
    id: number | null;
    codice: string;
  };
  isActive: boolean;
  isLogoColored?: boolean;
  backgroundData: string | null;
}

interface LocationState {
  markerId: number;
}

const MarkerDetails: FC = ({ ...props }): JSX.Element => {
  const [values, setValues] = useState<MarkerBody>({
    id: undefined,
    codice: "",
    azione: "",
    ambito: "",
    cliente: {
      id: null,
      nome: "",
    },
    tipoRisorsa: {
      id: null,
      codice: "",
    },
    isActive: true,
    isLogoColored: false,
    backgroundData: null,
  });

  const [isMultiAmbito, setIsMultiAmbito] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const location: Location<LocationState> = useLocation();

  const isMarkerCreated = useAppSelector(
    (state) => state.createMarker.data?.isCreated
  );
  const isMarkerUpdated = useAppSelector(
    (state) => state.updateMarker.data?.isUpdated
  );

  const markerList =
    useAppSelector((state) => state.marker.data?.markers) ?? [];

  const completeClienteList =
    useAppSelector((state) => state.clienteNoPag.data?.clienti) ?? [];

  const nextMarkerCodeFromState = useAppSelector(
    (state) => state.nextMarkerCode.data
  );

  const qrCodeImage = useAppSelector((state) => state.searchMarkerCode);

  const markerBackgroundFromState = useAppSelector(
    (state) => state.readMarkerBackground.data
  );

  useEffect(() => {
    if (!location.state?.markerId) {
      dispatch(nextMarkerCode());
    }
    return () => {
      dispatch(nextMarkerCodeReset());
      dispatch(readMarkerBackgroundReset());
      dispatch(searchMarkerCodeReset());
    };
  }, []);

  useEffect(() => {
    if (nextMarkerCodeFromState) {
      setValues({
        ...values,
        codice: nextMarkerCodeFromState,
      });
    }
  }, [nextMarkerCodeFromState]);

  useEffect(() => {
    if (markerBackgroundFromState && markerBackgroundFromState.isMultiambito) {
      setIsMultiAmbito(true);
      setValues({
        ...values,
        backgroundData: markerBackgroundFromState.bytes,
        cliente: markerBackgroundFromState.cliente ?? { id: null, nome: "" },
      });
    } else {
      setIsMultiAmbito(false);
      setValues({
        ...values,
        backgroundData: null,
        cliente: markerBackgroundFromState?.cliente ?? { id: null, nome: "" },
      });
    }
  }, [markerBackgroundFromState]);

  useEffect(() => {
    if (qrCodeImage.isFetched) {
      setValues({ ...values, isLogoColored: qrCodeImage.data?.isLogoColored });
    }
  }, [qrCodeImage]);

  useEffect(() => {
    if (location.state?.markerId) {
      const markerToUpdate = getMarkerToUpdate();
      if (markerToUpdate) {
        dispatch(readMarkerBackground(markerToUpdate.codice.toString(), true));
        dispatch(searchMarkerCode(markerToUpdate.codice.toString()));
        const {
          id,
          codice,
          azione,
          ambito,
          tipoRisorsa,
          cliente,
          isActive,
          isMarkerLogoColored: isLogoColored,
        } = markerToUpdate;
        setValues({
          id,
          codice: codice.toString(),
          azione,
          ambito,
          tipoRisorsa,
          cliente,
          isActive,
          isLogoColored,
          backgroundData: values.backgroundData,
        });
      }
    }
  }, [location.state?.markerId]);

  useEffect(() => {
    if (isMarkerCreated || isMarkerUpdated) {
      navigate(`/${ROUTE_PATH.APP}/${ROUTE_PATH.MARKER_LIST}`, {
        state: { success: true },
      });
    }
  }, [isMarkerCreated, isMarkerUpdated]);

  const completeTipoRisorsaList: TipoRisorsa[] =
    useAppSelector((state) => state.tipoRisorsa.data?.tipiRisorsa) ?? [];

  const getMarkerToUpdate = (): Marker | undefined => {
    return markerList.find(
      (marker: Marker) => marker.id === Number(location.state?.markerId)
    );
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    //Se cambia il codice devo aggiornare flag e immagine
    if (event.target.name === "codice") {
      dispatch(readMarkerBackground(event.target.value, !!getMarkerToUpdate()));
      dispatch(searchMarkerCode(event.target.value));
    }
  };

  const handleChangeAutocompleteCliente = (
    event: SyntheticEvent,
    value: ClienteBase
  ): void => {
    setValues({ ...values, cliente: value });
  };

  const handleChangeTextCliente = (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ): void => {
    const newCliente = { ...values.cliente };
    newCliente.nome = target.value;
    setValues({ ...values, cliente: newCliente });
  };

  const handleChangeAutocompleteTipoRisorsa = (
    event: SyntheticEvent,
    selectedTipoRisorsa: TipoRisorsa
  ): void => {
    setValues({ ...values, tipoRisorsa: selectedTipoRisorsa });
  };

  const handleChangeTextTipoRisorsa = (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ): void => {
    const newTipoRisorsa = { ...values.tipoRisorsa };
    newTipoRisorsa.codice = target.value;
    setValues({ ...values, tipoRisorsa: newTipoRisorsa });
  };

  const handleChangeBackground = (backgroundImgData: string) => {
    setValues({
      ...values,
      backgroundData: backgroundImgData,
    });
  };

  const handleDeleteBackground = () => {
    setValues({
      ...values,
      backgroundData: null,
    });
  };

  const handleMarkerAction = (): void => {
    const {
      cliente,
      ambito,
      tipoRisorsa,
      azione,
      codice,
      isActive,
      isLogoColored,
      backgroundData,
    } = values; // this is the markerToCreate

    if (Number(location.state?.markerId)) {
      const markerToUpdate: MarkerBody = {
        id: Number(location.state.markerId),
        cliente,
        azione: azione.trim(),
        ambito,
        tipoRisorsa,
        codice,
        isActive,
        isLogoColored,
        backgroundData: backgroundData
          ? extractByteString(backgroundData)
          : null,
      };
      dispatch(updateMarker(markerToUpdate));
    } else {
      dispatch(
        createMarker({
          cliente,
          ambito,
          tipoRisorsa,
          azione: azione.trim(),
          codice,
          isActive,
          isLogoColored,
          backgroundData,
        })
      );
    }
  };

  const handleChangeTextCallToAction = (
    target:
      | (EventTarget & HTMLTextAreaElement)
      | (EventTarget & HTMLInputElement)
  ) => {
    setValues({
      ...values,
      azione: target.value,
    });
    handleFindTypeFromAction(target.value, values, setValues);
  };

  const toggleIsActive = (): void => {
    setValues((prevState) => {
      return { ...prevState, isActive: !prevState.isActive };
    });
  };

  const toggleIsLogoColored = (): void => {
    setValues((prevState) => {
      return { ...prevState, isLogoColored: !prevState.isLogoColored };
    });
  };

  const checkDisabled = () => {
    return (
      values.cliente.id === null ||
      values.cliente.nome === "" ||
      !values.ambito ||
      !values.codice ||
      values.tipoRisorsa.id === null ||
      values.tipoRisorsa.id === 0 ||
      values.tipoRisorsa.codice === "" ||
      !values.azione
    );
  };

  return (
    <>
      <form autoComplete="off" noValidate {...props}>
        <Card>
          <CardHeader
            subheader={
              location.state?.markerId
                ? "Aggiorna i campi necessari"
                : "Completa i campi necessari"
            }
            title={location.state?.markerId ? "Modifica Marker" : "Crea Marker"}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  id="cliente"
                  options={completeClienteList}
                  getOptionLabel={(cliente: ClienteBase) => cliente.nome}
                  onChange={handleChangeAutocompleteCliente}
                  value={values.cliente}
                  inputValue={values.cliente.nome}
                  disableClearable
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Cliente"
                      name="cliente"
                      required
                      variant="outlined"
                      onChange={({ target }) => handleChangeTextCliente(target)}
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Ambito"
                  name="ambito"
                  onChange={handleChange}
                  required
                  value={values.ambito}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Codice Marker"
                  name="codice"
                  onChange={handleChange}
                  required
                  value={values.codice}
                  variant="outlined"
                  error={Number(values.codice) <= 0}
                  helperText={
                    Number(values.codice) <= 0 &&
                    "Il codice marker non può essere minore o uguale a 0"
                  }
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Call to Action"
                  name="azione"
                  onChange={({ target }) =>
                    handleChangeTextCallToAction(target)
                  }
                  required
                  value={values.azione}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <Autocomplete
                  disableClearable
                  options={completeTipoRisorsaList}
                  getOptionLabel={(tipoRisorsa: TipoRisorsa) =>
                    tipoRisorsa.codice
                  }
                  onChange={handleChangeAutocompleteTipoRisorsa}
                  inputValue={values.tipoRisorsa?.codice}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Tipo Risorsa"
                      name="tipoRisorsa"
                      value={values.tipoRisorsa?.codice}
                      required
                      variant="outlined"
                      error={
                        values.azione.length > 0 && values.tipoRisorsa?.id === 0
                      }
                      helperText={
                        values.azione.length > 0 && values.tipoRisorsa?.id === 0
                          ? "Call to Action non riconosciuta"
                          : null
                      }
                      onChange={({ target }) =>
                        handleChangeTextTipoRisorsa(target)
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      onClick={toggleIsActive}
                      checked={values.isActive}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="attivo"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      onClick={toggleIsLogoColored}
                      checked={values.isLogoColored}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label="logo colorato"
                />
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12} mt={2}>
                {qrCodeImage.isFetched && (
                  <fieldset style={customFieldset}>
                    <legend style={customLegend}>
                      <Typography color="textSecondary" fontSize={12.3}>
                        Marker evo
                      </Typography>
                    </legend>
                    <img
                      src={`data:image/png;base64,${qrCodeImage.data?.bytes}`}
                      alt={"test alt"}
                      loading="lazy"
                      style={{
                        width: 320,
                        height: 320,
                        position: "relative",
                        marginTop: 0,
                      }}
                    />
                  </fieldset>
                )}
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12} mt={2}>
                {isMultiAmbito && (
                  <fieldset style={customFieldset}>
                    <legend style={customLegend}>
                      <Typography color="textSecondary" fontSize={12.3}>
                        Sfondo multiambito
                      </Typography>
                    </legend>
                    <ImageDragAndDrop
                      backgroundImg={values.backgroundData}
                      maxFileSize={1500000}
                      maxSizeErrorMessage="L'immagine inserita non può superare 1,5MB e risoluzione 1440px di larghezza per 2960px di altezza."
                      handleChangeBackground={handleChangeBackground}
                      handleDeleteBackground={handleDeleteBackground}
                    />
                  </fieldset>
                )}
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              p: 2,
            }}
          >
            <Button
              color="secondary"
              variant="contained"
              onClick={() =>
                navigate(`/${ROUTE_PATH.APP}/${ROUTE_PATH.MARKER_LIST}`)
              }
              sx={{ marginRight: "1%" }}
            >
              Annulla
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={handleMarkerAction}
              disabled={checkDisabled()}
            >
              {location.state?.markerId ? "Aggiorna" : "Crea"}
            </Button>
          </Box>
        </Card>
      </form>
    </>
  );
};

export default MarkerDetails;

/* START styles */
const customFieldset: React.CSSProperties = {
  border: "1px solid lightgrey",
  borderRadius: 5,
  height: "350px",
  textAlign: "center",
};
const customLegend: React.CSSProperties = {
  marginLeft: "10px",
  paddingLeft: "5px",
  paddingRight: "5px",
  textAlign: "left",
};
/* END styles */