import {
  Action,
  GET_ALL_QRCODE_REQUEST,
  GET_ALL_QRCODE_REQUEST_FAILED,
  GET_ALL_QRCODE_REQUEST_SUCCEEDED,
  GET_QRCODE_REQUEST_WITH_FILTERS,
} from "./actions";
import { GetAllQrCodeRequestResponse } from "./interfaces";

export interface QrCodeRequestState {
  readonly data: GetAllQrCodeRequestResponse | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialQrCodeRequestState: QrCodeRequestState = {
  data: null,
  isFetching: false,
  isFetched: false,
};

const QrCodeRequestReducer = (
  state = initialQrCodeRequestState,
  action: Action
): QrCodeRequestState => {
  switch (action.type) {
    case GET_ALL_QRCODE_REQUEST: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case GET_QRCODE_REQUEST_WITH_FILTERS: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case GET_ALL_QRCODE_REQUEST_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case GET_ALL_QRCODE_REQUEST_FAILED: {
      return { ...state, isFetching: false, isFetched: false };
    }
    default:
      return state;
  }
};

export default QrCodeRequestReducer;
