// =============== //
// Actions //
import { UtentiRegistratiStatisticheResponse } from "../interfaces";

export const GET_ALL_USER_REGISTERED_SUCCEEDED =
  "GET_ALL_USER_REGISTERED_SUCCEEDED";
export const GET_ALL_USER_REGISTERED_FAILED = "GET_ALL_USER_REGISTERED_FAILED";
export const GET_ALL_USER_REGISTERED = "GET_ALL_USER_REGISTERED";

// =============== //
// Action types //
export type GetAllUserRegistered = {
  type: typeof GET_ALL_USER_REGISTERED;
  payload: null;
};

type GetAllUserRegisteredSucceeded = {
  type: typeof GET_ALL_USER_REGISTERED_SUCCEEDED;
  payload: UtentiRegistratiStatisticheResponse;
};

type GetAllUserRegisteredFailed = {
  type: typeof GET_ALL_USER_REGISTERED_FAILED;
  payload: null;
};

// =============== //
// Creators //
export const getAllUserRegistered = (): GetAllUserRegistered => ({
  type: GET_ALL_USER_REGISTERED,
  payload: null,
});

export const getAllUserRegisteredSucceeded = (
  response: UtentiRegistratiStatisticheResponse
): GetAllUserRegisteredSucceeded => ({
  type: GET_ALL_USER_REGISTERED_SUCCEEDED,
  payload: response,
});

export const getAllUserRegisteredFailed = (): GetAllUserRegisteredFailed => ({
  type: GET_ALL_USER_REGISTERED_FAILED,
  payload: null,
});

// =============== //
// Exports //
export type UtentiRegistratiAction =
  | GetAllUserRegistered
  | GetAllUserRegisteredSucceeded
  | GetAllUserRegisteredFailed;
