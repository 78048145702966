import { call, put, takeLatest } from "redux-saga/effects";

import { deleteUserApi } from "./api";
import {
  DELETE_USER,
  DeleteUser,
  deleteUserFailed,
  deleteUserSucceeded,
} from "./actions";
import { DeleteUserResponse, ResponsePayload } from "./interfaces";
import { SagaIterator } from "@redux-saga/types";

function* deleteUser(action: DeleteUser): SagaIterator {
  try {
    const { utenteId } = action.payload;

    const response: DeleteUserResponse = yield call(deleteUserApi, utenteId);
    const responsePayload: ResponsePayload = {
      message: response,
      isDeleted: true,
    };
    yield put(deleteUserSucceeded(responsePayload));
  } catch (e) {
    console.log("deleteUserSaga error: ", e);
    const responsePayload = {
      message: e.toString(),
      isDeleted: false,
    };
    yield put(deleteUserFailed(responsePayload));
  }
}

export default function* deleteUserSaga(): SagaIterator {
  yield takeLatest(DELETE_USER, deleteUser);
}
