// =============== //
// Actions //
import { GetAllMarkerRequestResponse } from "./interfaces";

export const GET_ALL_MARKER_REQUEST_SUCCEEDED =
  "GET_ALL_MARKER_REQUEST_SUCCEEDED";
export const GET_ALL_MARKER_REQUEST_FAILED = "GET_ALL_MARKER_REQUEST_FAILED";
export const GET_ALL_MARKER_REQUEST = "GET_ALL_MARKER_REQUEST";
export const GET_MARKER_REQUEST_WITH_FILTERS =
  "GET_MARKER_REQUEST_WITH_FILTERS";

// =============== //
// Action types //
export type GetAllMarkerRequest = {
  type: typeof GET_ALL_MARKER_REQUEST;
  payload: {
    offset: number;
    limit: number;
  };
};

export type GetMarkerRequestWithFilters = {
  type: typeof GET_MARKER_REQUEST_WITH_FILTERS;
  payload: {
    idCliente: string;
    idTipologia: string;
    ambito: string;
    marker: string;
    dateFrom: string;
    dateTo: string;
    offset: number;
    limit: number;
  };
};

type GetAllMarkerRequestSucceeded = {
  type: typeof GET_ALL_MARKER_REQUEST_SUCCEEDED;
  payload: GetAllMarkerRequestResponse;
};

type GetAllMarkerRequestFailed = {
  type: typeof GET_ALL_MARKER_REQUEST_FAILED;
  payload: null;
};

// =============== //
// Creators //
export const getAllMarkerRequest = (
  offset: number,
  limit: number
): GetAllMarkerRequest => ({
  type: GET_ALL_MARKER_REQUEST,
  payload: { offset, limit },
});

export const getMarkerRequestWithFilters = (
  idCliente: string,
  idTipologia: string,
  ambito: string,
  marker: string,
  dateFrom: string,
  dateTo: string,
  offset: number,
  limit: number
): GetMarkerRequestWithFilters => ({
  type: GET_MARKER_REQUEST_WITH_FILTERS,
  payload: {
    idCliente,
    idTipologia,
    ambito,
    marker,
    dateFrom,
    dateTo,
    offset,
    limit,
  },
});

export const getAllMarkerRequestSucceeded = (
  response: GetAllMarkerRequestResponse
): GetAllMarkerRequestSucceeded => ({
  type: GET_ALL_MARKER_REQUEST_SUCCEEDED,
  payload: response,
});

export const getAllMarkerRequestFailed = (): GetAllMarkerRequestFailed => ({
  type: GET_ALL_MARKER_REQUEST_FAILED,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | GetAllMarkerRequest
  | GetMarkerRequestWithFilters
  | GetAllMarkerRequestSucceeded
  | GetAllMarkerRequestFailed;
