// =============== //
// Actions //
import { MarkerRequestStatisticheResponse } from "../interfaces";

export const GET_ALL_MARKER_REQUESTS_SUCCEEDED =
  "GET_ALL_MARKER_REQUESTS_SUCCEEDED";
export const GET_ALL_MARKER_REQUESTS_FAILED = "GET_ALL_MARKER_REQUESTS_FAILED";
export const GET_ALL_MARKER_REQUESTS = "GET_ALL_MARKER_REQUESTS";

// =============== //
// Action types //
export type GetAllMarkerRequests = {
  type: typeof GET_ALL_MARKER_REQUESTS;
  payload: null;
};

type GetAllMarkerRequestsSucceeded = {
  type: typeof GET_ALL_MARKER_REQUESTS_SUCCEEDED;
  payload: MarkerRequestStatisticheResponse;
};

type GetAllMarkerRequestsFailed = {
  type: typeof GET_ALL_MARKER_REQUESTS_FAILED;
  payload: null;
};

// =============== //
// Creators //
export const getAllMarkerRequests = (): GetAllMarkerRequests => ({
  type: GET_ALL_MARKER_REQUESTS,
  payload: null,
});

export const getAllMarkerRequestsSucceeded = (
  response: MarkerRequestStatisticheResponse
): GetAllMarkerRequestsSucceeded => ({
  type: GET_ALL_MARKER_REQUESTS_SUCCEEDED,
  payload: response,
});

export const getAllMarkerRequestsFailed = (): GetAllMarkerRequestsFailed => ({
  type: GET_ALL_MARKER_REQUESTS_FAILED,
  payload: null,
});

// =============== //
// Exports //
export type MarkerRequestsStatisticheAction =
  | GetAllMarkerRequests
  | GetAllMarkerRequestsSucceeded
  | GetAllMarkerRequestsFailed;
