import {
  Action,
  DELETE_USER,
  DELETE_USER_FAILED,
  DELETE_USER_RESET,
  DELETE_USER_SUCCEEDED,
} from "./actions";

export interface DeleteUsersState {
  readonly data: { message: string; isDeleted: boolean } | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialDeleteUsersState: DeleteUsersState = {
  data: null,
  isFetching: false,
  isFetched: false,
};

const deleteUsersReducer = (
  state = initialDeleteUsersState,
  action: Action
): DeleteUsersState => {
  switch (action.type) {
    case DELETE_USER: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case DELETE_USER_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case DELETE_USER_FAILED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: false,
      };
    }
    case DELETE_USER_RESET: {
      return initialDeleteUsersState;
    }
    default:
      return state;
  }
};

export default deleteUsersReducer;
