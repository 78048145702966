import createSagaMiddleware from "redux-saga";
import { applyMiddleware, StoreEnhancer } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

export const sagaMiddleware = createSagaMiddleware();

export default function getMiddlewares(): StoreEnhancer {
  const middlewares = composeWithDevTools(applyMiddleware(sagaMiddleware));

  return middlewares;
}
