import { call, put, takeLatest } from "redux-saga/effects";
import {
  READ_MARKER_BACKGROUND,
  readMarkerBackgroundFailed,
  readMarkerBackgroundSucceeded,
  ReadMarkerBackground,
} from "./actions";
import { readMarkerBackgroundApi } from "./api";
import { SagaIterator } from "@redux-saga/types";
import { MarkerBackgroundResponse } from "./interfaces";

function* readMarkerBackground(action: ReadMarkerBackground): SagaIterator {
  try {
    const { markerCode, isEdit } = action.payload;

    const response: MarkerBackgroundResponse = yield call(
      readMarkerBackgroundApi,
      markerCode,
      isEdit
    );
    yield put(readMarkerBackgroundSucceeded(response));
  } catch (e) {
    yield put(readMarkerBackgroundFailed());
  }
}

export default function* readMarkerBackgroundSaga(): SagaIterator {
  yield takeLatest(READ_MARKER_BACKGROUND, readMarkerBackground);
}
