// =============== //
// Actions //
import {responsePayload} from "./interfaces";
import {CreateMarkerBulkInterf} from "./interfaces";
import {MarkerBody} from "../../../components/markerDetails/MarkerDetails";

export const CREATE_MARKER_SUCCEEDED = "CREATE_MARKER_SUCCEEDED";
export const CREATE_MARKER_FAILED = "CREATE_MARKER_FAILED";
export const CREATE_MARKER_RESET = "CREATE_MARKER_RESET";
export const CREATE_MARKER = "CREATE_MARKER";
export const CREATE_MARKER_BULK = "CREATE_MARKER_BULK";

// =============== //
// Action types //
export type CreateMarker = {
    type: typeof CREATE_MARKER;
    payload: MarkerBody;
};

type CreateMarkerSucceeded = {
    type: typeof CREATE_MARKER_SUCCEEDED;
    payload: responsePayload;
};

type CreateMarkerFailed = {
    type: typeof CREATE_MARKER_FAILED;
    payload: responsePayload;
};

type CreateMarkerReset = {
    type: typeof CREATE_MARKER_RESET;
    payload: null;
};

export type CreateMarkerBulk = {
    type: typeof CREATE_MARKER_BULK;
    payload: CreateMarkerBulkInterf;
};

// =============== //
// Creators //
export const createMarker = (markerToCreate: MarkerBody): CreateMarker => ({
    type: CREATE_MARKER,
    payload: markerToCreate,
});

export const createMarkerSucceeded = (
    response: responsePayload
): CreateMarkerSucceeded => ({
    type: CREATE_MARKER_SUCCEEDED,
    payload: response,
});

export const createMarkerFailed = (
    response: responsePayload
): CreateMarkerFailed => ({
    type: CREATE_MARKER_FAILED,
    payload: response,
});

export const createMarkerBulk = (markerToCreate: MarkerBody, numeroMarkerTotali: number): CreateMarkerBulk => ({
    type: CREATE_MARKER_BULK,
    payload: {markerBody: markerToCreate, numeroMarkerTotali: numeroMarkerTotali},
});

export const createMarkerReset = (): CreateMarkerReset => ({
    type: CREATE_MARKER_RESET,
    payload: null,
});

// =============== //
// Exports //
export type Action =
    | CreateMarker
    | CreateMarkerSucceeded
    | CreateMarkerFailed
    | CreateMarkerReset
    | CreateMarkerBulk;
