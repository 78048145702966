// =============== //
// Actions //
import { QrCodeRequestReportResponse } from "./interfaces";

export const GET_QRCODE_REQUEST_REPORT_SUCCEEDED =
  "GET_QRCODE_REQUEST_REPORT_SUCCEEDED";
export const GET_QRCODE_REQUEST_REPORT_FAILED =
  "GET_QRCODE_REQUEST_REPORT_FAILED";
export const GET_QRCODE_REQUEST_REPORT_RESET =
  "GET_QRCODE_REQUEST_REPORT_RESET";
export const GET_QRCODE_REQUEST_REPORT = "GET_QRCODE_REQUEST_REPORT";

// =============== //
// Action types //
export type GetQrCodeRequestReport = {
  type: typeof GET_QRCODE_REQUEST_REPORT;
  payload: {
    email: string;
    idTipologia: string;
    idCliente: string;
    dateFrom: string;
    dateTo: string;
  };
};

type GetQrCodeRequestReportSucceeded = {
  type: typeof GET_QRCODE_REQUEST_REPORT_SUCCEEDED;
  payload: QrCodeRequestReportResponse;
};

type GetQrCodeRequestReportFailed = {
  type: typeof GET_QRCODE_REQUEST_REPORT_FAILED;
  payload: null;
};

type GetQrCodeRequestReportReset = {
  type: typeof GET_QRCODE_REQUEST_REPORT_RESET;
  payload: null;
};

// =============== //
// Creators //
export const getQrCodeRequestReport = (
  email: string,
  idTipologia: string,
  idCliente: string,
  dateFrom: string,
  dateTo: string
): GetQrCodeRequestReport => ({
  type: GET_QRCODE_REQUEST_REPORT,
  payload: {
    email,
    idTipologia,
    idCliente,
    dateFrom,
    dateTo,
  },
});

export const getQrCodeRequestReportSucceeded = (
  response: QrCodeRequestReportResponse
): GetQrCodeRequestReportSucceeded => ({
  type: GET_QRCODE_REQUEST_REPORT_SUCCEEDED,
  payload: response,
});

export const getQrCodeRequestReportFailed =
  (): GetQrCodeRequestReportFailed => ({
    type: GET_QRCODE_REQUEST_REPORT_FAILED,
    payload: null,
  });

export const getQrCodeRequestReportReset = (): GetQrCodeRequestReportReset => ({
  type: GET_QRCODE_REQUEST_REPORT_RESET,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | GetQrCodeRequestReport
  | GetQrCodeRequestReportSucceeded
  | GetQrCodeRequestReportFailed
  | GetQrCodeRequestReportReset;
