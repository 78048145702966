import React, { ChangeEvent, FC } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { renderIcon } from "../../utils/returntype";
import { useAppSelector } from "../../hooks";
import { QrCodeRequest } from "../../pages/QrCodeRequestList/interfaces";
import moment from "moment";

interface Props {
  qrCodeRequestList: QrCodeRequest[];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
}

const QrCodeRequestListResults: FC<Props> = ({
  qrCodeRequestList,
  page,
  setPage,
  limit,
  setLimit,
  ...rest
}) => {
  const totalRecord =
    useAppSelector((state) => state.qrCodeRequest.data?.totalRecord) ?? 0;

  const handleLimitChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLimit(Number(event.target.value));
    setPage(0);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Email utente</TableCell>
                  <TableCell align="center">Tipo Risorsa</TableCell>
                  <TableCell>Call to Action</TableCell>
                  <TableCell>Data</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {qrCodeRequestList.map((qrCodeRequest: QrCodeRequest) => (
                  <TableRow
                    hover
                    key={qrCodeRequest.id}
                    id={qrCodeRequest.id.toString()}
                  >
                    <TableCell>{qrCodeRequest.utente.email}</TableCell>
                    <TableCell align="center">
                      {renderIcon(qrCodeRequest.tipoRisorsa.codice)}
                    </TableCell>
                    <TableCell
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                      onClick={() =>
                        window.open(qrCodeRequest.qrcodeData, "_blank")
                      }
                    >
                      {qrCodeRequest.qrcodeData}
                    </TableCell>
                    <TableCell>
                      {moment(qrCodeRequest.dataRequest).format(
                        "D MMMM YYYY, H:mm:ss"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={totalRecord}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage={"Righe per pagina"}
        />
      </Card>
    </>
  );
};

export default QrCodeRequestListResults;
