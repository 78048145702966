import "react-perfect-scrollbar/dist/css/styles.css";
import { useRoutes } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core";
import { FC, useEffect } from "react";
import GlobalStyles from "./components/GlobalStyles";
import "./mixins/chartjs";
import theme from "./theme";
import getRoutes from "./routes";
import CustomizedSnackbars from "./components/SnackBarAlert/CustomizedSnackbars";
import AlertDialogSlide from "./components/AlertDialogSlide/AlertDialogSlide";
import { isAuthenticated } from "./utils";

const App: FC = () => {
  let routes = getRoutes();

  useEffect(() => {
    routes = getRoutes();
  }, [isAuthenticated()]);

  const routing = useRoutes(routes);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {routing}
      <CustomizedSnackbars />
      <AlertDialogSlide />
    </ThemeProvider>
  );
};

export default App;
