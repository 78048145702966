// =============== //
// Actions //
import { responsePayload } from "./interfaces";

export const DELETE_MARKER_SUCCEEDED = "DELETE_MARKER_SUCCEEDED";
export const DELETE_MARKER_FAILED = "DELETE_MARKER_FAILED";
export const DELETE_MARKER_RESET = "DELETE_MARKER_RESET";
export const DELETE_MARKER = "DELETE_MARKER";

// =============== //
// Action types //
export type DeleteMarker = {
  type: typeof DELETE_MARKER;
  payload: {
    markerId: number;
  };
};

type DeleteMarkerSucceeded = {
  type: typeof DELETE_MARKER_SUCCEEDED;
  payload: responsePayload;
};

type DeleteMarkerFailed = {
  type: typeof DELETE_MARKER_FAILED;
  payload: responsePayload;
};

type DeleteMarkerReset = {
  type: typeof DELETE_MARKER_RESET;
  payload: null;
};

// =============== //
// Creators //
export const deleteMarker = (markerId: number): DeleteMarker => ({
  type: DELETE_MARKER,
  payload: { markerId },
});

export const deleteMarkerSucceeded = (
  response: responsePayload
): DeleteMarkerSucceeded => ({
  type: DELETE_MARKER_SUCCEEDED,
  payload: response,
});

export const deleteMarkerFailed = (
  response: responsePayload
): DeleteMarkerFailed => ({
  type: DELETE_MARKER_FAILED,
  payload: response,
});

export const deleteMarkerReset = (): DeleteMarkerReset => ({
  type: DELETE_MARKER_RESET,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | DeleteMarker
  | DeleteMarkerSucceeded
  | DeleteMarkerFailed
  | DeleteMarkerReset;
