import { Helmet } from "react-helmet";
import { Box, Container, Typography } from "@material-ui/core";
import { FC } from "react";

const NotFound: FC = (): JSX.Element => (
  <>
    <Helmet>
      <title>404 | DxMarker</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: "background.default",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
      }}
    >
      <Container maxWidth="md">
        <Typography align="center" color="textPrimary" variant="h1">
          La pagina che stai cercando non esiste
        </Typography>
        <Typography align="center" color="textPrimary" variant="subtitle2">
          Probabilmente sei arrivato qui inserendo un indirizzo sbagliato. Prova
          ad usare la barra laterale per la navigazione.
        </Typography>
        <Box sx={{ textAlign: "center" }}>
          <img
            alt="Under development"
            src="/static/images/undraw_page_not_found_su7k.svg"
            style={{
              marginTop: 50,
              display: "inline-block",
              maxWidth: "100%",
              width: 560,
            }}
          />
        </Box>
      </Container>
    </Box>
  </>
);

export default NotFound;
