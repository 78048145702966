import { all } from "redux-saga/effects";
import loginSaga from "../pages/Login/saga";
import userSaga from "../pages/UserList/saga";
import createUserSaga from "../pages/UserCRUD/CreateUser/saga";
import updateUserSaga from "../pages/UserCRUD/UpdateUser/saga";
import deleteUserSaga from "../pages/UserCRUD/DeleteUser/saga";
import changePasswordSaga from "../components/ResetPasswordPage/saga";
import markerSaga from "../pages/MarkerList/saga";
import getAllTipoRisorsaSaga from "../common/tipoRisorsa/saga";
import deleteMarkerSaga from "../pages/MarkerCRUD/DeleteMarker/saga";
import createMarkerSaga, {createMarkerBulkSaga} from "../pages/MarkerCRUD/CreateMarker/saga";
import updateMarkerSaga from "../pages/MarkerCRUD/UpdateMarker/saga";
import nextMarkerCodeSaga from "../pages/MarkerCRUD/NextMarkerCode/saga";
import readMarkerBackgroundSaga from "../pages/MarkerCRUD/ReadMarkerBackground/saga";
import getAllRuoloUtenteSaga from "../common/ruoloUtente/saga";
import markerRequestSaga from "../pages/MarkerRequestList/saga";
import qrCodeRequestSaga from "../pages/QrCodeRequestList/saga";
import statisticheAllSaga from "../pages/Dashboard/statistics/saga";
import userReportSaga from "../pages/UserCRUD/Report/saga";
import clienteSaga from "../pages/ClienteList/saga";
import createClienteSaga from "../pages/ClienteCRUD/CreateCliente/saga";
import updateClienteSaga from "../pages/ClienteCRUD/UpdateCliente/saga";
import deleteClienteSaga from "../pages/ClienteCRUD/DeleteCliente/saga";
import getAllClienteNoPagSaga from "../common/cliente/saga";
import markerRequestReportSaga from "../pages/MarkerRequestList/Report/saga";
import qrCodeRequestReportSaga from "../pages/QrCodeRequestList/Report/saga";
import { isUserAuthorized } from "../utils";
import { AUTHORIZATION } from "../constants";
import searchMarkerCodeSaga from "../pages/MarkerCRUD/SearchMarkerCode/saga";

export default function* rootSaga(): Generator {
  yield all([
    loginSaga(),
    isUserAuthorized(AUTHORIZATION.UT_V) && userSaga(),
    isUserAuthorized(AUTHORIZATION.UT_E) && createUserSaga(),
    isUserAuthorized(AUTHORIZATION.UT_E) && updateUserSaga(),
    isUserAuthorized(AUTHORIZATION.UT_E) && deleteUserSaga(),
    isUserAuthorized(AUTHORIZATION.UT_V) && userReportSaga(),

    changePasswordSaga(),

    isUserAuthorized(AUTHORIZATION.MK_V) && markerSaga(),
    isUserAuthorized(AUTHORIZATION.MK_E) && createMarkerSaga(),
    isUserAuthorized(AUTHORIZATION.MK_E) && createMarkerBulkSaga(),
    isUserAuthorized(AUTHORIZATION.MK_E) && updateMarkerSaga(),
    isUserAuthorized(AUTHORIZATION.MK_E) && deleteMarkerSaga(),
    isUserAuthorized(AUTHORIZATION.MK_E) && searchMarkerCodeSaga(),
    isUserAuthorized(AUTHORIZATION.MK_E) && readMarkerBackgroundSaga(),

    getAllTipoRisorsaSaga(),
    nextMarkerCodeSaga(),
    getAllRuoloUtenteSaga(),
    isUserAuthorized(AUTHORIZATION.RM_V) && markerRequestSaga(),
    isUserAuthorized(AUTHORIZATION.RM_V) && markerRequestReportSaga(),

    isUserAuthorized(AUTHORIZATION.QR_V) && qrCodeRequestSaga(),
    isUserAuthorized(AUTHORIZATION.QR_V) && qrCodeRequestReportSaga(),

    statisticheAllSaga(), // there are custom check inside the statisticheAllSaga

    isUserAuthorized(AUTHORIZATION.CL_V) && clienteSaga(),
    isUserAuthorized(AUTHORIZATION.CL_E) && createClienteSaga(),
    isUserAuthorized(AUTHORIZATION.CL_E) && updateClienteSaga(),
    isUserAuthorized(AUTHORIZATION.CL_E) && deleteClienteSaga(),
    isUserAuthorized(AUTHORIZATION.CL_V) && getAllClienteNoPagSaga(),
  ]);
}
