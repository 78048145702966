// =============== //
// Actions //
import { FindAllMarkerResponse, GetQrImageResponse } from "./interfaces";

export const FINDALL_MARKER_SUCCEEDED = "FINDALL_MARKER_SUCCEEDED";
export const FINDALL_MARKER_FAILED = "FINDALL_MARKER_FAILED";
export const FINDALL_MARKER = "FINDALL_MARKER";
export const FIND_MARKER_WITH_FILTERS = "FIND_MARKER_WITH_FILTERS";
export const GET_QR_IMAGE = "GET_QR_IMAGE";
export const REMOVE_QR_IMAGE = "REMOVE_QR_IMAGE";
export const GET_QR_IMAGE_SUCCEEDED = "GET_QR_IMAGE_SUCCEEDED";
export const GET_QR_IMAGE_FAILED = "GET_QR_IMAGE_FAILED";

// =============== //
// Action types //
export type FindAllMarker = {
  type: typeof FINDALL_MARKER;
  payload: {
    offset: number;
    limit: number;
  };
};

export type FindMarkerWithFilters = {
  type: typeof FIND_MARKER_WITH_FILTERS;
  payload: {
    idCliente: string;
    codiceMarker: string;
    idTipologia: string;
    ambito: string;
    isActive: boolean | null;
    offset: number;
    limit: number;
  };
};

type FindAllMarkerSucceeded = {
  type: typeof FINDALL_MARKER_SUCCEEDED;
  payload: FindAllMarkerResponse;
};

type FindAllMarkerFailed = {
  type: typeof FINDALL_MARKER_FAILED;
  payload: null;
};

export type GetQrImage = {
  type: typeof GET_QR_IMAGE;
  payload: {
    marker: number;
  };
};
type RemoveQrImage = {
  type: typeof REMOVE_QR_IMAGE;
  payload: null;
};
type GetQrImageSucceeded = {
  type: typeof GET_QR_IMAGE_SUCCEEDED;
  payload: GetQrImageResponse;
};
type GetQrImageFailed = {
  type: typeof GET_QR_IMAGE_FAILED;
  payload: null;
};

// =============== //
// Creators //
export const findAllMarker = (
  offset: number,
  limit: number
): FindAllMarker => ({
  type: FINDALL_MARKER,
  payload: { offset, limit },
});

export const findMarkerWithFilters = (
  idCliente: string,
  codiceMarker: string,
  idTipologia: string,
  ambito: string,
  isActive: boolean | null,
  offset: number,
  limit: number
): FindMarkerWithFilters => ({
  type: FIND_MARKER_WITH_FILTERS,
  payload: {
    idCliente,
    codiceMarker,
    idTipologia,
    ambito,
    isActive,
    offset,
    limit,
  },
});

export const findAllMarkerSucceeded = (
  response: FindAllMarkerResponse
): FindAllMarkerSucceeded => ({
  type: FINDALL_MARKER_SUCCEEDED,
  payload: response,
});

export const findAllMarkerFailed = (): FindAllMarkerFailed => ({
  type: FINDALL_MARKER_FAILED,
  payload: null,
});

export const getQrImage = (marker: number): GetQrImage => ({
  type: GET_QR_IMAGE,
  payload: {
    marker,
  },
});

export const removeQrImage = (): RemoveQrImage => ({
  type: REMOVE_QR_IMAGE,
  payload: null,
});

export const getQrImageSucceeded = (
  response: GetQrImageResponse
): GetQrImageSucceeded => ({
  type: GET_QR_IMAGE_SUCCEEDED,
  payload: response,
});

export const getQrImageFailed = (): GetQrImageFailed => ({
  type: GET_QR_IMAGE_FAILED,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | FindAllMarker
  | FindMarkerWithFilters
  | FindAllMarkerSucceeded
  | FindAllMarkerFailed
  | GetQrImage
  | RemoveQrImage
  | GetQrImageSucceeded
  | GetQrImageFailed;
