import { call, put, takeLatest } from "redux-saga/effects";

import { deleteMarkerApi } from "./api";
import {
  DELETE_MARKER,
  DeleteMarker,
  deleteMarkerFailed,
  deleteMarkerSucceeded,
} from "./actions";
import { DeleteMarkerResponse } from "./interfaces";
import { SagaIterator } from "@redux-saga/types";

function* deleteMarker(action: DeleteMarker): SagaIterator {
  try {
    const { markerId } = action.payload;

    const response: DeleteMarkerResponse = yield call(
      deleteMarkerApi,
      markerId
    );
    const responsePayload = {
      message: response,
      isDeleted: true,
    };
    yield put(deleteMarkerSucceeded(responsePayload));
  } catch (e) {
    console.log("deleteMarkerSaga error: ", e);
    const responsePayload = {
      message: e.toString(),
      isDeleted: false,
    };
    yield put(deleteMarkerFailed(responsePayload));
  }
}

export default function* deleteMarkerSaga(): SagaIterator {
  yield takeLatest(DELETE_MARKER, deleteMarker);
}
