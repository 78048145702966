import {
  Action,
  NEXT_MARKER_CODE,
  NEXT_MARKER_CODE_FAILED,
  NEXT_MARKER_CODE_RESET,
  NEXT_MARKER_CODE_SUCCEEDED,
} from "./actions";

export interface NextMarkerCodeState {
  readonly data: string | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialNextMarkerCode: NextMarkerCodeState = {
  data: null,
  isFetching: false,
  isFetched: false,
};

const NextMarkerCodeReducer = (
  state = initialNextMarkerCode,
  action: Action
): NextMarkerCodeState => {
  switch (action.type) {
    case NEXT_MARKER_CODE: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case NEXT_MARKER_CODE_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case NEXT_MARKER_CODE_FAILED: {
      return { ...state, isFetching: false, isFetched: false };
    }
    case NEXT_MARKER_CODE_RESET: {
      return initialNextMarkerCode;
    }
    default:
      return state;
  }
};

export default NextMarkerCodeReducer;
