import {
  AUTHORIZED_HEADERS,
  ENDPOINT,
  ENDPOINTS_BASE,
} from "../../../constants";
import { GetWidgetsDataResponse } from "./interfaces";

export async function getWidgetsDataApi(
  intervallo: number
): Promise<GetWidgetsDataResponse> {
  return fetch(
    `${ENDPOINTS_BASE}${ENDPOINT.GET_STATISTICHE_WIDGETS}?intervallo=${intervallo}`,
    {
      method: "GET",
      headers: AUTHORIZED_HEADERS,
    }
  ).then((response) => response.json());
}
