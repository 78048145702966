import {
  AUTHORIZED_HEADERS,
  ENDPOINT,
  ENDPOINTS_BASE,
} from "../../../constants";
import { QrImageResponse } from "./interfaces";

export async function nextMarkerCodeApi(
  markerCode: string
): Promise<QrImageResponse> {
  return fetch(
    `${ENDPOINTS_BASE}${ENDPOINT.QR_IMAGE}?codMarker=${markerCode}`,
    {
      method: "GET",
      headers: AUTHORIZED_HEADERS,
    }
  ).then(async (response) => {
    if (response.status === 200) {
      return response.json();
    } else throw new Error("Errore generico");
  });
}
