import {
  GET_ALL_USER_REGISTERED,
  GET_ALL_USER_REGISTERED_FAILED,
  GET_ALL_USER_REGISTERED_SUCCEEDED,
  UtentiRegistratiAction,
} from "./actions/utentiRegistratiActions";
import {
  ClientiStatisticheResponse,
  GetWidgetsDataResponse,
  MarkerRequestStatisticheResponse,
  MarkerStatisticheResponse,
  QrcodeRequestStatisticheResponse,
  UtentiRegistratiStatisticheResponse,
} from "./interfaces";
import {
  GET_ALL_QRCODE_REQUESTS,
  GET_ALL_QRCODE_REQUESTS_FAILED,
  GET_ALL_QRCODE_REQUESTS_SUCCEEDED,
  QrcodeRequestAction,
} from "./actions/qrcodeRequestActions";
import {
  GET_ALL_MARKERS,
  GET_ALL_MARKERS_FAILED,
  GET_ALL_MARKERS_SUCCEEDED,
  MarkerStatisticheAction,
} from "./actions/markerActions";
import {
  GET_ALL_MARKER_REQUESTS,
  GET_ALL_MARKER_REQUESTS_FAILED,
  GET_ALL_MARKER_REQUESTS_SUCCEEDED,
  MarkerRequestsStatisticheAction,
} from "./actions/markerRequestsActions";
import {
  ClientiTotaliAction,
  GET_ALL_CLIENTI_FAILED,
  GET_ALL_CLIENTI_SUCCEEDED,
} from "./actions/clientiActions";
import {
  GET_WIDGETS_DATA_FAILED,
  GET_WIDGETS_DATA_SUCCEEDED,
  GetWidgetsDataAction,
} from "./actions/widgetsActions";

export interface StatisticheState {
  readonly clientiTotali: {
    data: ClientiStatisticheResponse | null;
    readonly isFetching: boolean;
    readonly isFetched: boolean;
  };
  readonly utentiRegistrati: {
    data: UtentiRegistratiStatisticheResponse | null;
    readonly isFetching: boolean;
    readonly isFetched: boolean;
  };
  readonly qrcodeRequest: {
    data: QrcodeRequestStatisticheResponse | null;
    readonly isFetching: boolean;
    readonly isFetched: boolean;
  };
  readonly markerTotali: {
    data: MarkerStatisticheResponse | null;
    readonly isFetching: boolean;
    readonly isFetched: boolean;
  };
  readonly markerRequest: {
    data: MarkerRequestStatisticheResponse | null;
    readonly isFetching: boolean;
    readonly isFetched: boolean;
  };
  readonly widgets: {
    data: GetWidgetsDataResponse | null;
    readonly isFetching: boolean;
    readonly isFetched: boolean;
  };
}

const initialUsersState: StatisticheState = {
  clientiTotali: { data: null, isFetching: false, isFetched: false },
  utentiRegistrati: { data: null, isFetching: false, isFetched: false },
  qrcodeRequest: { data: null, isFetching: false, isFetched: false },
  markerTotali: { data: null, isFetching: false, isFetched: false },
  markerRequest: { data: null, isFetching: false, isFetched: false },
  widgets: { data: null, isFetching: false, isFetched: false },
};

const StatisticheAllReducer = (
  state = initialUsersState,
  action:
    | UtentiRegistratiAction
    | QrcodeRequestAction
    | MarkerStatisticheAction
    | MarkerRequestsStatisticheAction
    | ClientiTotaliAction
    | GetWidgetsDataAction
): StatisticheState => {
  switch (action.type) {
    case GET_ALL_USER_REGISTERED: {
      return {
        ...state,
        utentiRegistrati: {
          data: state.utentiRegistrati.data,
          isFetching: true,
          isFetched: false,
        },
      };
    }
    case GET_ALL_USER_REGISTERED_SUCCEEDED: {
      return {
        ...state,
        utentiRegistrati: {
          data: action.payload,
          isFetching: false,
          isFetched: true,
        },
      };
    }
    case GET_ALL_USER_REGISTERED_FAILED: {
      return {
        ...state,
        utentiRegistrati: {
          data: state.utentiRegistrati.data,
          isFetching: false,
          isFetched: false,
        },
      };
    }

    case GET_ALL_QRCODE_REQUESTS: {
      return {
        ...state,
        qrcodeRequest: {
          data: state.qrcodeRequest.data,
          isFetching: true,
          isFetched: false,
        },
      };
    }
    case GET_ALL_QRCODE_REQUESTS_SUCCEEDED: {
      return {
        ...state,
        qrcodeRequest: {
          data: action.payload,
          isFetching: false,
          isFetched: true,
        },
      };
    }
    case GET_ALL_QRCODE_REQUESTS_FAILED: {
      return {
        ...state,
        qrcodeRequest: {
          data: state.qrcodeRequest.data,
          isFetching: false,
          isFetched: false,
        },
      };
    }

    case GET_ALL_MARKERS: {
      return {
        ...state,
        markerTotali: {
          data: state.markerTotali.data,
          isFetching: true,
          isFetched: false,
        },
      };
    }
    case GET_ALL_MARKERS_SUCCEEDED: {
      return {
        ...state,
        markerTotali: {
          data: action.payload,
          isFetching: false,
          isFetched: true,
        },
      };
    }
    case GET_ALL_MARKERS_FAILED: {
      return {
        ...state,
        markerTotali: {
          data: state.markerTotali.data,
          isFetching: false,
          isFetched: false,
        },
      };
    }

    case GET_ALL_MARKER_REQUESTS: {
      return {
        ...state,
        markerRequest: {
          data: state.markerRequest.data,
          isFetching: true,
          isFetched: false,
        },
      };
    }
    case GET_ALL_MARKER_REQUESTS_SUCCEEDED: {
      return {
        ...state,
        markerRequest: {
          data: action.payload,
          isFetching: false,
          isFetched: true,
        },
      };
    }
    case GET_ALL_MARKER_REQUESTS_FAILED: {
      return {
        ...state,
        markerRequest: {
          data: state.markerRequest.data,
          isFetching: false,
          isFetched: false,
        },
      };
    }

    case GET_ALL_CLIENTI_SUCCEEDED: {
      return {
        ...state,
        clientiTotali: {
          data: action.payload,
          isFetching: false,
          isFetched: true,
        },
      };
    }
    case GET_ALL_CLIENTI_FAILED: {
      return {
        ...state,
        clientiTotali: {
          data: state.clientiTotali.data,
          isFetching: false,
          isFetched: false,
        },
      };
    }
    case GET_WIDGETS_DATA_SUCCEEDED: {
      return {
        ...state,
        widgets: {
          data: action.payload,
          isFetching: false,
          isFetched: true,
        },
      };
    }

    case GET_WIDGETS_DATA_FAILED: {
      return {
        ...state,
        widgets: {
          data: action.payload,
          isFetching: false,
          isFetched: false,
        },
      };
    }
    default:
      return state;
  }
};

export default StatisticheAllReducer;
