import { MyAction } from "shared";

export interface AppState {
  readonly version: string;
}

const initialAppState: AppState = {
  version: process.env.REACT_APP_VERSION,
};

const appReducer = (state = initialAppState, action: MyAction): AppState => {
  switch (action.type) {
    default:
      return state;
  }
};

export default appReducer;
