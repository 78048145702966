// =============== //
// Actions //
import { UserReportResponse } from "./interfaces";

export const GET_USER_REPORT_SUCCEEDED = "GET_USER_REPORT_SUCCEEDED";
export const GET_USER_REPORT_FAILED = "GET_USER_REPORT_FAILED";
export const GET_USER_REPORT_RESET = "GET_USER_REPORT_RESET";
export const GET_USER_REPORT = "GET_USER_REPORT";

// =============== //
// Action types //
export type GetUserReport = {
  type: typeof GET_USER_REPORT;
  payload: {
    email: string;
    ruoloId: string;
    ambito: string;
    dateFrom: string;
    dateTo: string;
  };
};

type GetUserReportSucceeded = {
  type: typeof GET_USER_REPORT_SUCCEEDED;
  payload: UserReportResponse;
};

type GetUserReportFailed = {
  type: typeof GET_USER_REPORT_FAILED;
  payload: null;
};

type GetUserReportReset = {
  type: typeof GET_USER_REPORT_RESET;
  payload: null;
};

// =============== //
// Creators //
export const getUserReport = (
  email: string,
  ruoloId: string,
  ambito: string,
  dateFrom: string,
  dateTo: string
): GetUserReport => ({
  type: GET_USER_REPORT,
  payload: { email, ruoloId, ambito, dateFrom, dateTo },
});

export const getUserReportSucceeded = (
  response: UserReportResponse
): GetUserReportSucceeded => ({
  type: GET_USER_REPORT_SUCCEEDED,
  payload: response,
});

export const getUserReportFailed = (): GetUserReportFailed => ({
  type: GET_USER_REPORT_FAILED,
  payload: null,
});

export const getUserReportReset = (): GetUserReportReset => ({
  type: GET_USER_REPORT_RESET,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | GetUserReport
  | GetUserReportSucceeded
  | GetUserReportFailed
  | GetUserReportReset;
