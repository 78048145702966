import { call, put, takeLatest } from "redux-saga/effects";
import {
  SEARCH_MARKER_CODE,
  searchMarkerCodeFailed,
  searchMarkerCodeSucceeded,
  SearchMarkerCode,
} from "./actions";
import { nextMarkerCodeApi } from "./api";
import { SagaIterator } from "@redux-saga/types";
import { QrImageResponse } from "./interfaces";

function* searchMarkerCode(action: SearchMarkerCode): SagaIterator {
  const { markerCode } = action.payload;
  try {
    const response: QrImageResponse = yield call(nextMarkerCodeApi, markerCode);
    yield put(searchMarkerCodeSucceeded(response));
  } catch (e: any) {
    yield put(searchMarkerCodeFailed());
  }
}

export default function* searchMarkerCodeSaga(): SagaIterator {
  yield takeLatest(SEARCH_MARKER_CODE, searchMarkerCode);
}
