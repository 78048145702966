// =============== //
// Actions //
import { responsePayload } from "./interfaces";

export const CREATE_USER_SUCCEEDED = "CREATE_USER_SUCCEEDED";
export const CREATE_USER_FAILED = "CREATE_USER_FAILED";
export const CREATE_USER_RESET = "CREATE_USER_RESET";
export const CREATE_USER = "CREATE_USER";

// =============== //
// Action types //
export type CreateUser = {
  type: typeof CREATE_USER;
  payload: {
    email: string;
    password: string;
    ruoloUtente: number;
    isActive: boolean;
  };
};

type CreateUserSucceeded = {
  type: typeof CREATE_USER_SUCCEEDED;
  payload: responsePayload;
};

type CreateUserFailed = {
  type: typeof CREATE_USER_FAILED;
  payload: responsePayload;
};

type CreateUserReset = {
  type: typeof CREATE_USER_RESET;
  payload: null;
};

// =============== //
// Creators //
export const createUser = (
  email: string,
  password: string,
  ruoloUtente: number,
  isActive: boolean
): CreateUser => ({
  type: CREATE_USER,
  payload: { email, password, ruoloUtente, isActive },
});

export const createUserSucceeded = (
  response: responsePayload
): CreateUserSucceeded => ({
  type: CREATE_USER_SUCCEEDED,
  payload: response,
});

export const createUserFailed = (
  response: responsePayload
): CreateUserFailed => ({
  type: CREATE_USER_FAILED,
  payload: response,
});

export const createUserReset = (): CreateUserReset => ({
  type: CREATE_USER_RESET,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | CreateUser
  | CreateUserSucceeded
  | CreateUserFailed
  | CreateUserReset;
