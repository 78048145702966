import React, { FC } from "react";
import DoneIcon from "@material-ui/icons/Done";
import { experimentalStyled } from "@material-ui/core";
import { useLocation } from "react-router";
import { ConfirmedState } from "./interfaces";

const ResetPasswordConfirmed: FC = (): JSX.Element => {
  const LayoutRoot = experimentalStyled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  }));

  const location = useLocation();
  const state = location.state as ConfirmedState;
  const userEmail: string = state.user;

  return (
    <LayoutRoot>
      <DoneIcon sx={{ color: "#19BF19", fontSize: "15rem" }} />
      <h1>{`Password di ${userEmail} modificata`}</h1>
      <br />
      <h3>
        Apri l'App Ciclopeviewer ed effettua la login con le tue nuove
        credenziali
      </h3>
      <img
        alt="Logo"
        src="/static/logo2_inverted.png"
        style={{ width: "16rem", margin: "3%" }}
      />
    </LayoutRoot>
  );
};

export default ResetPasswordConfirmed;
