import { GetAllClienteResponse } from "../../pages/ClienteList/interfaces";

// =============== //
// Actions //

export const GET_ALL_CLIENTE_NO_PAG = "GET_ALL_CLIENTE_NO_PAG";
export const GET_ALL_CLIENTE_NO_PAG_SUCCEEDED =
  "GET_ALL_CLIENTE_NO_PAG_SUCCEEDED";
export const GET_ALL_CLIENTE_NO_PAG_FAILED = "GET_ALL_CLIENTE_NO_PAG_FAILED";

// =============== //
// Action types //
export type GetAllClienteNoPag = {
  type: typeof GET_ALL_CLIENTE_NO_PAG;
  payload: { offset: number; limit: number };
};

type GetAllClienteNoPagSucceeded = {
  type: typeof GET_ALL_CLIENTE_NO_PAG_SUCCEEDED;
  payload: GetAllClienteResponse;
};

type GetAllClienteNoPagFailed = {
  type: typeof GET_ALL_CLIENTE_NO_PAG_FAILED;
  payload: null;
};

// =============== //
// Creators //
export const getAllClienteNoPag = (): GetAllClienteNoPag => ({
  type: GET_ALL_CLIENTE_NO_PAG,
  payload: { offset: 0, limit: 0 },
});

export const getAllClienteNoPagSucceeded = (
  response: GetAllClienteResponse
): GetAllClienteNoPagSucceeded => ({
  type: GET_ALL_CLIENTE_NO_PAG_SUCCEEDED,
  payload: response,
});

export const getAllClienteNoPagFailed = (): GetAllClienteNoPagFailed => ({
  type: GET_ALL_CLIENTE_NO_PAG_FAILED,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | GetAllClienteNoPag
  | GetAllClienteNoPagSucceeded
  | GetAllClienteNoPagFailed;
