import { call, put, select, takeLatest } from "redux-saga/effects";

import { disableMarkerApi, updateMarkerApi } from "./api";
import {
  UPDATE_MARKER,
  UpdateMarker,
  updateMarkerFailed,
  updateMarkerSucceeded,
} from "./actions";
import { UpdateMarkerResponse } from "./interfaces";
import { SagaIterator } from "@redux-saga/types";
import { MarkerBody } from "../../../components/markerDetails/MarkerDetails";
import { Marker } from "../../MarkerList/interfaces";

function* updateMarker(action: UpdateMarker): SagaIterator {
  const markerToUpdate: MarkerBody = action.payload;

  try {
    const markerList = yield select((state) => state.marker.data?.markers);
    const originalMarkerArray = markerList?.filter(
      (marker: Marker) => marker.id === markerToUpdate.id
    );

    if (originalMarkerArray) {
      const originalMarker: Marker =
        originalMarkerArray.length > 0 ? originalMarkerArray[0] : null;
      if (originalMarker) {
        if (originalMarker.isActive && !markerToUpdate.isActive) {
          const areMarkerObjectEqual = (): boolean => {
            return (
              originalMarker.azione === markerToUpdate.azione &&
              originalMarker.codice === Number(markerToUpdate.codice) &&
              originalMarker.ambito === markerToUpdate.ambito &&
              originalMarker.cliente.id === markerToUpdate.cliente.id &&
              originalMarker.cliente.id === markerToUpdate.cliente.id &&
              originalMarker.tipoRisorsa.id === markerToUpdate.tipoRisorsa.id
            );
          };

          if (areMarkerObjectEqual()) {
            const response: UpdateMarkerResponse = yield call(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              disableMarkerApi,
              markerToUpdate.id
            );
            const responsePayload = {
              message: response,
              isUpdated: true,
            };
            yield put(updateMarkerSucceeded(responsePayload));
            return;
          }
        }
      }
    }
  } catch (e) {
    console.log("Disable marker Saga error: ", e);
    const responsePayload = {
      message: e.toString(),
      isUpdated: false,
    };
    yield put(updateMarkerFailed(responsePayload));
  }

  try {
    const response: UpdateMarkerResponse = yield call(
      updateMarkerApi,
      markerToUpdate
    );
    const responsePayload = {
      message: response,
      isUpdated: true,
    };
    yield put(updateMarkerSucceeded(responsePayload));
  } catch (e) {
    console.log("updateMarkerSaga error: ", e);
    const responsePayload = {
      message: e.toString(),
      isUpdated: false,
    };
    yield put(updateMarkerFailed(responsePayload));
  }
}

export default function* updateMarkerSaga(): SagaIterator {
  yield takeLatest(UPDATE_MARKER, updateMarker);
}
