import { FC } from "react";

const Logo: FC = (props): JSX.Element => (
  <img
    alt="Logo"
    src="/static/logo2_inverted.png"
    style={{ width: "11rem", padding: "2%" }}
    {...props}
  />
);

export default Logo;
