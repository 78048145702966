import {
  Action,
  GET_ALL_MARKER_REQUEST,
  GET_ALL_MARKER_REQUEST_FAILED,
  GET_ALL_MARKER_REQUEST_SUCCEEDED,
  GET_MARKER_REQUEST_WITH_FILTERS,
} from "./actions";
import { GetAllMarkerRequestResponse } from "./interfaces";

export interface MarkerRequestState {
  readonly data: GetAllMarkerRequestResponse | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialMarkerRequestState: MarkerRequestState = {
  data: null,
  isFetching: false,
  isFetched: false,
};

const MarkerRequestReducer = (
  state = initialMarkerRequestState,
  action: Action
): MarkerRequestState => {
  switch (action.type) {
    case GET_ALL_MARKER_REQUEST: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case GET_MARKER_REQUEST_WITH_FILTERS: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case GET_ALL_MARKER_REQUEST_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case GET_ALL_MARKER_REQUEST_FAILED: {
      return { ...state, isFetching: false, isFetched: false };
    }
    default:
      return state;
  }
};

export default MarkerRequestReducer;
