import { Navigate } from "react-router-dom";
import DashboardLayout from "./components/DashboardLayout";
import MainLayout from "./components/MainLayout";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import CreateUser from "./pages/UserCRUD/CreateUser/CreateUser";
import UserList from "./pages/UserList/UserList";
import MarkerList from "./pages/MarkerList/MarkerList";
import CreateMarker from "./pages/MarkerCRUD/CreateMarker/CreateMarker";
import MarkerRequestList from "./pages/MarkerRequestList/MarkerRequestList";
import QrCodeRequestList from "./pages/QrCodeRequestList/QrCodeRequestList";
import ClienteList from "./pages/ClienteList/ClienteList";
import CreateCliente from "./pages/ClienteCRUD/CreateCliente/CreateCliente";
import { isUserAuthorized } from "./utils";
import { AUTHORIZATION, ROUTE_PATH } from "./constants";
import RegistrationConfirmed from "./components/RegistrationMessagePage/RegistrationConfirmed";
import RegistrationError from "./components/RegistrationMessagePage/RegistrationError";
import ResetPasswordPage from "./components/ResetPasswordPage/ResetPassword";
import ResetPasswordConfirmed from "./components/ResetPasswordMessagePage/ResetPasswordConfirmed";
import ResetPasswordError from "./components/ResetPasswordMessagePage/ResetPasswordError";
import CreateMarkerMassivo from "./pages/MarkerCRUD/CreateMarker/CreateMarkerMassivo";

interface RouteObj {
  path?: string;
  element?: JSX.Element;
  children?: RouteObj[];
}

const getRoutes = (): Array<RouteObj> => [
  {
    path: ROUTE_PATH.APP,
    element: <DashboardLayout />,
    children: [
      isUserAuthorized(AUTHORIZATION.ST_V)
        ? {
            path: ROUTE_PATH.DASHBOARD,
            element: <Dashboard />,
          }
        : {},

      isUserAuthorized(AUTHORIZATION.CL_V)
        ? {
            path: ROUTE_PATH.CLIENTI,
            element: <ClienteList />,
          }
        : {},
      isUserAuthorized(AUTHORIZATION.CL_E)
        ? { path: ROUTE_PATH.CLIENTE, element: <CreateCliente /> }
        : {},

      isUserAuthorized(AUTHORIZATION.MK_V)
        ? {
            path: ROUTE_PATH.MARKER_LIST,
            element: <MarkerList />,
          }
        : {},
      isUserAuthorized(AUTHORIZATION.MK_E)
        ? { path: ROUTE_PATH.MARKER, element: <CreateMarker /> }
        : {},

      isUserAuthorized(AUTHORIZATION.MK_E)
        ? { path: ROUTE_PATH.MARKER_MASSIVO, element: <CreateMarkerMassivo /> }
        : {},

      isUserAuthorized(AUTHORIZATION.UT_V)
        ? {
            path: ROUTE_PATH.USERS,
            element: <UserList />,
          }
        : {},
      isUserAuthorized(AUTHORIZATION.UT_E)
        ? { path: ROUTE_PATH.USER, element: <CreateUser /> }
        : {},

      isUserAuthorized(AUTHORIZATION.RM_V)
        ? {
            path: ROUTE_PATH.MARKER_REQUEST_LIST,
            element: <MarkerRequestList />,
          }
        : {},

      isUserAuthorized(AUTHORIZATION.QR_V)
        ? {
            path: ROUTE_PATH.QRCODE_REQUEST_LIST,
            element: <QrCodeRequestList />,
          }
        : {},

      {
        path: "*",
        element: <Navigate to={`/${ROUTE_PATH.NOT_FOUND}`} />,
      },
    ],
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: ROUTE_PATH.LOGIN, element: <Login /> },
      {
        path: ROUTE_PATH.NOT_FOUND,
        element: <NotFound />,
      },
      {
        path: "/",
        element: <Navigate to={`/${ROUTE_PATH.APP}/${ROUTE_PATH.DASHBOARD}`} />,
      },
      {
        path: "*",
        element: <Navigate to={`/${ROUTE_PATH.NOT_FOUND}`} />,
      },
    ],
  },
  { path: ROUTE_PATH.CONFIRMED, element: <RegistrationConfirmed /> },
  { path: ROUTE_PATH.RESET_PASSWORD, element: <ResetPasswordPage /> },
  {
    path: ROUTE_PATH.CONFIRMED_RESET_PASSWORD,
    element: <ResetPasswordConfirmed />,
  },
  { path: ROUTE_PATH.ERROR_RESET_PASSWORD, element: <ResetPasswordError /> },
  { path: ROUTE_PATH.ERROR, element: <RegistrationError /> },
];

export default getRoutes;
