// =============== //
// Actions //
import { GetWidgetsDataResponse } from "../interfaces";

export const GET_WIDGETS_DATA = "GET_WIDGETS_DATA";
export const GET_WIDGETS_DATA_SUCCEEDED = "GET_WIDGETS_DATA_SUCCEEDED";
export const GET_WIDGETS_DATA_FAILED = "GET_WIDGETS_DATA_FAILED";

// =============== //
// Action types //
export type GetWidgetsData = {
  type: typeof GET_WIDGETS_DATA;
  payload: { intervallo: number };
};

type GetWidgetsDataSucceeded = {
  type: typeof GET_WIDGETS_DATA_SUCCEEDED;
  payload: GetWidgetsDataResponse;
};

type GetWidgetsDataFailed = {
  type: typeof GET_WIDGETS_DATA_FAILED;
  payload: null;
};

// =============== //
// Creators //
export const getWidgetsData = (intervallo: number): GetWidgetsData => ({
  type: GET_WIDGETS_DATA,
  payload: { intervallo },
});

export const getWidgetsDataSucceeded = (
  response: GetWidgetsDataResponse
): GetWidgetsDataSucceeded => ({
  type: GET_WIDGETS_DATA_SUCCEEDED,
  payload: response,
});

export const getWidgetsDataFailed = (): GetWidgetsDataFailed => ({
  type: GET_WIDGETS_DATA_FAILED,
  payload: null,
});

// =============== //
// Exports //
export type GetWidgetsDataAction =
  | GetWidgetsData
  | GetWidgetsDataSucceeded
  | GetWidgetsDataFailed;
