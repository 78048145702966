import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Formik, FormikHelpers, FormikValues } from "formik";
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
} from "@material-ui/core";

import * as React from "react";
import { FC, useEffect } from "react";
import { loginUser } from "./actions";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { isAuthenticated } from "../../utils";

const Login: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const isLoginFetching = useAppSelector((state) => state.login.isFetching);
  const isLoginFetched = useAppSelector((state) => state.login.isFetched);

  useEffect(() => {
    if (!isLoginFetching && isLoginFetched) {
      if (isAuthenticated()) {
        navigate("/", { replace: true });
      }
      history.go(0);
    }
  }, [isLoginFetching, isLoginFetched]);

  const handleSubmit = (
    values: FormikValues,
    actions: FormikHelpers<{ email: string; password: string }>
  ): void => {
    dispatch(loginUser(values.email, values.password));
    actions.setSubmitting(false);
  };

  return (
    <>
      <Helmet>
        <title>Login | DxMarker</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          justifyContent: "center",
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            /*validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Must be a valid email")
                .max(255)
                .required("Email is required"),
              password: Yup.string().max(255).required("Password is required"),
            })}*/
            onSubmit={(values, actions) => handleSubmit(values, actions)}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography color="textPrimary" variant="h2">
                    Login
                  </Typography>
                </Box>
                <TextField
                  //error={Boolean(touched.email && errors.email)}
                  fullWidth
                  // helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  //type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Login
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </>
  );
};

export default Login;
