import { Helmet } from "react-helmet";
import { Box, Container } from "@material-ui/core";
import React, {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAllQrCodeRequest, getQrCodeRequestWithFilters } from "./actions";
import { QrCodeRequest } from "./interfaces";
import QrCodeRequestListResults from "../../components/qrCodeRequest/QrCodeRequestListResults";
import QrCodeRequestListToolbar from "../../components/qrCodeRequest/QrCodeRequestListToolbar";
import { getAllTipoRisorsa } from "../../common/tipoRisorsa/actions";
import { TipoRisorsa } from "../../common/tipoRisorsa/interfaces";
import { DateRange } from "@material-ui/lab/DateRangePicker/RangeTypes";
import { dateMakerForFilters } from "../../utils";
import { Cliente } from "../ClienteList/interfaces";
import { getAllClienteNoPag } from "../../common/cliente/actions";
import { getQrCodeRequestReport } from "./Report/actions";
import CustomLoader from "../../components/CustomLoader/CustomLoader";

export interface QrCodeRequestFilters {
  email: string;
  cliente: { id: string; nome: string };
  tipologia: { id: string; codice: string };
}

const QrCodeRequestList: FC = () => {
  const dispatch = useAppDispatch();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const [qrCodeRequestFilters, setQrCodeRequestFilters] =
    useState<QrCodeRequestFilters>({
      email: "",
      cliente: { id: "", nome: "" },
      tipologia: { id: "", codice: "" },
    });

  const [dateFilterValue, setDateFilterValue] = useState<DateRange<Date>>([
    null,
    null,
  ]);

  const areQrCodeRequestFiltersFilled = (): boolean => {
    const { email, cliente, tipologia } = qrCodeRequestFilters;
    return (
      !!email ||
      !!cliente.id ||
      !!tipologia.id ||
      !!dateFilterValue[0] ||
      !!dateFilterValue[1]
    );
  };

  const qrCodeRequestList: QrCodeRequest[] =
    useAppSelector((state) => state.qrCodeRequest.data?.qrcodeRequests) ?? [];

  const isQrCodeRequestFetching = useAppSelector(
    (state) => state.qrCodeRequest.isFetching
  );
  const isQrCodeRequestFetched = useAppSelector(
    (state) => state.qrCodeRequest.isFetched
  );

  useEffect(() => {
    dispatch(getAllTipoRisorsa());
    dispatch(getAllClienteNoPag());
  }, []);

  useEffect(() => {
    setPage(0);
    setLimit(10);
    const offset = page * limit;
    if (areQrCodeRequestFiltersFilled()) {
      const { email, tipologia, cliente } = qrCodeRequestFilters;
      const dateFrom = dateMakerForFilters(dateFilterValue[0]);
      const dateTo = dateMakerForFilters(dateFilterValue[1]);
      dispatch(
        getQrCodeRequestWithFilters(
          email,
          tipologia.id,
          cliente.id,
          dateFrom,
          dateTo,
          offset,
          limit
        )
      );
    } else {
      dispatch(getAllQrCodeRequest(offset, limit));
    }
  }, [
    qrCodeRequestFilters.email,
    qrCodeRequestFilters.cliente.id,
    qrCodeRequestFilters.tipologia.id,
    dateFilterValue[0],
    dateFilterValue[1],
  ]);

  useEffect(() => {
    const offset = page * limit;
    if (areQrCodeRequestFiltersFilled()) {
      const { email, tipologia, cliente } = qrCodeRequestFilters;
      const dateFrom = dateMakerForFilters(dateFilterValue[0]);
      const dateTo = dateMakerForFilters(dateFilterValue[1]);
      dispatch(
        getQrCodeRequestWithFilters(
          email,
          tipologia.id,
          cliente.id,
          dateFrom,
          dateTo,
          offset,
          limit
        )
      );
    } else {
      dispatch(getAllQrCodeRequest(offset, limit));
    }
  }, [page, limit]);

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setQrCodeRequestFilters({
      ...qrCodeRequestFilters,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeAutocompleteTipoRisorsaFilter = (
    event: SyntheticEvent,
    selectedTipoRisorsa: TipoRisorsa | null
  ) => {
    if (selectedTipoRisorsa) {
      setQrCodeRequestFilters((prevState) => {
        return {
          ...prevState,
          tipologia: {
            id: selectedTipoRisorsa.id.toString(),
            codice: selectedTipoRisorsa.codice,
          },
        };
      });
    } else {
      setQrCodeRequestFilters((prevState) => {
        return {
          ...prevState,
          tipologia: {
            id: "",
            codice: "",
          },
        };
      });
    }
  };

  const handleChangeTextTipoRisorsaFilter = (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => {
    setQrCodeRequestFilters({
      ...qrCodeRequestFilters,
      tipologia: { ...qrCodeRequestFilters.tipologia, codice: target.value },
    });
  };

  const handleChangeAutocompleteClienteFilter = (
    event: SyntheticEvent,
    selectedCliente: Cliente | null
  ) => {
    if (selectedCliente) {
      setQrCodeRequestFilters((prevState) => {
        return {
          ...prevState,
          cliente: {
            id: selectedCliente.id.toString(),
            nome: selectedCliente.nome,
          },
        };
      });
    } else {
      setQrCodeRequestFilters((prevState) => {
        return {
          ...prevState,
          cliente: {
            id: "",
            nome: "",
          },
        };
      });
    }
  };

  const handleChangeTextClienteFilter = (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => {
    setQrCodeRequestFilters((prevState) => {
      return {
        ...prevState,
        cliente: { ...prevState.cliente, nome: target.value },
      };
    });
  };

  const handleReportClick = (): void => {
    const { email, cliente, tipologia } = qrCodeRequestFilters;
    const dateFrom = dateMakerForFilters(dateFilterValue[0]);
    const dateTo = dateMakerForFilters(dateFilterValue[1]);
    dispatch(
      getQrCodeRequestReport(email, tipologia.id, cliente.id, dateFrom, dateTo)
    );
  };

  return (
    <>
      <Helmet>
        <title>QrCode Request | DxQrCode</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <QrCodeRequestListToolbar
            qrCodeRequestFilters={qrCodeRequestFilters}
            handleChangeAutocompleteClienteFilter={
              handleChangeAutocompleteClienteFilter
            }
            handleChangeTextClienteFilter={handleChangeTextClienteFilter}
            handleChangeFilter={handleChangeFilter}
            handleChangeAutocompleteTipoRisorsaFilter={
              handleChangeAutocompleteTipoRisorsaFilter
            }
            handleChangeTextTipoRisorsaFilter={
              handleChangeTextTipoRisorsaFilter
            }
            dateFilterValue={dateFilterValue}
            setDateFilterValue={setDateFilterValue}
            handleReportClick={handleReportClick}
          />
          <Box sx={{ pt: 3 }}>
            <CustomLoader visible={isQrCodeRequestFetching} />
            {isQrCodeRequestFetched && (
              <QrCodeRequestListResults
                qrCodeRequestList={qrCodeRequestList}
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
              />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default QrCodeRequestList;
