import { ChangeEvent, FC, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  TextField,
} from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { Cliente } from "../../pages/ClienteList/interfaces";
import { createCliente } from "../../pages/ClienteCRUD/CreateCliente/actions";
import { updateCliente } from "../../pages/ClienteCRUD/UpdateCliente/actions";
import { ROUTE_PATH } from "../../constants";

interface LocationState {
  clienteId: number;
}

const ClienteDetails: FC = ({ ...props }): JSX.Element => {
  const [values, setValues] = useState<
    Pick<
      Cliente,
      "nome" | "descrizione" | "sede" | "email" | "isActive" | "isLogoColored"
    >
  >({
    nome: "",
    descrizione: "",
    sede: "",
    email: "",
    isActive: true,
    isLogoColored: false,
  });

  const [, setClienteToUpdate] = useState<Cliente | null>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const location: Location<LocationState> = useLocation();

  const isClienteCreated = useAppSelector(
    (state) => state.createCliente.data?.isCreated
  );
  const isClienteUpdated = useAppSelector(
    (state) => state.updateCliente.data?.isUpdated
  );
  const clienteList =
    useAppSelector((state) => state.cliente.data?.clienti) ?? [];

  useEffect(() => {
    if (location.state?.clienteId) {
      const clienteToUpdateFromState: Cliente | undefined = clienteList.find(
        (cliente) => cliente.id === location.state?.clienteId
      );

      if (clienteToUpdateFromState) {
        setClienteToUpdate(clienteToUpdateFromState);
        const { nome, descrizione, sede, email, isActive, isLogoColored } =
          clienteToUpdateFromState;
        setValues({
          nome,
          descrizione,
          sede,
          email,
          isActive,
          isLogoColored,
        });
      }
    }
  }, [location.state?.clienteId]);

  useEffect(() => {
    if (isClienteCreated || isClienteUpdated) {
      navigate(`/${ROUTE_PATH.APP}/${ROUTE_PATH.CLIENTI}`);
    }
  }, [isClienteCreated, isClienteUpdated]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleClienteAction = () => {
    const { nome, descrizione, sede, email, isActive, isLogoColored } = values;

    if (location.state?.clienteId) {
      dispatch(
        updateCliente(
          location.state.clienteId,
          nome,
          email,
          sede,
          descrizione,
          isActive,
          isLogoColored
        )
      );
    } else {
      dispatch(
        createCliente(nome, email, sede, descrizione, isActive, isLogoColored)
      );
    }
  };

  const toggleIsActive = (): void => {
    setValues((prevState) => {
      return { ...prevState, isActive: !prevState.isActive };
    });
  };

  const toggleIsColored = (): void => {
    setValues((prevState) => {
      return { ...prevState, isLogoColored: !prevState.isLogoColored };
    });
  };

  return (
    <>
      <form autoComplete="off" noValidate {...props}>
        <Card>
          <CardHeader
            name="title"
            subheader={
              location.state?.clienteId
                ? "Aggiorna i campi necessari"
                : "Completa i campi necessari"
            }
            title={
              location.state?.clienteId ? "Modifica Cliente" : "Crea Cliente"
            }
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nome"
                  name="nome"
                  onChange={handleChange}
                  required
                  value={values.nome}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Sede"
                  name="sede"
                  onChange={handleChange}
                  required
                  value={values.sede}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Descrizione attività"
                  name="descrizione"
                  onChange={handleChange}
                  required
                  value={values.descrizione}
                  variant="outlined"
                  multiline
                  rows={4}
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Indirizzo email"
                  name="email"
                  onChange={handleChange}
                  required
                  value={values.email}
                  variant="outlined"
                />
                <Grid item md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        onClick={toggleIsActive}
                        checked={values.isActive}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="attivo"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        onClick={toggleIsColored}
                        checked={values.isLogoColored}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    }
                    label="Logo colorato"
                  />
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              p: 2,
            }}
          >
            <Button
              color="secondary"
              variant="contained"
              onClick={() =>
                navigate(`/${ROUTE_PATH.APP}/${ROUTE_PATH.CLIENTI}`)
              }
              sx={{ marginRight: "1%" }}
            >
              Annulla
            </Button>

            <Button
              color="primary"
              variant="contained"
              onClick={handleClienteAction}
              disabled={
                !values.nome ||
                !values.descrizione ||
                !values.sede ||
                !values.email
              }
            >
              {location.state?.clienteId ? "Aggiorna" : "Crea"}
            </Button>
          </Box>
        </Card>
      </form>
    </>
  );
};

export default ClienteDetails;
