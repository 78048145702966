import { call, put, takeLatest } from "redux-saga/effects";

import { updateClienteApi } from "./api";
import {
  UPDATE_CLIENTE,
  UpdateCliente,
  updateClienteFailed,
  updateClienteSucceeded,
} from "./actions";
import { UpdateClienteResponse } from "./interfaces";
import { SagaIterator } from "@redux-saga/types";

function* updateCliente(action: UpdateCliente): SagaIterator {
  try {
    const { id, nome, email, sede, descrizione, isActive, isLogoColored } =
      action.payload;

    const response: UpdateClienteResponse = yield call(
      updateClienteApi,
      id,
      nome,
      email,
      sede,
      descrizione,
      isActive,
      isLogoColored
    );
    const responsePayload = {
      message: response,
      isUpdated: true,
    };
    yield put(updateClienteSucceeded(responsePayload));
  } catch (e) {
    console.log("updateClienteSaga error: ", e);
    const responsePayload = {
      message: e.toString(),
      isUpdated: false,
    };
    yield put(updateClienteFailed(responsePayload));
  }
}

export default function* updateClienteSaga(): SagaIterator {
  yield takeLatest(UPDATE_CLIENTE, updateCliente);
}
