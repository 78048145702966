// =============== //
// Actions //
import { MarkerStatisticheResponse } from "../interfaces";

export const GET_ALL_MARKERS_SUCCEEDED = "GET_ALL_MARKERS_SUCCEEDED";
export const GET_ALL_MARKERS_FAILED = "GET_ALL_MARKERS_FAILED";
export const GET_ALL_MARKERS = "GET_ALL_MARKERS";

// =============== //
// Action types //
export type GetAllMarkers = {
  type: typeof GET_ALL_MARKERS;
  payload: null;
};

type GetAllMarkersSucceeded = {
  type: typeof GET_ALL_MARKERS_SUCCEEDED;
  payload: MarkerStatisticheResponse;
};

type GetAllMarkersFailed = {
  type: typeof GET_ALL_MARKERS_FAILED;
  payload: null;
};

// =============== //
// Creators //
export const getAllMarkers = (): GetAllMarkers => ({
  type: GET_ALL_MARKERS,
  payload: null,
});

export const getAllMarkersSucceeded = (
  response: MarkerStatisticheResponse
): GetAllMarkersSucceeded => ({
  type: GET_ALL_MARKERS_SUCCEEDED,
  payload: response,
});

export const getAllMarkersFailed = (): GetAllMarkersFailed => ({
  type: GET_ALL_MARKERS_FAILED,
  payload: null,
});

// =============== //
// Exports //
export type MarkerStatisticheAction =
  | GetAllMarkers
  | GetAllMarkersSucceeded
  | GetAllMarkersFailed;
