// =============== //
// Actions //
import { responsePayload } from "./interfaces";

export const CREATE_CLIENTE_SUCCEEDED = "CREATE_CLIENTE_SUCCEEDED";
export const CREATE_CLIENTE_FAILED = "CREATE_CLIENTE_FAILED";
export const CREATE_CLIENTE_RESET = "CREATE_CLIENTE_RESET";
export const CREATE_CLIENTE = "CREATE_CLIENTE";

// =============== //
// Action types //
export type CreateCliente = {
  type: typeof CREATE_CLIENTE;
  payload: {
    nome: string;
    email: string;
    sede: string;
    descrizione: string;
    isActive: boolean;
    isLogoColored: boolean;
  };
};

type CreateClienteSucceeded = {
  type: typeof CREATE_CLIENTE_SUCCEEDED;
  payload: responsePayload;
};

type CreateClienteFailed = {
  type: typeof CREATE_CLIENTE_FAILED;
  payload: responsePayload;
};

type CreateClienteReset = {
  type: typeof CREATE_CLIENTE_RESET;
  payload: null;
};

// =============== //
// Creators //
export const createCliente = (
  nome: string,
  email: string,
  sede: string,
  descrizione: string,
  isActive: boolean,
  isLogoColored: boolean
): CreateCliente => ({
  type: CREATE_CLIENTE,
  payload: { nome, email, sede, descrizione, isActive, isLogoColored },
});

export const createClienteSucceeded = (
  response: responsePayload
): CreateClienteSucceeded => ({
  type: CREATE_CLIENTE_SUCCEEDED,
  payload: response,
});

export const createClienteFailed = (
  response: responsePayload
): CreateClienteFailed => ({
  type: CREATE_CLIENTE_FAILED,
  payload: response,
});

export const createClienteReset = (): CreateClienteReset => ({
  type: CREATE_CLIENTE_RESET,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | CreateCliente
  | CreateClienteSucceeded
  | CreateClienteFailed
  | CreateClienteReset;
