import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_ALL_CLIENTE_NO_PAG,
  GetAllClienteNoPag,
  getAllClienteNoPagFailed,
  getAllClienteNoPagSucceeded,
} from "./actions";
import { GetAllClienteResponse } from "../../pages/ClienteList/interfaces";
import { getAllClienteApi } from "../../pages/ClienteList/api";
import { SagaIterator } from "@redux-saga/types";

/**
 * funzione utilizzata per reperire lista completa di clienti,
 * la lista viene utilizzata per inserire gli utenti nelle select
 * @param action
 */
function* getAllClienteNoPag(action: GetAllClienteNoPag): SagaIterator {
  try {
    const { offset, limit } = action.payload;
    const firstResponse: GetAllClienteResponse = yield call(
      getAllClienteApi,
      offset,
      limit
    );
    const { totalRecord } = firstResponse;
    const lastResponse: GetAllClienteResponse = yield call(
      getAllClienteApi,
      0,
      totalRecord
    );
    yield put(getAllClienteNoPagSucceeded(lastResponse));
  } catch (e) {
    yield put(getAllClienteNoPagFailed());
  }
}

export default function* getAllClienteNoPagSaga(): SagaIterator {
  yield takeLatest(GET_ALL_CLIENTE_NO_PAG, getAllClienteNoPag);
}
