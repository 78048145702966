// =============== //
// Actions //
import { FindAllUserResponse } from "./interfaces";

export const FINDALL_USER_SUCCEEDED = "FINDALL_USER_SUCCEEDED";
export const FINDALL_USER_FAILED = "FINDALL_USER_FAILED";
export const FINDALL_USER = "FINDALL_USER";
export const FIND_USER_WITH_FILTERS = "FIND_USER_WITH_FILTERS";

// =============== //
// Action types //
export type FindAllUser = {
  type: typeof FINDALL_USER;
  payload: {
    offset: number;
    limit: number;
  };
};

export type FindUserWithFilters = {
  type: typeof FIND_USER_WITH_FILTERS;
  payload: {
    email: string;
    ruoloId: string;
    ambito: string;
    idCliente: string;
    codiceMarker: string;
    dateFrom: string;
    dateTo: string;
    offset: number;
    limit: number;
  };
};

type FindAllUserSucceeded = {
  type: typeof FINDALL_USER_SUCCEEDED;
  payload: FindAllUserResponse;
};

type FindAllUserFailed = {
  type: typeof FINDALL_USER_FAILED;
  payload: null;
};

// =============== //
// Creators //
export const findAllUser = (offset: number, limit: number): FindAllUser => ({
  type: FINDALL_USER,
  payload: { offset, limit },
});

export const findUserWithFilters = (
  email: string,
  ruoloId: string,
  ambito: string,
  idCliente: string,
  codiceMarker: string,
  dateFrom: string,
  dateTo: string,
  offset: number,
  limit: number
): FindUserWithFilters => ({
  type: FIND_USER_WITH_FILTERS,
  payload: {
    email,
    ruoloId,
    ambito,
    idCliente,
    codiceMarker,
    dateFrom,
    dateTo,
    offset,
    limit,
  },
});

export const findAllUserSucceeded = (
  response: FindAllUserResponse
): FindAllUserSucceeded => ({
  type: FINDALL_USER_SUCCEEDED,
  payload: response,
});

export const findAllUserFailed = (): FindAllUserFailed => ({
  type: FINDALL_USER_FAILED,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | FindAllUser
  | FindUserWithFilters
  | FindAllUserSucceeded
  | FindAllUserFailed;
