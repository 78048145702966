// =============== //
// Actions //

export const NEXT_MARKER_CODE_SUCCEEDED = "NEXT_MARKER_CODE_SUCCEEDED";
export const NEXT_MARKER_CODE_FAILED = "NEXT_MARKER_CODE_FAILED";
export const NEXT_MARKER_CODE_RESET = "NEXT_MARKER_CODE_RESET";
export const NEXT_MARKER_CODE = "NEXT_MARKER_CODE";

// =============== //
// Action types //
export type NextMarkerCode = {
  type: typeof NEXT_MARKER_CODE;
  payload: null;
};

type NextMarkerCodeSucceeded = {
  type: typeof NEXT_MARKER_CODE_SUCCEEDED;
  payload: string;
};

type NextMarkerCodeFailed = {
  type: typeof NEXT_MARKER_CODE_FAILED;
  payload: null;
};

type NextMarkerCodeReset = {
  type: typeof NEXT_MARKER_CODE_RESET;
  payload: null;
};

// =============== //
// Creators //
export const nextMarkerCode = (): NextMarkerCode => ({
  type: NEXT_MARKER_CODE,
  payload: null,
});

export const nextMarkerCodeSucceeded = (
  response: string
): NextMarkerCodeSucceeded => ({
  type: NEXT_MARKER_CODE_SUCCEEDED,
  payload: response,
});

export const nextMarkerCodeFailed = (): NextMarkerCodeFailed => ({
  type: NEXT_MARKER_CODE_FAILED,
  payload: null,
});

export const nextMarkerCodeReset = (): NextMarkerCodeReset => ({
  type: NEXT_MARKER_CODE_RESET,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | NextMarkerCode
  | NextMarkerCodeSucceeded
  | NextMarkerCodeFailed
  | NextMarkerCodeReset;
