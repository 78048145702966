import React, { FC } from "react";
import TextField from "@material-ui/core/TextField";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import it from "date-fns/locale/it";
import Box from "@material-ui/core/Box";
import MobileDateRangePicker from "@material-ui/lab/MobileDateRangePicker";
import { DateRange } from "@material-ui/lab/DateRangePicker/RangeTypes";

interface Props {
  setDateFilterValue: React.Dispatch<React.SetStateAction<DateRange<Date>>>;
  dateFilterValue: DateRange<Date>;
  disabled?: boolean;
}

const CustomResponsiveDateRangePicker: FC<Props> = ({
  dateFilterValue,
  setDateFilterValue,
  disabled,
}): JSX.Element => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={it}>
      <MobileDateRangePicker
        startText="Da"
        endText="A"
        value={dateFilterValue}
        onChange={(newValue) => {
          setDateFilterValue(newValue);
        }}
        maxDate={new Date()}
        disabled={disabled}
        renderInput={(startProps, endProps) => {
          return (
            <>
              <TextField {...startProps} variant="standard" placeholder="" />
              <Box sx={{ mx: 2 }} />
              <TextField {...endProps} variant="standard" placeholder="" />
            </>
          );
        }}
      />
    </LocalizationProvider>
  );
};
export default CustomResponsiveDateRangePicker;
