import { call, put, takeLatest } from "redux-saga/effects";

import { fetchLoginUser } from "./api";
import {
  LOGIN_USER,
  LoginUser,
  loginUserFailed,
  loginUserSucceeded,
} from "./actions";
import { LoginResponse } from "./interfaces";
import { storeToken } from "../../utils";
import { SagaIterator } from "@redux-saga/types";

function* registerUser(action: LoginUser): SagaIterator {
  try {
    const { email, password } = action.payload;
    const response: LoginResponse = yield call(fetchLoginUser, email, password);
    yield put(loginUserSucceeded(response));
    storeToken(response.token);
  } catch (e) {
    yield put(loginUserFailed());
  }
}

export default function* loginSaga(): SagaIterator {
  yield takeLatest(LOGIN_USER, registerUser);
}
