import {
  Action,
  GET_ALL_CLIENTE,
  GET_ALL_CLIENTE_SUCCEEDED,
  GET_ALL_CLIENTE_FAILED,
  FIND_CLIENTE_WITH_FILTERS,
} from "./actions";
import { GetAllClienteResponse } from "./interfaces";

export interface ClienteState {
  readonly data: GetAllClienteResponse | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialClienteState: ClienteState = {
  data: null,
  isFetching: false,
  isFetched: false,
};

const ClienteReducer = (
  state = initialClienteState,
  action: Action
): ClienteState => {
  switch (action.type) {
    case GET_ALL_CLIENTE: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case FIND_CLIENTE_WITH_FILTERS: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case GET_ALL_CLIENTE_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case GET_ALL_CLIENTE_FAILED: {
      return { ...state, isFetching: false, isFetched: false };
    }
    default:
      return state;
  }
};

export default ClienteReducer;
