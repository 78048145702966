import {
  AUTHORIZED_HEADERS,
  ENDPOINT,
  ENDPOINTS_BASE,
} from "../../../constants";
import { QrCodeRequestReportResponse } from "./interfaces";

export async function getQrCodeRequestReportApi(
  email: string,
  idTipologia: string,
  idCliente: string,
  dateFrom: string,
  dateTo: string
): Promise<QrCodeRequestReportResponse> {
  return fetch(
    `${ENDPOINTS_BASE}${ENDPOINT.GET_QRCODE_REQUEST_REPORT}?emailUtente=${email}&idCliente=${idCliente}&idTipologia=${idTipologia}&dateFrom=${dateFrom}&dateTo=${dateTo}`,
    {
      method: "GET",
      headers: AUTHORIZED_HEADERS,
    }
  ).then((response) => response.json());
}
