import React, { ChangeEvent, Component, FC, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { Edit as EditIcon, Trash2 as DelIcon } from "react-feather";
import { User } from "../../pages/UserList/interfaces";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import AlertDialog from "../AlertDialog/AlertDialog";
import { deleteUser } from "../../pages/UserCRUD/DeleteUser/actions";
import { renderIsActive } from "../../utils/returntype";
import { getCurrentUserEmail, isUserAuthorized } from "../../utils";
import moment from "moment";
import { AUTHORIZATION, ROUTE_PATH } from "../../constants";

interface Props {
  users: User[];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
}

const FACEBOOK = "FACEBOOK";
const APPLE = "APPLE";
const GOOGLE = "GOOGLE";
const CICLOPE = "CICLOPE";

const UserListResults: FC<Props> = ({
  users,
  page,
  setPage,
  limit,
  setLimit,
  ...rest
}) => {
  const [userToDelete, setUserToDelete] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const totalRecord =
    useAppSelector((state) => state.users.data?.totalRecord) ?? 0;

  const handleLimitChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLimit(Number(event.target.value));
    setPage(0);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleClickUser = (userId: number) => {
    navigate(`/${ROUTE_PATH.APP}/${ROUTE_PATH.USER}`, { state: { userId } });
  };

  const handleDeleteUser = (): void => {
    dispatch(deleteUser(userToDelete.toString()));
    setIsModalOpen(false);
  };

  const handleOpenModal = (userId: number): void => {
    setUserToDelete(userId);
    setIsModalOpen(true);
  };

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Ruolo</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Telefono</TableCell>
                  <TableCell>Login</TableCell>
                  <TableCell align="center">Attivo</TableCell>
                  <TableCell align="center">Marketing</TableCell>
                  <TableCell>Data Registrazione</TableCell>
                  <TableCell align="center">Modifica</TableCell>
                  <TableCell align="center">Elimina</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user: User) => (
                  <TableRow hover key={user.id} id={user.id.toString()}>
                    <TableCell>{user.ruoloUtente.ruolo}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.telefono}</TableCell>
                    <TableCell>
                      {user.login === GOOGLE && (
                        <img
                          src="/static/images/logo/Google_logo_100x100.png"
                          alt="Google"
                          style={{ height: "24px", width: "24px" }}
                        />
                      )}
                      {user.login === APPLE && (
                        <img
                          src="/static/images/logo/Apple_logo_100x100.png"
                          alt="Apple"
                          style={{ height: "24px", width: "24px" }}
                        />
                      )}
                      {user.login === FACEBOOK && (
                        <img
                          src="/static/images/logo/Facebook_logo_100x100.png"
                          alt="Facebook"
                          style={{ height: "24px", width: "24px" }}
                        />
                      )}
                      {(user.login === undefined || user.login === CICLOPE) && (
                        <img
                          src="/static/images/logo/Icona_Ciclope_Color_100x100.jpg"
                          alt="logo colorato"
                          style={{ height: "24px", width: "24px" }}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {renderIsActive(user.isActive)}
                    </TableCell>
                    <TableCell align="center">
                      {renderIsActive(user.marketing)}
                    </TableCell>
                    <TableCell>
                      {moment(user.dataRegistrazione).format("DD MMMM YYYY")}
                    </TableCell>
                    <TableCell align="center">
                      {isUserAuthorized(AUTHORIZATION.UT_E) && (
                        <EditIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClickUser(user.id)}
                        />
                      )}
                    </TableCell>
                    <TableCell align="center">
                      {user.email !== getCurrentUserEmail() &&
                        isUserAuthorized(AUTHORIZATION.UT_E) && (
                          <DelIcon
                            style={{ cursor: "pointer" }}
                            onClick={() => handleOpenModal(user.id)}
                          />
                        )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={totalRecord}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage={"Righe per pagina"}
        />
      </Card>
      <AlertDialog
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        dialogTitle={"Elimina Utente"}
        dialogContent={
          "Sei sicuro di eliminare l'utente?\nProcedendo verranno eliminate anche le relative scansioni effettuate dall'utente"
        }
        buttonSuccessTitle={"Si"}
        buttonFailureTitle={"No"}
        buttonSuccessAction={handleDeleteUser}
      />
    </>
  );
};

export default UserListResults;
