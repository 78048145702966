// =============== //
// Actions //
import { MarkerRequestReportResponse } from "./interfaces";

export const GET_MARKER_REQUEST_REPORT_SUCCEEDED =
  "GET_MARKER_REQUEST_REPORT_SUCCEEDED";
export const GET_MARKER_REQUEST_REPORT_FAILED =
  "GET_MARKER_REQUEST_REPORT_FAILED";
export const GET_MARKER_REQUEST_REPORT_RESET =
  "GET_MARKER_REQUEST_REPORT_RESET";
export const GET_MARKER_REQUEST_REPORT = "GET_MARKER_REQUEST_REPORT";

// =============== //
// Action types //
export type GetMarkerRequestReport = {
  type: typeof GET_MARKER_REQUEST_REPORT;
  payload: {
    marker: string;
    cliente: string;
    ambito: string;
    tipologia: string;
    dateFrom: string;
    dateTo: string;
  };
};

type GetMarkerRequestReportSucceeded = {
  type: typeof GET_MARKER_REQUEST_REPORT_SUCCEEDED;
  payload: MarkerRequestReportResponse;
};

type GetMarkerRequestReportFailed = {
  type: typeof GET_MARKER_REQUEST_REPORT_FAILED;
  payload: null;
};

type GetMarkerRequestReportReset = {
  type: typeof GET_MARKER_REQUEST_REPORT_RESET;
  payload: null;
};

// =============== //
// Creators //
export const getMarkerRequestReport = (
  marker: string,
  cliente: string,
  ambito: string,
  tipologia: string,
  dateFrom: string,
  dateTo: string
): GetMarkerRequestReport => ({
  type: GET_MARKER_REQUEST_REPORT,
  payload: {
    marker,
    cliente,
    ambito,
    tipologia,
    dateFrom,
    dateTo,
  },
});

export const getMarkerRequestReportSucceeded = (
  response: MarkerRequestReportResponse
): GetMarkerRequestReportSucceeded => ({
  type: GET_MARKER_REQUEST_REPORT_SUCCEEDED,
  payload: response,
});

export const getMarkerRequestReportFailed =
  (): GetMarkerRequestReportFailed => ({
    type: GET_MARKER_REQUEST_REPORT_FAILED,
    payload: null,
  });

export const getMarkerRequestReportReset = (): GetMarkerRequestReportReset => ({
  type: GET_MARKER_REQUEST_REPORT_RESET,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | GetMarkerRequestReport
  | GetMarkerRequestReportSucceeded
  | GetMarkerRequestReportFailed
  | GetMarkerRequestReportReset;
