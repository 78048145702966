import { Helmet } from "react-helmet";
import { Box, Container, Grid } from "@material-ui/core";
import { FC } from "react";
import ClienteDetails from "../../../components/clienteDetails/ClienteDetails";

const CreateCliente: FC = (): JSX.Element => {
  return (
    <>
      <Helmet>
        <title>Crea Cliente | DxMarker</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={3}>
            <Grid item lg={8} md={6} xs={12}>
              <ClienteDetails />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default CreateCliente;
