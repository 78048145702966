import {
  Action,
  LOGIN_USER,
  LOGIN_USER_FAILED,
  LOGIN_USER_SUCCEEDED,
} from "./actions";

export interface LoginState {
  readonly token: string | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialLoginState: LoginState = {
  token: null,
  isFetching: false,
  isFetched: false,
};

const LoginReducer = (
  state = initialLoginState,
  action: Action
): LoginState => {
  switch (action.type) {
    case LOGIN_USER: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case LOGIN_USER_SUCCEEDED: {
      return { ...state, isFetching: false, isFetched: true };
    }
    case LOGIN_USER_FAILED: {
      return { ...state, isFetching: false, isFetched: false };
    }
    default:
      return state;
  }
};

export default LoginReducer;
