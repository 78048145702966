import { call, put, takeLatest } from "redux-saga/effects";
import {
  CREATE_MARKER, CREATE_MARKER_BULK,
  CreateMarker, CreateMarkerBulk,
  createMarkerFailed,
  createMarkerSucceeded,
} from "./actions";
import {CreateMarkerBulkResponse, CreateMarkerResponse} from "./interfaces";
import {createMarkerApi, createMarkerBulkApi} from "./api";
import { SagaIterator } from "@redux-saga/types";

function* createMarker(action: CreateMarker): SagaIterator {
  try {
    const markerToCreate = action.payload;

    const response: CreateMarkerResponse = yield call(
      createMarkerApi,
      markerToCreate
    );
    const responsePayload = {
      message: response,
      isCreated: true,
    };
    yield put(createMarkerSucceeded(responsePayload));
  } catch (e) {
    console.log("createMarkerSaga error: ", e);
    const responsePayload = {
      message: e.toString(),
      isCreated: false,
    };
    yield put(createMarkerFailed(responsePayload));
  }
}

export default function* createMarkerSaga(): SagaIterator {
  yield takeLatest(CREATE_MARKER, createMarker);
}

function* createMarkerBulk(action: CreateMarkerBulk): SagaIterator {
  try {
    const markerToCreate = action.payload.markerBody;
    const numeroMarkerTotali = action.payload.numeroMarkerTotali;
    console.log("createMarkerBulkSaga");
    const response: CreateMarkerBulkResponse = yield call(
        createMarkerBulkApi,
        markerToCreate,
        numeroMarkerTotali
    );
    const responsePayload = {
      message: response,
      isCreated: true,
    };
    yield put(createMarkerSucceeded(responsePayload));
  } catch (e) {
    console.log("createMarkerBulkSaga error: ", e);
    const responsePayload = {
      message: e.toString(),
      isCreated: false,
    };
    yield put(createMarkerFailed(responsePayload));
  }
}

export function* createMarkerBulkSaga(): SagaIterator {
  yield takeLatest(CREATE_MARKER_BULK, createMarkerBulk);
}
