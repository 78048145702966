import {
  Action,
  GET_ALL_RUOLO_UTENTE,
  GET_ALL_RUOLO_UTENTE_FAILED,
  GET_ALL_RUOLO_UTENTE_SUCCEEDED,
} from "./actions";
import { GetAllRuoloUtenteResponse } from "./interfaces";

export interface RuoloUtenteState {
  readonly data: GetAllRuoloUtenteResponse | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialRuoloUtenteState: RuoloUtenteState = {
  data: null,
  isFetching: false,
  isFetched: false,
};

const RuoloUtenteReducer = (
  state = initialRuoloUtenteState,
  action: Action
): RuoloUtenteState => {
  switch (action.type) {
    case GET_ALL_RUOLO_UTENTE: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case GET_ALL_RUOLO_UTENTE_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case GET_ALL_RUOLO_UTENTE_FAILED: {
      return { ...state, isFetching: false, isFetched: false };
    }
    default:
      return state;
  }
};

export default RuoloUtenteReducer;
