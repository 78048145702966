import { getToken } from "./utils";

export const ENDPOINTS_BASE = process.env.REACT_APP_ENDPOINTS_BASE;

export const ENDPOINT = {
  REGISTER_USER: "/api/v1/login",
  CHANGE_PASSWORD: "/api/v1/change-psw",
  FINDALL_USERS: "/api/v1/utente/all",
  FIND_USERS_WITH_FILTERS: "/api/v1/utente",
  CREATE_USER: "/api/v1/utente",
  UPDATE_USER: "/api/v1/utente",
  DELETE_USER: "/api/v1/utente",
  FINDALL_AMBITO: "/api/v1/ambito/all",
  FIND_AMBITO_BY_NAME: "/api/v1/ambito",
  CREATE_AMBITO: "/api/v1/ambito",
  DELETE_AMBITO: "/api/v1/ambito",
  UPDATE_AMBITO: "/api/v1/ambito",
  FINDALL_MARKER: "/api/v1/marker/all",
  FIND_MARKER_WITH_FILTERS: "/api/v1/marker",
  CREATE_MARKER: "/api/v1/marker",
  CREATE_MARKER_BULK: "/api/v1/marker-bulk",
  UPDATE_MARKER: "/api/v1/marker",
  DISABLE_MARKER: "/api/v1/marker/disable",
  DELETE_MARKER: "/api/v1/marker",
  GET_MARKER_BACKGROUND: "/api/v1/marker/background",
  QR_IMAGE: "/api/v1/marker/qr-image",
  FIND_ALL_TIPO_RISORSA: "/api/v1/tipo-risorsa/all",
  NEXT_MARKER_CODE: "/api/v1/marker/get-next",
  GET_ALL_RUOLO_UTENTE: "/api/v1/ruolo-utente/all",
  GET_RUOLO_UTENTE_DASHBOARD: "/api/v1/ruolo-utente/dashboard",
  GET_ALL_MARKER_REQUEST: "/api/v1/marker-request/all",
  GET_MARKER_REQUEST_WITH_FILTERS: "/api/v1/marker-request",
  GET_ALL_QRCODE_REQUEST: "/api/v1/qrcode-request/all",
  GET_QRCODE_REQUEST_WITH_FILTERS: "/api/v1/qrcode-request",
  GET_QRCODE_REQUEST_REPORT: "/api/v1/qrcode-request/report",
  GET_STATISTICHE_UTENTI_REGISTRATI: "/api/v1/statistiche/utenti-registrati",
  GET_STATISTICHE_QRCODE_REQUEST: "/api/v1/statistiche/qrcode-request",
  GET_STATISTICHE_MARKER: "/api/v1/statistiche/marker",
  GET_STATISTICHE_MARKER_REQUEST: "/api/v1/statistiche/marker-request",
  GET_STATISTICHE_WIDGETS: "/api/v1/statistiche/marker-request",
  GET_USER_REPORT: "/api/v1/utente/report",
  GET_MARKER_REQUEST_REPORT: "/api/v1/marker-request/report",
  GET_ALL_CLIENTE: "/api/v1/cliente/all",
  FIND_CLIENTE_WITH_FILTERS: "/api/v1/cliente",
  CREATE_CLIENTE: "/api/v1/cliente",
  UPDATE_CLIENTE: "/api/v1/cliente",
  DELETE_CLIENTE: "/api/v1/cliente",
};
export const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
};

export const AUTHORIZED_HEADERS = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${getToken()}`,
};

export const EXCEL_REPORT_NAME = {
  USER_LIST: "Lista_Utenti",
  MARKER_REQUEST_LIST: "Lista_Scansioni_Marker",
  QRCODE_REQUEST_LIST: "Lista_Scansioni_QrCode",
};

export enum AUTHORIZATION {
  UT_V = "UT_V",
  UT_E = "UT_E",
  MK_V = "MK_V",
  MK_E = "MK_E",
  QR_V = "QR_V",
  RM_V = "RM_V",
  ST_V = "ST_V",
  RL_V = "RL_V",
  RL_E = "RL_E",
  TR_V = "TR_V",
  CL_V = "CL_V",
  CL_E = "CL_E",
}

export const USER_ROLE = {
  ADMIN: "ADMIN",
  MOBILE: "MOBILE",
};

export const ROUTE_PATH = {
  APP: "app",
  DASHBOARD: "dashboard",
  CLIENTI: "clienti",
  CLIENTE: "cliente",
  MARKER_LIST: "markerList",
  MARKER: "marker",
  MARKER_MASSIVO: "marker_massivo",
  USERS: "users",
  USER: "user",
  MARKER_REQUEST_LIST: "markerRequestList",
  QRCODE_REQUEST_LIST: "qrCodeRequestList",
  NOT_FOUND: "/api/v1/404",
  LOGIN: "login",
  CONFIRMED: "/api/v1/confirmed",
  RESET_PASSWORD: "change-psw",
  CONFIRMED_RESET_PASSWORD: "change-psw-success",
  ERROR_RESET_PASSWORD: "change-psw-error",
  ERROR: "error",
};
