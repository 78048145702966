import * as React from "react";
import { FC } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { Grid, SvgIcon } from "@material-ui/core";
import { Loader } from "react-feather";

interface Props {
  value: number | undefined;
  title: string;
  icon: JSX.Element;
  color: string;
}

const DashboardCard: FC<Props> = ({
  value,
  title,
  icon,
  color,
  ...props
}): JSX.Element => {
  return (
    <Card {...props} sx={{ maxWidth: 345 }}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: "space-between" }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="h6">
              {title.toUpperCase()}
            </Typography>
            <Typography color="textPrimary" variant="h3">
              {value ?? (
                <SvgIcon fontSize="small" color="action">
                  <Loader />
                </SvgIcon>
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: color,
                height: 36,
                width: 36,
              }}
            >
              {icon}
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
export default DashboardCard;
