// =============== //
// Actions //
import { responsePayload } from "./interfaces";

export const DELETE_CLIENTE_SUCCEEDED = "DELETE_CLIENTE_SUCCEEDED";
export const DELETE_CLIENTE_FAILED = "DELETE_CLIENTE_FAILED";
export const DELETE_CLIENTE_RESET = "DELETE_CLIENTE_RESET";
export const DELETE_CLIENTE = "DELETE_CLIENTE";

// =============== //
// Action types //
export type DeleteCliente = {
  type: typeof DELETE_CLIENTE;
  payload: {
    clienteId: number;
  };
};

type DeleteClienteSucceeded = {
  type: typeof DELETE_CLIENTE_SUCCEEDED;
  payload: responsePayload;
};

type DeleteClienteFailed = {
  type: typeof DELETE_CLIENTE_FAILED;
  payload: responsePayload;
};

type DeleteClienteReset = {
  type: typeof DELETE_CLIENTE_RESET;
  payload: null;
};

// =============== //
// Creators //
export const deleteCliente = (clienteId: number): DeleteCliente => ({
  type: DELETE_CLIENTE,
  payload: { clienteId },
});

export const deleteClienteSucceeded = (
  response: responsePayload
): DeleteClienteSucceeded => ({
  type: DELETE_CLIENTE_SUCCEEDED,
  payload: response,
});

export const deleteClienteFailed = (
  response: responsePayload
): DeleteClienteFailed => ({
  type: DELETE_CLIENTE_FAILED,
  payload: response,
});

export const deleteClienteReset = (): DeleteClienteReset => ({
  type: DELETE_CLIENTE_RESET,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | DeleteCliente
  | DeleteClienteSucceeded
  | DeleteClienteFailed
  | DeleteClienteReset;
