import React, { ChangeEvent, FC, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { renderIcon } from "../../utils/returntype";
import { useAppSelector } from "../../hooks";
import { MarkerRequest } from "../../pages/MarkerRequestList/interfaces";
import PortalClickAway from "../portalClickAway/PortalClickAway";
import moment from "moment";
import "moment/locale/it";

interface Props {
  markerRequestList: MarkerRequest[];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  limit: number;
  setLimit: React.Dispatch<React.SetStateAction<number>>;
}

const MarkerRequestListResults: FC<Props> = ({
  markerRequestList,
  page,
  setPage,
  limit,
  setLimit,
  ...rest
}) => {
  const [portalParameters, setPortalParameters] = useState({
    codice: "",
    open: false,
  });

  const totalRecord =
    useAppSelector((state) => state.markerRequest.data?.totalRecord) ?? 0;

  const handleLimitChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLimit(Number(event.target.value));
    setPage(0);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleClickMarker = (markerId: string) => {
    setPortalParameters({ codice: markerId, open: true });
  };

  const handleClickAway = () => {
    setPortalParameters({ codice: "", open: false });
  };

  return (
    <>
      <Card {...rest}>
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Codice Marker</TableCell>
                  <TableCell>Email utente</TableCell>
                  <TableCell>Cliente</TableCell>
                  <TableCell>Ambito</TableCell>
                  <TableCell align="center">Tipo Risorsa</TableCell>
                  <TableCell>Call to Action</TableCell>
                  <TableCell>Data</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {markerRequestList.map((markerRequest: MarkerRequest) => (
                  <TableRow
                    hover
                    key={markerRequest.id}
                    id={markerRequest.id.toString()}
                  >
                    <TableCell
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        handleClickMarker(
                          markerRequest.marker.codice.toString()
                        )
                      }
                    >
                      {markerRequest.marker.codice}
                    </TableCell>
                    <TableCell>{markerRequest.utente.email}</TableCell>
                    <TableCell>{markerRequest.marker.cliente.nome}</TableCell>
                    <TableCell>{markerRequest.marker.ambito}</TableCell>
                    <TableCell align="center">
                      {renderIcon(markerRequest.marker.tipoRisorsa.codice)}
                    </TableCell>
                    <TableCell
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                      onClick={() =>
                        window.open(markerRequest.marker.azione, "_blank")
                      }
                    >
                      {markerRequest.marker.azione}
                    </TableCell>
                    <TableCell>
                      {moment(markerRequest.dataRequest).format(
                        "D MMMM YYYY, H:mm:ss"
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
        <TablePagination
          component="div"
          count={totalRecord}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage={"Righe per pagina"}
        />
      </Card>
      {portalParameters.codice && portalParameters.open && (
        <PortalClickAway
          codice={portalParameters.codice}
          open={portalParameters.open}
          handleClickAway={handleClickAway}
        />
      )}
    </>
  );
};

export default MarkerRequestListResults;
