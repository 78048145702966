import { GetAllRuoloUtenteResponse } from "./interfaces";

export const GET_ALL_RUOLO_UTENTE = "GET_ALL_RUOLO_UTENTE";
export const GET_ALL_RUOLO_UTENTE_DASHBOARD = "GET_ALL_RUOLO_UTENTE_DASHBOARD";
export const GET_ALL_RUOLO_UTENTE_SUCCEEDED = "GET_ALL_RUOLO_UTENTE_SUCCEEDED";
export const GET_ALL_RUOLO_UTENTE_FAILED = "GET_ALL_RUOLO_UTENTE_FAILED";

// =============== //
// Action types //

export type GetAllRuoloUtente = {
  type: typeof GET_ALL_RUOLO_UTENTE;
  payload: null;
};

export type GetAllRuoloUtenteDashboard = {
  type: typeof GET_ALL_RUOLO_UTENTE_DASHBOARD;
  payload: null;
};

type GetAllRuoloUtenteSucceeded = {
  type: typeof GET_ALL_RUOLO_UTENTE_SUCCEEDED;
  payload: GetAllRuoloUtenteResponse;
};

type GetAllRuoloUtenteFailed = {
  type: typeof GET_ALL_RUOLO_UTENTE_FAILED;
  payload: null;
};

// =============== //
// Creators //

export const getAllRuoloUtente = (): GetAllRuoloUtente => ({
  type: GET_ALL_RUOLO_UTENTE,
  payload: null,
});

export const getAllRuoloUtenteDashboard = (): GetAllRuoloUtenteDashboard => ({
  type: GET_ALL_RUOLO_UTENTE_DASHBOARD,
  payload: null,
});

export const getAllRuoloUtenteSucceeded = (
  response: GetAllRuoloUtenteResponse
): GetAllRuoloUtenteSucceeded => ({
  type: GET_ALL_RUOLO_UTENTE_SUCCEEDED,
  payload: response,
});

export const getAllRuoloUtenteFailed = (): GetAllRuoloUtenteFailed => ({
  type: GET_ALL_RUOLO_UTENTE_FAILED,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | GetAllRuoloUtente
  | GetAllRuoloUtenteSucceeded
  | GetAllRuoloUtenteFailed;
