import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_ALL_MARKER_REQUEST,
  GET_MARKER_REQUEST_WITH_FILTERS,
  GetAllMarkerRequest,
  getAllMarkerRequestFailed,
  getAllMarkerRequestSucceeded,
  GetMarkerRequestWithFilters,
} from "./actions";
import { GetAllMarkerRequestResponse } from "./interfaces";
import {
  getAllMarkerRequestApi,
  getAllMarkerRequestWithFiltersApi,
} from "./api";
import { SagaIterator } from "@redux-saga/types";

function* getAllMarkerRequest(action: GetAllMarkerRequest): SagaIterator {
  try {
    const { offset, limit } = action.payload;
    const response: GetAllMarkerRequestResponse = yield call(
      getAllMarkerRequestApi,
      offset,
      limit
    );
    yield put(getAllMarkerRequestSucceeded(response));
  } catch (e) {
    yield put(getAllMarkerRequestFailed());
  }
}

function* getMarkerRequestWithFilters(
  action: GetMarkerRequestWithFilters
): SagaIterator {
  try {
    const {
      idCliente,
      idTipologia,
      ambito,
      marker,
      dateFrom,
      dateTo,
      offset,
      limit,
    } = action.payload;
    const response: GetAllMarkerRequestResponse = yield call(
      getAllMarkerRequestWithFiltersApi,
      idCliente,
      idTipologia,
      ambito,
      marker,
      dateFrom,
      dateTo,
      offset,
      limit
    );
    yield put(getAllMarkerRequestSucceeded(response));
  } catch (e) {
    yield put(getAllMarkerRequestFailed());
  }
}

export default function* markerRequestSaga(): SagaIterator {
  yield takeLatest(GET_ALL_MARKER_REQUEST, getAllMarkerRequest);
  yield takeLatest(
    GET_MARKER_REQUEST_WITH_FILTERS,
    getMarkerRequestWithFilters
  );
}
