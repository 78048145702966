// =============== //
// Actions //

import { QrImageResponse } from "./interfaces";

export const SEARCH_MARKER_CODE_SUCCEEDED = "SEARCH_MARKER_CODE_SUCCEEDED";
export const SEARCH_MARKER_CODE_FAILED = "SEARCH_MARKER_CODE_FAILED";
export const SEARCH_MARKER_CODE_RESET = "SEARCH_MARKER_CODE_RESET";
export const SEARCH_MARKER_CODE = "SEARCH_MARKER_CODE";

// =============== //
// Action types //
export type SearchMarkerCode = {
  type: typeof SEARCH_MARKER_CODE;
  payload: {
    markerCode: string;
  };
};

type SearchMarkerCodeSucceeded = {
  type: typeof SEARCH_MARKER_CODE_SUCCEEDED;
  payload: QrImageResponse;
};

type SearchMarkerCodeFailed = {
  type: typeof SEARCH_MARKER_CODE_FAILED;
  payload: null;
};

type SearchMarkerCodeReset = {
  type: typeof SEARCH_MARKER_CODE_RESET;
  payload: null;
};

// =============== //
// Creators //
export const searchMarkerCode = (markerCode: string): SearchMarkerCode => ({
  type: SEARCH_MARKER_CODE,
  payload: {
    markerCode,
  },
});

export const searchMarkerCodeSucceeded = (
  response: QrImageResponse
): SearchMarkerCodeSucceeded => ({
  type: SEARCH_MARKER_CODE_SUCCEEDED,
  payload: response,
});

export const searchMarkerCodeFailed = (): SearchMarkerCodeFailed => ({
  type: SEARCH_MARKER_CODE_FAILED,
  payload: null,
});

export const searchMarkerCodeReset = (): SearchMarkerCodeReset => ({
  type: SEARCH_MARKER_CODE_RESET,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | SearchMarkerCode
  | SearchMarkerCodeSucceeded
  | SearchMarkerCodeFailed
  | SearchMarkerCodeReset;
