import { createStore, Store } from "redux";
import { MyAction } from "shared";

import getMiddlewares, { sagaMiddleware } from "../middlewares";
import getRootReducer, { RootState } from "../reducers/root-reducer";
import rootSaga from "../sagas/root-saga";

function configureStore(): Store<RootState, MyAction> {
  const middlewares = getMiddlewares();
  const rootReducer = getRootReducer();
  const store = createStore(rootReducer, middlewares);

  // Hot reloading
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const MODULE = module as any;
  if (process.env.NODE_ENV !== "production" && MODULE.hot) {
    MODULE.hot.accept("../reducers/root-reducer", () =>
      store.replaceReducer(rootReducer)
    );
  }

  sagaMiddleware.run(rootSaga);

  return store;
}

export default configureStore;
