import { Helmet } from "react-helmet";
import { Box, Container } from "@material-ui/core";
import React, {
  ChangeEvent,
  FC,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import MarkerRequestListResults from "../../components/markerRequest/MarkerRequestListResults";
import MarkerRequestListToolbar from "../../components/markerRequest/MarkerRequestListToolbar";
import { getAllMarkerRequest, getMarkerRequestWithFilters } from "./actions";
import { MarkerRequest } from "./interfaces";
import { Cliente } from "../ClienteList/interfaces";
import { TipoRisorsa } from "../../common/tipoRisorsa/interfaces";
import { getAllTipoRisorsa } from "../../common/tipoRisorsa/actions";
import { getAllClienteNoPag } from "../../common/cliente/actions";
import { DateRange } from "@material-ui/lab/DateRangePicker/RangeTypes";
import { dateMakerForFilters } from "../../utils";
import { getAllMarkers } from "../Dashboard/statistics/actions/markerActions";
import { getMarkerRequestReport } from "./Report/actions";
import { findMarkerWithFilters } from "../MarkerList/actions";
import CustomLoader from "../../components/CustomLoader/CustomLoader";

export interface MarkerRequestFilters {
  cliente: { id: string; nome: string; codMarkers: Array<number> };
  tipologia: { id: string; codice: string };
  ambito: string;
  marker: string;
}

const MarkerRequestList: FC = () => {
  const dispatch = useAppDispatch();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const [markerRequestFilters, setMarkerRequestFilters] =
    useState<MarkerRequestFilters>({
      cliente: { id: "", nome: "", codMarkers: [] },
      tipologia: { id: "", codice: "" },
      ambito: "",
      marker: "",
    });

  const [dateFilterValue, setDateFilterValue] = useState<DateRange<Date>>([
    null,
    null,
  ]);

  const areMarkerRequestFiltersFilled = (): boolean => {
    const { cliente, tipologia, ambito, marker } = markerRequestFilters;
    return (
      !!cliente.id ||
      !!tipologia.id ||
      !!tipologia.codice ||
      !!ambito ||
      !!marker ||
      !!dateFilterValue[0] ||
      !!dateFilterValue[1]
    );
  };

  const markerRequestList: MarkerRequest[] =
    useAppSelector((state) => state.markerRequest.data?.markerRequests) ?? [];

  const isMarkerRequestFetching = useAppSelector(
    (state) => state.markerRequest.isFetching
  );

  const isMarkerRequestFetched = useAppSelector(
    (state) => state.markerRequest.isFetched
  );

  useEffect(() => {
    dispatch(getAllTipoRisorsa());
    dispatch(getAllClienteNoPag());
    dispatch(getAllMarkers());
  }, []);

  useEffect(() => {
    setPage(0);
    setLimit(10);
    const offset = page * limit;

    if (areMarkerRequestFiltersFilled()) {
      const { cliente, tipologia, ambito, marker } = markerRequestFilters;
      const dateFrom = dateMakerForFilters(dateFilterValue[0]);
      const dateTo = dateMakerForFilters(dateFilterValue[1]);
      dispatch(
        getMarkerRequestWithFilters(
          cliente.id,
          tipologia.id,
          ambito,
          marker,
          dateFrom,
          dateTo,
          offset,
          limit
        )
      );
    } else {
      dispatch(getAllMarkerRequest(offset, limit));
    }
  }, [
    markerRequestFilters.ambito,
    markerRequestFilters.cliente.id,
    markerRequestFilters.tipologia.id,
    markerRequestFilters.marker,
    dateFilterValue[0],
    dateFilterValue[1],
  ]);

  useEffect(() => {
    const offset = page * limit;
    if (areMarkerRequestFiltersFilled()) {
      const { cliente, tipologia, ambito, marker } = markerRequestFilters;
      const dateFrom = dateMakerForFilters(dateFilterValue[0]);
      const dateTo = dateMakerForFilters(dateFilterValue[1]);
      dispatch(
        getMarkerRequestWithFilters(
          cliente.id,
          tipologia.id,
          ambito,
          marker,
          dateFrom,
          dateTo,
          offset,
          limit
        )
      );
    } else {
      dispatch(getAllMarkerRequest(offset, limit));
    }
  }, [limit, page]);

  const handleChangeAutocompleteClienteFilter = (
    event: SyntheticEvent,
    selectedCliente: Cliente | null
  ) => {
    setMarkerRequestFilters((prevState) => {
      return {
        ...prevState,
        cliente: {
          id: selectedCliente?.id?.toString() ?? "",
          nome: selectedCliente?.nome ?? "",
          codMarkers: selectedCliente?.codMarkers ?? [],
        },
        marker: selectedCliente?.id ? prevState.marker : "",
        ambito: selectedCliente?.id ? prevState.ambito : "",
      };
    });
  };

  const handleChangeTextClienteFilter = (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => {
    setMarkerRequestFilters((prevState) => {
      return {
        ...prevState,
        cliente: { ...prevState.cliente, nome: target.value },
      };
    });
  };

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setMarkerRequestFilters((prevState) => {
      return {
        ...prevState,
        [event.target.name]: event.target.value,
      };
    });
  };

  const handleChangeAutocompleteTipoRisorsaFilter = (
    event: SyntheticEvent,
    selectedTipoRisorsa: TipoRisorsa | null
  ) => {
    if (selectedTipoRisorsa) {
      setMarkerRequestFilters((prevState) => {
        return {
          ...prevState,
          tipologia: {
            id: selectedTipoRisorsa.id.toString(),
            codice: selectedTipoRisorsa.codice,
          },
        };
      });
    } else {
      setMarkerRequestFilters((prevState) => {
        return {
          ...prevState,
          tipologia: {
            id: "",
            codice: "",
          },
        };
      });
    }
  };

  const handleChangeTextTipoRisorsaFilter = (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => {
    setMarkerRequestFilters({
      ...markerRequestFilters,
      tipologia: { ...markerRequestFilters.tipologia, codice: target.value },
    });
  };

  const handleReportClick = (): void => {
    const { cliente, tipologia, ambito, marker } = markerRequestFilters;
    const dateFrom = dateMakerForFilters(dateFilterValue[0]);
    const dateTo = dateMakerForFilters(dateFilterValue[1]);
    dispatch(
      getMarkerRequestReport(
        marker,
        cliente.id,
        ambito,
        tipologia.id,
        dateFrom,
        dateTo
      )
    );
  };

  const handleChangeAutocompleteMarkerFilter = (
    event: SyntheticEvent,
    selectedMarker: string | null
  ) => {
    if (selectedMarker) {
      // to find all the ambito connected to that specific marker
      dispatch(
        findMarkerWithFilters(
          markerRequestFilters.cliente.id.toString(),
          selectedMarker,
          "",
          "",
          true,
          0,
          50
        )
      );
    }
    setMarkerRequestFilters((prevState) => {
      return {
        ...prevState,
        marker: selectedMarker ?? "",
        ambito: selectedMarker ? "" : prevState.ambito,
      };
    });
  };

  const handleChangeAutocompleteAmbitoFilter = (
    event: SyntheticEvent,
    selectedAmbito: string | null
  ) => {
    setMarkerRequestFilters((prevState) => {
      return {
        ...prevState,
        ambito: selectedAmbito ?? "",
      };
    });
  };

  return (
    <>
      <Helmet>
        <title>Marker Request | DxMarker</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <MarkerRequestListToolbar
            markerRequestFilters={markerRequestFilters}
            handleChangeAutocompleteClienteFilter={
              handleChangeAutocompleteClienteFilter
            }
            handleChangeTextClienteFilter={handleChangeTextClienteFilter}
            handleChangeFilter={handleChangeFilter}
            handleChangeAutocompleteTipoRisorsaFilter={
              handleChangeAutocompleteTipoRisorsaFilter
            }
            handleChangeTextTipoRisorsaFilter={
              handleChangeTextTipoRisorsaFilter
            }
            dateFilterValue={dateFilterValue}
            setDateFilterValue={setDateFilterValue}
            handleReportClick={handleReportClick}
            handleChangeAutocompleteMarkerFilter={
              handleChangeAutocompleteMarkerFilter
            }
            handleChangeAutocompleteAmbitoFilter={
              handleChangeAutocompleteAmbitoFilter
            }
          />
          <Box sx={{ pt: 3 }}>
            <CustomLoader visible={isMarkerRequestFetching} />
            {isMarkerRequestFetched && (
              <MarkerRequestListResults
                markerRequestList={markerRequestList}
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
              />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default MarkerRequestList;
