// =============== //
// Actions //
import { responsePayload } from "./interfaces";
import { MarkerBody } from "../../../components/markerDetails/MarkerDetails";

export const UPDATE_MARKER_SUCCEEDED = "UPDATE_MARKER_SUCCEEDED";
export const UPDATE_MARKER_FAILED = "UPDATE_MARKER_FAILED";
export const UPDATE_MARKER_RESET = "UPDATE_MARKER_RESET";
export const UPDATE_MARKER = "UPDATE_MARKER";

// =============== //
// Action types //

export type UpdateMarker = {
  type: typeof UPDATE_MARKER;
  payload: MarkerBody;
};

type UpdateMarkerSucceeded = {
  type: typeof UPDATE_MARKER_SUCCEEDED;
  payload: responsePayload;
};

type UpdateMarkerFailed = {
  type: typeof UPDATE_MARKER_FAILED;
  payload: responsePayload;
};

type UpdateMarkerReset = {
  type: typeof UPDATE_MARKER_RESET;
  payload: null;
};

// =============== //
// Creators //
export const updateMarker = (markerToUpdate: MarkerBody): UpdateMarker => ({
  type: UPDATE_MARKER,
  payload: markerToUpdate,
});

export const updateMarkerSucceeded = (
  response: responsePayload
): UpdateMarkerSucceeded => ({
  type: UPDATE_MARKER_SUCCEEDED,
  payload: response,
});

export const updateMarkerFailed = (
  response: responsePayload
): UpdateMarkerFailed => ({
  type: UPDATE_MARKER_FAILED,
  payload: response,
});

export const updateMarkerReset = (): UpdateMarkerReset => ({
  type: UPDATE_MARKER_RESET,
  payload: null,
});

// =============== //
// Exports //
export type Action =
  | UpdateMarker
  | UpdateMarkerSucceeded
  | UpdateMarkerFailed
  | UpdateMarkerReset;
