import { Helmet } from "react-helmet";
import { Box, Container } from "@material-ui/core";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { findClienteWithFilters, getAllCliente } from "./actions";
import { Cliente } from "./interfaces";
import ClienteListResults from "../../components/cliente/ClienteListResults";
import ClienteListToolbar from "../../components/cliente/ClienteListToolbar";
import { getAllMarkers } from "../Dashboard/statistics/actions/markerActions";
import CustomLoader from "../../components/CustomLoader/CustomLoader";

export interface ClienteFilters {
  nome: string;
  codiceMarker: string;
}

const ClienteList: FC = () => {
  const dispatch = useAppDispatch();

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const [clienteFilters, setClienteFilters] = useState<ClienteFilters>({
    nome: "",
    codiceMarker: "",
  });

  const areClienteFiltersFilled = (): boolean => {
    const { nome, codiceMarker } = clienteFilters;
    return !!nome || !!codiceMarker;
  };

  const clienteList: Cliente[] =
    useAppSelector((state) => state.cliente.data?.clienti) ?? [];

  const isClienteDeleted = useAppSelector(
    (state) => state.deleteCliente.data?.isDeleted
  );

  const isClienteUpdated = useAppSelector(
    (state) => state.updateCliente.data?.isUpdated
  );

  useEffect(() => {
    dispatch(getAllMarkers());
  }, []);

  useEffect(() => {
    setPage(0);
    setLimit(10);
    const offset = page * limit;
    if (areClienteFiltersFilled()) {
      const { nome, codiceMarker } = clienteFilters;
      dispatch(findClienteWithFilters(nome, codiceMarker, offset, limit));
    } else {
      dispatch(getAllCliente(offset, limit));
    }
  }, [
    clienteFilters.nome.length,
    clienteFilters.codiceMarker.length,
    isClienteDeleted,
    isClienteUpdated,
  ]);

  useEffect(() => {
    const offset = page * limit;
    if (areClienteFiltersFilled()) {
      const { nome, codiceMarker } = clienteFilters;
      dispatch(findClienteWithFilters(nome, codiceMarker, offset, limit));
    } else {
      dispatch(getAllCliente(offset, limit));
    }
  }, [page, limit]);

  const handleChangeFilter = (event: ChangeEvent<HTMLInputElement>) => {
    setClienteFilters({
      ...clienteFilters,
      [event.target.name]: event.target.value,
    });
  };

  const isClienteFetched = useAppSelector((state) => state.cliente.isFetched);
  const isClienteFetching = useAppSelector((state) => state.cliente.isFetching);

  return (
    <>
      <Helmet>
        <title>Clienti | DxMarker</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <ClienteListToolbar handleChangeFilter={handleChangeFilter} />
          <Box sx={{ pt: 3 }}>
            <CustomLoader visible={isClienteFetching} />
            {isClienteFetched && (
              <ClienteListResults
                clienteList={clienteList}
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
              />
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ClienteList;
