import { AUTHORIZED_HEADERS, ENDPOINT, ENDPOINTS_BASE } from "../../constants";
import { FindAllMarkerResponse, GetQrImageResponse } from "./interfaces";

export async function fetchAllMarker(
  offset: number,
  limit: number
): Promise<FindAllMarkerResponse> {
  return fetch(
    `${ENDPOINTS_BASE}${ENDPOINT.FINDALL_MARKER}?offset=${offset}&limit=${limit}`,
    {
      method: "GET",
      headers: AUTHORIZED_HEADERS,
    }
  ).then((response) => response.json());
}

export async function fetchMarkerWithFiltersApi(
  idCliente: string,
  codiceMarker: string,
  idTipologia: string,
  ambito: string,
  isActive: boolean | null,
  offset: number,
  limit: number
): Promise<FindAllMarkerResponse> {
  return fetch(
    `${ENDPOINTS_BASE}${
      ENDPOINT.FIND_MARKER_WITH_FILTERS
    }?offset=${offset}&limit=${limit}&ambito=${ambito}&codiceMarker=${codiceMarker}&idTipologia=${idTipologia}&idCliente=${idCliente}&active=${
      isActive !== null ? isActive : ""
    }`,
    {
      method: "GET",
      headers: AUTHORIZED_HEADERS,
    }
  ).then((response) => response.json());
}

export async function getQrImageApi(
  marker: number
): Promise<GetQrImageResponse> {
  return fetch(`${ENDPOINTS_BASE}${ENDPOINT.QR_IMAGE}?codMarker=${marker}`, {
    method: "GET",
    headers: AUTHORIZED_HEADERS,
  }).then((response) => response.json());
}
