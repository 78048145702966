import { AUTHORIZED_HEADERS, ENDPOINT, ENDPOINTS_BASE } from "../../constants";
import { FindAllTipoRisorsaResponse } from "./interfaces";

export async function fetchAllTipoRisorsa(
  offset: number,
  limit: number
): Promise<FindAllTipoRisorsaResponse> {
  return fetch(
    `${ENDPOINTS_BASE}${ENDPOINT.FIND_ALL_TIPO_RISORSA}?offset=${offset}&limit=${limit}`,
    {
      method: "GET",
      headers: AUTHORIZED_HEADERS,
    }
  ).then((response) => response.json());
}
