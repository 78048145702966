import { FC } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { AppBar, Box, Hidden, IconButton, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import InputIcon from "@material-ui/icons/Input";
import Logo from "./Logo";
import { cleanToken } from "../utils";
import { ROUTE_PATH } from "../constants";

interface Props {
  onMobileNavOpen: () => void;
}

const DashboardNavbar: FC<Props> = ({ onMobileNavOpen, ...rest }) => {
  const navigate = useNavigate();

  const handleLogout = (): void => {
    cleanToken();
    navigate(`/${ROUTE_PATH.LOGIN}`, { replace: true });
  };

  return (
    <AppBar elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden lgDown>
          <IconButton color="inherit" onClick={handleLogout} title="Logout">
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardNavbar;
