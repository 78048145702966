import { Helmet } from "react-helmet";
import { Box, Container, Grid, Hidden } from "@material-ui/core";
import TemporalGraph from "../../components/dashboard/TemporalGraph";
import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { getAllStatistiche } from "./statistics/actions/actions";
import DashboardCard from "../../components/dashboard/DashboardCard";
import PeopleIcon from "@material-ui/icons/PeopleOutlined";
import CropFreeIcon from "@material-ui/icons/CropFree";
import { green, orange, purple, red } from "@material-ui/core/colors";
import InsertChartIcon from "@material-ui/icons/InsertChartOutlined";
import TipiRisorseCakeGraph from "../../components/dashboard/TipiRisorseCakeGraph";
import { getWidgetsData } from "./statistics/actions/widgetsActions";

export enum WIDGETS_INTERVAL {
  WEEKLY = 7,
  MONTHLY = 31,
  YEARLY = 365,
}

const Dashboard: FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getAllStatistiche());
    dispatch(getWidgetsData(WIDGETS_INTERVAL.WEEKLY));
  }, []);

  const statisticheAll = useAppSelector((state) => state.statisticheAll);
  const { widgets: widgetsData } = statisticheAll;

  return (
    <>
      <Helmet>
        <title>Dashboard | DxMarker</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 3,
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={2} columns={10}>
            <Grid item lg={2} sm={5} md={4} xl={2} xs={10}>
              <DashboardCard
                value={statisticheAll.clientiTotali.data?.totalClients}
                title={"Clienti Totali"}
                icon={<InsertChartIcon />}
                color={purple[600]}
              />
            </Grid>
            <Grid item lg={2} sm={5} md={4} xl={2} xs={10}>
              <DashboardCard
                value={statisticheAll.utentiRegistrati.data?.totalUsers}
                title={"Utenti Totali"}
                icon={<PeopleIcon />}
                color={green[600]}
              />
            </Grid>
            <Grid item lg={2} sm={5} md={4} xl={2} xs={10}>
              <DashboardCard
                value={statisticheAll.markerTotali.data?.totalMarkers}
                title={"Marker Totali"}
                icon={<InsertChartIcon />}
                color={orange[600]}
              />
            </Grid>

            <Grid item lg={2} sm={5} md={4} xl={2} xs={10}>
              <DashboardCard
                value={statisticheAll.markerRequest.data?.totalMarkerRequests}
                title={"Scansioni Marker"}
                icon={<InsertChartIcon />}
                color={purple[600]}
              />
            </Grid>
            <Grid item lg={2} sm={5} md={4} xl={2} xs={10}>
              <DashboardCard
                value={statisticheAll.qrcodeRequest.data?.totalQrcodeRequests}
                title={"Scansioni Qr Code"}
                icon={<CropFreeIcon />}
                color={red[600]}
              />
            </Grid>
            <Hidden lgUp>
              <Grid item lg={10} sm={5} md={4} xl={2} xs={10} />
            </Hidden>
            {widgetsData.data ? (
              <>
                <Grid item lg={5} md={5} xl={5} xs={10}>
                  <TemporalGraph widgetsData={widgetsData.data} />
                </Grid>
                <Grid item lg={5} md={5} xl={5} xs={10}>
                  <TipiRisorseCakeGraph
                    sx={{ height: "100%" }}
                    widgetsData={widgetsData.data}
                  />
                </Grid>
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
