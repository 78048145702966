import {
  AUTHORIZED_HEADERS,
  ENDPOINT,
  ENDPOINTS_BASE,
} from "../../../constants";
import { MarkerBody } from "../../../components/markerDetails/MarkerDetails";

export async function updateMarkerApi(
  markerToUpdate: MarkerBody
): Promise<string> {
  return fetch(`${ENDPOINTS_BASE}${ENDPOINT.UPDATE_MARKER}`, {
    method: "PUT",
    headers: AUTHORIZED_HEADERS,
    body: JSON.stringify(markerToUpdate),
  }).then(async (response) => {
    if (response.ok) {
      return response.text();
    }
    throw new Error(await response.text());
  });
}

export async function disableMarkerApi(markerId: string): Promise<string> {
  return fetch(
    `${ENDPOINTS_BASE}${ENDPOINT.DISABLE_MARKER}?markerId=${markerId}`,
    {
      method: "PUT",
      headers: AUTHORIZED_HEADERS,
    }
  ).then(async (response) => {
    if (response.ok) {
      return response.text();
    }
    throw new Error(await response.text());
  });
}
