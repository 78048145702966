import { AUTHORIZED_HEADERS, ENDPOINT, ENDPOINTS_BASE } from "../../constants";
import { FindAllUserResponse } from "./interfaces";

export async function fetchAllUsers(
  offset: number,
  limit: number
): Promise<FindAllUserResponse> {
  return fetch(
    `${ENDPOINTS_BASE}${ENDPOINT.FINDALL_USERS}?offset=${offset}&limit=${limit}`,
    {
      method: "GET",
      headers: AUTHORIZED_HEADERS,
    }
  ).then((response) => response.json());
}

export async function fetchUsersWithFiltersApi(
  email: string,
  ruoloId: string,
  ambito: string,
  idCliente: string,
  codiceMarker: string,
  dateFrom: string,
  dateTo: string,
  offset: number,
  limit: number
): Promise<FindAllUserResponse> {
  return fetch(
    `${ENDPOINTS_BASE}${ENDPOINT.FIND_USERS_WITH_FILTERS}?email=${email}&ruoloId=${ruoloId}&ambito=${ambito}&idCliente=${idCliente}&codiceMarker=${codiceMarker}&dateFrom=${dateFrom}&dateTo=${dateTo}&offset=${offset}&limit=${limit}`,
    {
      method: "GET",
      headers: AUTHORIZED_HEADERS,
    }
  ).then((response) => response.json());
}
