import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { TransitionProps } from "@material-ui/core/transitions";
import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { createClienteReset } from "../../pages/ClienteCRUD/CreateCliente/actions";
import { deleteClienteReset } from "../../pages/ClienteCRUD/DeleteCliente/actions";
import { updateClienteReset } from "../../pages/ClienteCRUD/UpdateCliente/actions";
import { createMarkerReset } from "../../pages/MarkerCRUD/CreateMarker/actions";
import { deleteMarkerReset } from "../../pages/MarkerCRUD/DeleteMarker/actions";
import { updateMarkerReset } from "../../pages/MarkerCRUD/UpdateMarker/actions";
import { createUserReset } from "../../pages/UserCRUD/CreateUser/actions";
import { deleteUserReset } from "../../pages/UserCRUD/DeleteUser/actions";
import { updateUserReset } from "../../pages/UserCRUD/UpdateUser/actions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children?: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide: FC = (): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const handleClose = () => setOpen(false);

  const dispatch = useAppDispatch();

  //========UTENTE========//
  const isDeletedUser =
    useAppSelector((state) => state.deleteUser.data?.isDeleted) ?? null;

  const isCreatedUser =
    useAppSelector((state) => state.createUser.data?.isCreated) ?? null;

  const isUpdatedUser =
    useAppSelector((state) => state.updateUser.data?.isUpdated) ?? null;

  const getDeleteUserMessage =
    useAppSelector((state) => state.deleteUser.data?.message) ?? "";

  const getCreateUserMessage =
    useAppSelector((state) => state.createUser.data?.message) ?? "";

  const getUpdateUserMessage =
    useAppSelector((state) => state.updateUser.data?.message) ?? "";

  useEffect(() => {
    if (isDeletedUser !== null && isDeletedUser === false) {
      setMessage(getDeleteUserMessage);
      setOpen(true);
    }
    return () => {
      dispatch(deleteUserReset());
    };
  }, [isDeletedUser]);

  useEffect(() => {
    if (isCreatedUser !== null && isCreatedUser === false) {
      setMessage(getCreateUserMessage);
      setOpen(true);
    }
    return () => {
      dispatch(createUserReset());
    };
  }, [isCreatedUser]);

  useEffect(() => {
    if (isUpdatedUser !== null && isUpdatedUser === false) {
      setMessage(getUpdateUserMessage);
      setOpen(true);
    }
    return () => {
      dispatch(updateUserReset());
    };
  }, [isUpdatedUser]);

  //========CLIENTE========//
  const isDeletedCliente =
    useAppSelector((state) => state.deleteCliente.data?.isDeleted) ?? null;

  const isCreatedCliente =
    useAppSelector((state) => state.createCliente.data?.isCreated) ?? null;

  const isUpdatedCliente =
    useAppSelector((state) => state.updateCliente.data?.isUpdated) ?? null;

  const getDeleteClienteMessage =
    useAppSelector((state) => state.deleteCliente.data?.message) ?? "";

  const getCreateClienteMessage =
    useAppSelector((state) => state.createCliente.data?.message) ?? "";

  const getUpdateClienteMessage =
    useAppSelector((state) => state.updateCliente.data?.message) ?? "";

  useEffect(() => {
    if (isDeletedCliente !== null && isDeletedCliente === false) {
      setMessage(getDeleteClienteMessage);
      setOpen(true);
    }
    return () => {
      dispatch(deleteClienteReset());
    };
  }, [isDeletedCliente]);

  useEffect(() => {
    if (isCreatedCliente !== null && isCreatedCliente === false) {
      setMessage(getCreateClienteMessage);
      setOpen(true);
    }
    return () => {
      dispatch(createClienteReset());
    };
  }, [isCreatedCliente]);

  useEffect(() => {
    if (isUpdatedCliente !== null && isUpdatedCliente === false) {
      setMessage(getUpdateClienteMessage);
      setOpen(true);
    }
    return () => {
      dispatch(updateClienteReset());
    };
  }, [isUpdatedCliente]);

  //========MARKER========//
  const isDeletedMarker =
    useAppSelector((state) => state.deleteMarker.data?.isDeleted) ?? null;

  const isCreatedMarker =
    useAppSelector((state) => state.createMarker.data?.isCreated) ?? null;

  const isUpdatedMarker =
    useAppSelector((state) => state.updateMarker.data?.isUpdated) ?? null;

  const getCreateMarkerMessage =
    useAppSelector((state) => state.createMarker.data?.message) ?? "";

  const getMarkerMessage =
    useAppSelector((state) => state.deleteMarker.data?.message) ?? "";

  const getUpdateMarkerMessage =
    useAppSelector((state) => state.updateMarker.data?.message) ?? "";

  useEffect(() => {
    if (isDeletedMarker !== null && isDeletedMarker === false) {
      setMessage(getMarkerMessage + ".");
      setOpen(true);
    }
    return () => {
      dispatch(deleteMarkerReset());
    };
  }, [isDeletedMarker]);

  useEffect(() => {
    if (isCreatedMarker !== null && isCreatedMarker === false) {
      setMessage(getCreateMarkerMessage);
      setOpen(true);
    }
    return () => {
      dispatch(createMarkerReset());
    };
  }, [isCreatedMarker]);

  useEffect(() => {
    if (isUpdatedMarker !== null && isUpdatedMarker === false) {
      setMessage(getUpdateMarkerMessage);
      setOpen(true);
    }
    return () => {
      dispatch(updateMarkerReset());
    };
  }, [isUpdatedMarker]);

  return (
    <div>
      {open && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">Errore</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {message}
              {message.search("MarkerRequest") > 0 && (
                <p>Disabilita il marker.</p>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Chiudi</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};
export default AlertDialogSlide;
