import {
  Action,
  GET_QRCODE_REQUEST_REPORT,
  GET_QRCODE_REQUEST_REPORT_FAILED,
  GET_QRCODE_REQUEST_REPORT_SUCCEEDED,
  GET_QRCODE_REQUEST_REPORT_RESET,
} from "./actions";
import { QrCodeRequestReportResponse } from "./interfaces";

export interface QrCodeRequestReportState {
  readonly data: QrCodeRequestReportResponse | null;
  readonly isFetching: boolean;
  readonly isFetched: boolean;
}

const initialQrCodeRequestsState: QrCodeRequestReportState = {
  data: null,
  isFetching: false,
  isFetched: false,
};

const QrCodeRequestReportReducer = (
  state = initialQrCodeRequestsState,
  action: Action
): QrCodeRequestReportState => {
  switch (action.type) {
    case GET_QRCODE_REQUEST_REPORT: {
      return { ...state, isFetching: true, isFetched: false };
    }
    case GET_QRCODE_REQUEST_REPORT_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isFetched: true,
      };
    }
    case GET_QRCODE_REQUEST_REPORT_FAILED: {
      return { ...state, isFetching: false, isFetched: false };
    }
    case GET_QRCODE_REQUEST_REPORT_RESET: {
      return initialQrCodeRequestsState;
    }
    default:
      return state;
  }
};

export default QrCodeRequestReportReducer;
