import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
} from "@material-ui/core";
import { Search as SearchIcon } from "react-feather";
import { useNavigate } from "react-router-dom";
import React, { ChangeEvent, FC, SyntheticEvent, useEffect } from "react";
import {
  GetAllRuoloUtenteResponse,
  RuoloUtente,
} from "../../common/ruoloUtente/interfaces";
import { Autocomplete } from "@material-ui/core";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { UserFilters } from "../../pages/UserList/UserList";
import CustomResponsiveDateRangePicker from "../customResponsiveDateRangePicker/CustomResponsiveDateRangePicker";
import {
  exportExcel,
  isUserAuthorized,
  uniqueAndSortedArrayOfNumber,
} from "../../utils";
import { AUTHORIZATION, EXCEL_REPORT_NAME, ROUTE_PATH } from "../../constants";
import { DateRange } from "@material-ui/lab/DateRangePicker/RangeTypes";
import { Cliente } from "../../pages/ClienteList/interfaces";
import { Marker } from "../../pages/MarkerList/interfaces";
import { getUserReportReset } from "../../pages/UserCRUD/Report/actions";

interface Props {
  handleChangeFilter: (event: ChangeEvent<HTMLInputElement>) => void;
  userFilters: UserFilters;
  handleChangeAutocompleteRuoloFilter: (
    event: SyntheticEvent,
    selectedRuolo: RuoloUtente | null
  ) => void;
  handleChangeTextRuoloUtenteFilter: (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => void;
  dateFilterValue: DateRange<Date>;
  setDateFilterValue: React.Dispatch<React.SetStateAction<DateRange<Date>>>;
  handleReportClick: () => void;
  handleChangeAutocompleteClienteFilter: (
    event: SyntheticEvent,
    selectedRuolo: Cliente | null
  ) => void;
  handleChangeTextClienteFilter: (
    target:
      | (EventTarget & HTMLInputElement)
      | (EventTarget & HTMLTextAreaElement)
  ) => void;
  handleChangeAutocompleteMarkerFilter: (
    event: SyntheticEvent,
    selectedMarker: string | null
  ) => void;
  handleChangeAutocompleteAmbitoFilter: (
    event: SyntheticEvent,
    selectedAmbito: string | null
  ) => void;
}

const UserListToolbar: FC<Props> = ({
  handleChangeFilter,
  userFilters,
  handleChangeAutocompleteRuoloFilter,
  handleChangeTextRuoloUtenteFilter,
  dateFilterValue,
  setDateFilterValue,
  handleReportClick,
  handleChangeAutocompleteClienteFilter,
  handleChangeTextClienteFilter,
  handleChangeAutocompleteMarkerFilter,
  handleChangeAutocompleteAmbitoFilter,
  ...props
}): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const ruoloUtenteList: GetAllRuoloUtenteResponse =
    useAppSelector((state) => state.ruoloUtente.data) ?? [];

  const completeClienteList: Cliente[] =
    useAppSelector((state) => state.clienteNoPag.data?.clienti) ?? [];

  const isUserReportFetched = useAppSelector(
    (state) => state.userReport.isFetched
  );
  const isUserReportFetching = useAppSelector(
    (state) => state.userReport.isFetching
  );
  const byteExcel = useAppSelector((state) => state.userReport.data?.bytes);

  const getAmbitoListByClienteAndMarker = (): Array<string> => {
    const filteredMarkerList: Marker[] =
      useAppSelector((state) => state.marker.data?.markers) ?? [];
    const ambitoList: Array<string> = filteredMarkerList.map(
      (marker) => marker.ambito
    );
    return Array.from(new Set(ambitoList));
  };

  useEffect(() => {
    if (isUserReportFetched && byteExcel) {
      exportExcel(byteExcel, EXCEL_REPORT_NAME.USER_LIST);
    }
    return () => dispatch(getUserReportReset());
  }, [isUserReportFetched]);

  const isUserListLoading = useAppSelector((state) => state.users.isFetching);

  return (
    <Box {...props}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleReportClick}
          disabled={isUserReportFetching}
        >
          Esporta Report
        </Button>
        {isUserAuthorized(AUTHORIZATION.UT_E) && (
          <Button
            sx={{ marginLeft: "1%" }}
            color="primary"
            variant="contained"
            onClick={() => navigate(`/${ROUTE_PATH.APP}/${ROUTE_PATH.USER}`)}
          >
            Inserisci Utente
          </Button>
        )}
      </Box>

      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid sx={{ mt: 1 }} container spacing={2}>
              <Grid item xs={12} sm={12} md={6} lg={5}>
                <TextField
                  name="email"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Email"
                  variant="outlined"
                  onChange={handleChangeFilter}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Autocomplete
                  id="ruoloUtente"
                  options={ruoloUtenteList}
                  getOptionLabel={(ruoloUtente: RuoloUtente) =>
                    ruoloUtente.ruolo
                  }
                  onChange={handleChangeAutocompleteRuoloFilter}
                  inputValue={userFilters.ruoloUtente?.ruolo}
                  disabled={isUserListLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Ruolo Utente"
                      name="ruoloUtente"
                      onChange={({ target }) =>
                        handleChangeTextRuoloUtenteFilter(target)
                      }
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={3}>
                <CustomResponsiveDateRangePicker
                  dateFilterValue={dateFilterValue}
                  setDateFilterValue={setDateFilterValue}
                  disabled={isUserListLoading}
                />
              </Grid>

              <Grid item xs={12} sm={8} md={6} lg={4}>
                <Autocomplete
                  id="cliente-marker-search"
                  options={completeClienteList}
                  getOptionLabel={(cliente: Cliente) => cliente.nome}
                  onChange={handleChangeAutocompleteClienteFilter}
                  inputValue={userFilters.cliente?.nome}
                  disabled={isUserListLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Cliente"
                      name="cliente"
                      variant="outlined"
                      onChange={({ target }) =>
                        handleChangeTextClienteFilter(target)
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={6} lg={3}>
                <Autocomplete
                  id="marker-by-cliente-search"
                  options={uniqueAndSortedArrayOfNumber(
                    userFilters.cliente.codMarkers
                  )}
                  getOptionLabel={(marker: string) => marker}
                  onChange={handleChangeAutocompleteMarkerFilter}
                  inputValue={userFilters.codiceMarker}
                  disabled={!userFilters.cliente.id || isUserListLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="Marker"
                      name="codiceMarker"
                      variant="outlined"
                      placeholder="Marker"
                      value={userFilters.codiceMarker}
                      onChange={handleChangeFilter}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={5}>
                <Autocomplete
                  id="ambito-by-marker-search"
                  options={getAmbitoListByClienteAndMarker()}
                  getOptionLabel={(ambito: string) => ambito}
                  onChange={handleChangeAutocompleteAmbitoFilter}
                  inputValue={userFilters.ambito}
                  disabled={!userFilters.codiceMarker || isUserListLoading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="ambito"
                      label="Ambito"
                      fullWidth
                      value={userFilters.ambito}
                      placeholder="Ambito"
                      variant="outlined"
                      onChange={handleChangeFilter}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default UserListToolbar;
